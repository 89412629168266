import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { isAudio, isImage, isVideo } from "utils";
import { ConversationSelectors } from "redux-store";
import { Close } from "@mui/icons-material";
import { withCloseBadge } from "components/hoc";

// Now, only accept value AppConstant.ACCEPTABLE_IMAGE_TYPES
const UploadMedia = ({ multipleFile, onChangeFile, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const { processStatus } = useSelector(ConversationSelectors.getUploadingAttachment);

  const [filesPreview, setFilesPreview] = useState([]);

  useEffect(() => {
    if (multipleFile) {
      const filesDetail = multipleFile.map(item => {
        const fileDetail = getFileDetail(item.file);
        return {
          attachmentId: item.attachmentId,
          ...fileDetail,
        };
      });
      setFilesPreview(filesDetail);
    }

    return () => {
      if (Array.isArray(filesPreview)) {
        filesPreview.map(item => URL.revokeObjectURL(item.url));
      }
    };
  }, []);

  const isFailedUpload = AppConstant.PROCESS_STATUS.error === processStatus;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} pt={2}>
        <Typography className={"semiBold-lg-txt"}>{getLabel(LangConstant.TXT_SEND_FILE_CONFIRM_TEXT)}</Typography>
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </Stack>

      <Box className={classes.uploadComponent}>
        {multipleFile.length === 1 ? (
          <SingleMediaUpload fileDetail={filesPreview[0]} />
        ) : (
          <MultipleMediaUpload multipleFile={filesPreview} onChangeFile={onChangeFile} />
        )}
      </Box>

      {isFailedUpload && (
        <Typography className={clsx(classes.uploadFailed, "small-md-txt")}>
          {getLabel(LangConstant.TXT_UPLOAD_FAILED)}
        </Typography>
      )}
    </>
  );
};

const SingleMediaUpload = ({ fileDetail, customStyles, customImage, mediaProps }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.mediaBox, customStyles)}>
      {fileDetail?.url && (
        <Box className={classes.mediaPreview}>
          {fileDetail?.type === SystemConstant.MEDIA_SEND_TYPE.image && (
            <img src={fileDetail?.url} alt={fileDetail?.name} loading="lazy" className={customImage} {...mediaProps} />
          )}

          {fileDetail?.type === SystemConstant.MEDIA_SEND_TYPE.video && (
            <video controls={true} className={classes.videoPreview} src={fileDetail?.url} {...mediaProps} />
          )}
        </Box>
      )}
    </Box>
  );
};

const PreviewImageWithBadge = withCloseBadge(SingleMediaUpload);

const MultipleMediaUpload = ({ multipleFile, onChangeFile }) => {
  const classes = useStyles();

  const [selectFile, setSelectFile] = useState({});
  const [fileList, setFileList] = useState([]);

  const handleSelectFile = fileDetail => {
    if (fileDetail.attachmentId !== selectFile.attachmentId) {
      setSelectFile(fileDetail);
    }
  };

  const handleRemoveOneItem = (event, removeItem) => {
    event.stopPropagation();

    onChangeFile(removeItem);
    const newFileList = fileList.filter(item => item.attachmentId !== removeItem.attachmentId);
    if (selectFile.attachmentId === removeItem.attachmentId) {
      setSelectFile(newFileList[0]);
    }
    setFileList(newFileList);
  };

  useEffect(() => {
    setFileList(multipleFile);
    setSelectFile(multipleFile[0] || {});
  }, [multipleFile]);

  return (
    <Stack direction="row" className={classes.contentBox}>
      <Box className={classes.selectedImage}>
        <SingleMediaUpload fileDetail={selectFile} customStyles={classes.customPadding} />
      </Box>

      <Box className={classes.imageListBox}>
        <Grid container rowSpacing={1}>
          {fileList.map(item => (
            <Grid
              item
              key={item.attachmentId}
              sx={{ width: "100%", height: "100%" }}
              onClick={() => handleSelectFile(item)}
            >
              <PreviewImageWithBadge
                ComponentProps={{
                  fileDetail: item,
                  customStyles: classes.previewItem,
                  customImage: classes.customImage,
                  mediaProps: {
                    controls: false,
                  },
                }}
                IconProps={{
                  customIconStyle: classes.deleteIcon,
                  onClick: event => handleRemoveOneItem(event, item),
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

UploadMedia.propTypes = {
  multipleFile: PropTypes.any.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadMedia;

const getFileDetail = file => {
  let type;

  switch (true) {
    case isImage(file.type):
      type = SystemConstant.MEDIA_SEND_TYPE.image;
      break;

    case isAudio(file.type):
      type = SystemConstant.MEDIA_SEND_TYPE.audio;
      break;

    case isVideo(file.type):
      type = SystemConstant.MEDIA_SEND_TYPE.video;
      break;

    default:
      type = SystemConstant.MEDIA_SEND_TYPE.file;
      break;
  }

  return {
    name: file.name,
    type: type,
    url: URL.createObjectURL(file),
  };
};

const useStyles = makeStyles({
  contentBox: {
    overflowY: "auto",
    overflowX: "hidden",
    margin: "16px 0px",
  },

  mediaBox: {
    padding: 16,
    borderRadius: 10,
  },

  confirmTitle: {
    fontSize: "1.125rem",
    fontWeight: 600,
    marginBottom: 20,
  },

  mediaPreview: {
    maxHeight: "100%",
    height: 315,
    width: "100%",
    backgroundColor: "#F3F3F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    "&>img": {
      maxHeight: "100%",
    },
  },

  videoPreview: {
    height: "100%",
    width: "100%",
    borderRadius: 8,
    objectFit: "cover",
  },

  uploadFailed: {
    color: "red",
    marginTop: 10,
    width: "100%",
    textAlign: "end",
  },

  previewItem: {
    width: 120,
    height: 120,
    padding: 0,
    border: "solid 0.5px",
    borderColor: "#F3F3F5",
    "&:hover": {
      cursor: "pointer",
    },
  },

  deleteIcon: {
    top: 2,
    right: 16,
  },

  imageListBox: {
    width: "calc(100% - 463px)",
    height: 315,
    position: "relative",
    left: 463,
  },

  selectedImage: {
    width: 450,
    height: 315,
    paddingLeft: 16,
    position: "fixed",
  },

  customPadding: {
    padding: 0,
  },

  customImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 10,
  },
});
