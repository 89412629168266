import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const UnpinIcon = ({ className, width, height }) => {
  const classes = useStyles({ width, height });

  return (
    <SvgIcon
      className={clsx(className, classes.root)}
      height="24px"
      width="24px"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M680-840v80h-40v327l-80-80v-247H400v87l-87-87-33-33v-47h400ZM480-40l-40-40v-240H240v-80l80-80v-46L56-792l56-56 736 736-58 56-264-264h-6v240l-40 40ZM354-400h92l-44-44-2-2-46 46Zm126-193Zm-78 149Z" />
    </SvgIcon>
  );
};
UnpinIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
UnpinIcon.defaultProps = {
  width: 24,
  height: 24,
};
export default memo(UnpinIcon);

const useStyles = makeStyles(() => ({
  root: {
    width: props => props.width,
    height: props => props.height,
  },
}));
