import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Backdrop, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import KeywordsPopup from "./KeywordsPopup";
import ContactSearchPopup from "./ContactSearchPopup";
import MessageSearchPopup from "./MessageSearchPopup";
import GroupSearchPopup from "./GroupSearchPopup";
import { EmptyResult, SearchBar } from "components";
import { getSearchResult, searchingMessage } from "utils/view.utils";
import { getInteractor } from "services/local.service";
import { replaceId2Name } from "utils/message.utils";
import { formatStringWithKeyword, highlightString, StorageUtil } from "utils";
import orderBy from "lodash/orderBy";
import { useAccountList, useContactList, useLazyEffect } from "hooks";
import clsx from "clsx";

const SEARCH_INPUT_ANCHOR_ID = "search-input-anchor-id";
const SearchConversation = ({ selectedBranch }) => {
  const searchRef = useRef();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();
  const inputEl = document.getElementById(SEARCH_INPUT_ANCHOR_ID);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const isBranchServer = selectedBranch.type === SystemConstant.SERVER_TYPE.branch;

  const { accountList } = useAccountList();
  const { contactList } = useContactList();

  const [searchInputEl, setSearchInputEl] = useState(null);
  const [searchingResult, setSearchingResult] = useState({
    contacts: [],
    messages: [],
    groups: [],
    channels: [],
  });

  const handleClosePopup = isSelected => () => {
    setSearchInputEl(null);
    if (isSelected) {
      handleReset();
    }
  };

  const handleChangeInput = value => {
    searchRef.current = value;

    Promise.all([
      handleSearchMessage(value),
      getSearchResult(value, isBranchServer ? accountList : contactList, ["name", "contactName", "phone"]),
      getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
        keyword: value,
        group_type: SystemConstant.GROUP_CHAT_TYPE.group,
        account_id: accountId,
        branch_id: branchId,
      }),
      getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
        keyword: value,
        group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
        account_id: accountId,
        branch_id: branchId,
      }),
    ]).then(([messages, contacts, groups, channels]) => {
      setSearchingResult({
        ...searchingResult,
        messages: messages,
        contacts: contacts.slice(0, 5),
        groups: groups.slice(0, 5),
        channels: channels.slice(0, 5),
      });
    });
  };

  const handleSearchMessage = async searchValue => {
    if (searchValue) {
      const messageSearchResult = await searchingMessage(searchValue, {
        limit: 30,
      });
      const messageList = orderBy(messageSearchResult, ["created"], ["desc"]).slice(0, 5);

      const handleData = await Promise.all(
        messageList.map(async message => {
          if (Object.values(SystemConstant.MEDIA_SEND_TYPE).includes(message.sendType)) {
            message.content = JSON.parse(message.content).content_message;
          }
          const group = await getInteractor(prefixKey).LocalGroupService.get(message.groupId);
          if (group && group.id) {
            message.content = await replaceId2Name(message.content, group.groupMembers);
          }
          message.content = formatStringWithKeyword(searchValue, message.content);
          message.content = highlightString(searchValue, message.content);

          return message;
        }),
      );

      return handleData;
    }

    return [];
  };

  const handleReset = () => {
    setSearchInputEl(null);
    setSearchingResult({
      contacts: [],
      messages: [],
      groups: [],
      channels: [],
    });
    searchRef.current = "";
  };

  useEffect(() => {
    handleReset();
  }, [selectedBranch]);

  return (
    <>
      <SearchBar
        value={searchRef.current}
        placeholder={getLabel(LangConstant.TXT_CHAT_SEARCH_PLACE_HOLDER)}
        onFocus={() => setSearchInputEl(inputEl)}
        onChange={handleChangeInput}
        classes={{
          root: clsx(classes.searchBarRoot, {
            [classes.searchBarRootActive]: Boolean(searchInputEl),
          }),
        }}
        componentsProps={{
          root: { id: SEARCH_INPUT_ANCHOR_ID },
        }}
      />

      {Boolean(searchInputEl) && (
        <SearchPopup
          anchorEL={searchInputEl}
          data={searchingResult}
          onClose={handleClosePopup(false)}
          onSelectItem={handleClosePopup(true)}
          keyword={searchRef.current}
          onKeywordClick={handleChangeInput}
        />
      )}
    </>
  );
};

const SearchPopup = ({ anchorEL, keyword, data, onClose, onKeywordClick, onSelectItem, ...otherProps }) => {
  const classes = useStyles();
  const { contacts, channels, groups, messages } = data;
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const isShowList = contacts.length > 0 || messages.length > 0 || groups.length > 0 || channels.length > 0;
  const isShowKeywordHistory = keyword.length === 0;

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (anchorEL) {
      let popupEl = document.getElementById(SEARCH_POPUP_ID);
      popupEl.style.left = anchorEL.offsetLeft + "px";
      popupEl.style.top = anchorEL.offsetHeight + anchorEL.offsetTop + "px";
      popupEl.style.width = anchorEL.offsetWidth + "px";
    }
  }, [anchorEL?.offsetWidth]);

  useLazyEffect(() => {
    setIsEmpty(!isShowKeywordHistory && !isShowList);
  }, [data, keyword, isShowList, isShowKeywordHistory]);

  return (
    <Backdrop open={Boolean(anchorEL)} onClick={onClose} className={classes.modal} {...otherProps}>
      <Box className={classes.popupContainer} onClick={e => e.stopPropagation()} id={SEARCH_POPUP_ID}>
        {isShowKeywordHistory ? (
          <KeywordsPopup onKeywordClick={onKeywordClick} />
        ) : (
          isShowList && (
            <Box className={classes.resultWrapper}>
              {contacts.length > 0 && <ContactSearchPopup data={contacts} keyword={keyword} onClick={onSelectItem} />}
              {channels.length > 0 && (
                <GroupSearchPopup
                  data={channels}
                  keyword={keyword}
                  onClick={onSelectItem}
                  title={getLabel(LangConstant.TXT_CHANNEL_FOUND)}
                />
              )}
              {groups.length > 0 && (
                <GroupSearchPopup
                  data={groups}
                  keyword={keyword}
                  onClick={onSelectItem}
                  title={getLabel(LangConstant.TXT_GROUP_FOUND)}
                />
              )}
              {messages.length > 0 && <MessageSearchPopup data={messages} keyword={keyword} onClick={onSelectItem} />}
            </Box>
          )
        )}

        {isEmpty && (
          <EmptyResult
            classes={{
              root: classes.emptyDataWrapper,
              emptyImage: classes.emptySearchResultImage,
              emptyText: classes.notFoundText,
            }}
          />
        )}
      </Box>
    </Backdrop>
  );
};

export default memo(SearchConversation);

const SEARCH_POPUP_ID = "search-popup-id";

SearchPopup.propTypes = {
  onClose: PropTypes.func,

  keyword: PropTypes.string,

  data: PropTypes.shape({
    contacts: PropTypes.array,
    groups: PropTypes.array,
    messages: PropTypes.array,
  }),
};

SearchPopup.defaultProps = {
  onClose: () => {},

  keyword: "",

  data: {
    contacts: [],
    groups: [],
    messages: [],
  },
};

const useStyles = makeStyles(theme => ({
  searchBarRoot: {
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  searchBarRootActive: {
    zIndex: theme.zIndex.snackbar,
  },

  modal: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    zIndex: theme.zIndex.modal,
  },

  popupContainer: {
    position: "absolute",
    marginTop: 8,
    maxHeight: 370,
    borderRadius: 10,
    backgroundColor: theme.palette.white,
    overflowY: "hidden",
    boxShadow: "none",
  },

  title: {
    color: "#7F7F80",
    lineHeight: "1rem",
  },

  emptyDataWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 330,
    padding: 64,
  },

  emptySearchResultImage: {
    width: "unset",
    height: "unset",
    marginBottom: 18,
  },

  notFoundText: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    color: theme.palette.black,
  },

  resultWrapper: {
    overflowY: "scroll",
    maxHeight: 350,
    margin: "10px 0",
  },
}));
