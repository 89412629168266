import { useState } from "react";
import { useAlertContext } from "components/context/AlertContext";
import ForwardingDialog from "components/dialog/ForwardDialog";
import { getInteractor } from "services/local.service";
import { toCamel } from "utils";
import { getCommonLang } from "utils/lang.utils";

const withForwardMessage = TriggerComponent => {
  return props => {
    const { showAlert } = useAlertContext();

    const [forwardData, setForwardData] = useState({
      attachmentId: null,
      message: null,
    });

    const handleError = () => {
      setForwardData({});
      showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), severity: "error" });
    };

    const handleSetForwardData = async item => {
      const { attachmentId, sourceMessageId, message } = item || {};

      switch (true) {
        case Boolean(attachmentId && sourceMessageId):
          const messageRecord = await getInteractor().LocalMessageService.findOne({
            source_id: sourceMessageId,
          });

          if (messageRecord?.id) {
            setForwardData({
              attachmentId: attachmentId,
              message: toCamel(messageRecord),
            });
          } else {
            handleError();
          }
          break;

        case Boolean(attachmentId && message):
          setForwardData({
            attachmentId: attachmentId,
            message: toCamel(message),
          });
          break;

        case Boolean(message):
          setForwardData({
            message: toCamel(message),
          });
          break;

        default:
          handleError();
          break;
      }
    };

    const isShowForward = Boolean(forwardData && forwardData.message);

    return (
      <>
        <TriggerComponent onForward={handleSetForwardData} {...props} />

        {isShowForward && (
          <ForwardingDialog
            open={isShowForward}
            onClose={() => setForwardData({})}
            forwardAttachmentId={forwardData.attachmentId}
            forwardMessage={forwardData.message}
          />
        )}
      </>
    );
  };
};

export default withForwardMessage;
