import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { getInteractor } from "services/local.service";
import { StorageUtil, toCamel } from "utils";
import { convertMillisecondsToDate } from "utils/date.utils";
import ChatTypography from "../../MessengerChat/ChatItem/ChatTypography";
import { KeyConstant } from "const";
import { AppAvatar } from "components";
import { useCleanUpEffect } from "hooks";

const MessageThreadItem = ({ message, groupMembers }) => {
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const { isMounted } = useCleanUpEffect();

  const [accountDetail, setAccountDetail] = useState("");

  useEffect(() => {
    if (message) {
      getInteractor(prefixKey)
        .LocalAccountService.get(message.senderId, branchId)
        .then(account => {
          if (isMounted()) setAccountDetail(toCamel(account) || {});
        });
    }
  }, [message]);

  return (
    <>
      {message.senderId && (
        <Box className={classes.component}>
          <AppAvatar avatarId={accountDetail.avatarId} className={classes.accountAvatar} />
          <Box className={classes.content}>
            <Box className={classes.contentTitle}>
              <Typography>{accountDetail.name}</Typography>
              <Typography className={classes.textColor}>
                {convertMillisecondsToDate(message.created, "HH:mm - dd/MM/yyyy")}
              </Typography>
            </Box>
            <ChatTypography
              className={clsx(classes.messageContent, classes.textColor, "ellipsis")}
              mentionList={groupMembers}
              messageContent={message.content}
              messageId={message.id}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MessageThreadItem;

const useStyles = makeStyles(() => ({
  component: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },

  accountAvatar: {
    marginRight: 10,
  },

  content: {
    width: "100%",
  },

  contentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  textColor: {
    color: "#7F7F80",
    fontSize: "0.875rem",
  },

  messageContent: {},

  timeSend: {},
}));
