import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { isJSONString, toCamel, StorageUtil } from "utils";
import { isEqual } from "lodash";
import { getNSLang } from "utils/lang.utils";
import { Replay, Error } from "@mui/icons-material";
import MessengerPeople from "./MessengerPeople";
import FilePreview from "./FilePreview";
import ImageMessage from "./MediaPreview/ImageMessage";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { getInteractor } from "services/local.service";
import ThreadFlow from "./ThreadFlow";
import ReactionFlow from "./ReactionFlow";
import ChatAction from "./ChatAction";
import { shallowEqual, useSelector } from "react-redux";
import { useCleanUpEffect } from "hooks";
import { getLatestMessage } from "utils/view.utils";

const ChatItem = ({ data, isThreadMode, isSearchMode, ...otherProps }) => {
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);

  const isMine = data.senderId === accountId;
  const isSentByThisDevice = isMine && data.senderDeviceId === deviceId;
  const isSending = isSentByThisDevice && isMine && !Boolean(data.modified);
  const updateMessageStatus = useSelector(state => state.conversationRedux.updateMessageStatus, shallowEqual);
  const { isMounted } = useCleanUpEffect();

  const [message, setMessage] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reactionList, setReactionList] = useState([]);
  const [isShowSeenMembers, setIsShowSeenMembers] = useState(false);
  const [threadInfo, setThreadInfo] = useState({});

  const [isFailedSending, isShowMessageDetail, sendingMsgStatus] = useMemo(() => {
    const isFailedSending = !isSending && message.state === SystemConstant.STATE.inactive && isSentByThisDevice;
    const isShowMessageDetail = !isSending && !isFailedSending && message.state === SystemConstant.STATE.active;

    let sendingMsgStatus = SENDING_STATUS.success;
    switch (true) {
      case Boolean(isSending):
        sendingMsgStatus = SENDING_STATUS.sending;
        break;

      case Boolean(isFailedSending):
        sendingMsgStatus = SENDING_STATUS.failed;
        break;

      default:
        break;
    }

    return [isFailedSending, isShowMessageDetail, sendingMsgStatus];
  }, [message]);

  const handleEditMode = () => {
    if (isSearchMode) return;

    setIsEditing(true);
  };

  const handleHoverChat = hover => () => {
    if (isSearchMode) return;

    setIsHovered(hover);
  };

  useEffect(() => {
    const isValid = Boolean(data && data.id && data.sourceId);
    if (!isValid) return;

    getLatestMessage(prefixKey, data).then(latestMessage => {
      if (!isEqual(latestMessage, message) && isMounted()) setMessage(latestMessage);
    });

    if (!isSearchMode) {
      // Handling reply message
      countThreadMessage(prefixKey, data.sourceId).then(thread => {
        if (!isEqual(thread, threadInfo) && isMounted()) setThreadInfo(thread);
      });

      // Get all reaction of origin message
      getReactionList(prefixKey, data.sourceId).then(msgReactionList => {
        if (!isEqual(msgReactionList, reactionList) && isMounted()) setReactionList(msgReactionList);
      });
    }
  }, [data]);

  useEffect(() => {
    const isValid =
      !isSearchMode &&
      Boolean(
        message.id &&
          Array.isArray(updateMessageStatus.updateMessageIds) &&
          updateMessageStatus.updateMessageIds.includes(message.id),
      );
    if (!isValid) return;

    // Handling reply message
    countThreadMessage(prefixKey, data.sourceId).then(thread => {
      if (!isEqual(thread, threadInfo) && isMounted()) setThreadInfo(thread);
    });
  }, [updateMessageStatus]);

  const isShowAction = !isSearchMode && (isThreadMode ? Boolean(message.threadId) : !isEditing);
  if (!Boolean(message.id && message.sourceId)) return null;
  return (
    <>
      <Box
        className={clsx(classes.chatItemRoot, isMine && classes.chatItemRootFromMe)}
        onMouseEnter={handleHoverChat(true)}
        onMouseLeave={handleHoverChat(false)}
        {...otherProps}
      >
        {isShowAction && (
          <ChatAction
            isShow={isHovered}
            isShowMessageDetail={isShowMessageDetail}
            isThreadMode={isThreadMode}
            message={message}
            reactionList={reactionList}
            onEditMessage={handleEditMode}
          />
        )}

        <Stack
          direction="row"
          justifyContent={isMine ? "flex-end" : "flex-start"}
          className={classes.messageContent}
          onClick={() => setIsShowSeenMembers(!isShowSeenMembers)}
        >
          {isFailedSending && (
            <IconButton color="error" className={classes.chatMenuButton}>
              <Replay />
            </IconButton>
          )}

          {[SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video].includes(message.sendType) &&
          isJSONString(message.content) ? (
            <ImageMessage message={message} isMine={isMine} />
          ) : message.sendType === SystemConstant.SEND_TYPE.file ? (
            <FilePreview message={message} />
          ) : (
            <MessengerPeople message={message} isMine={isMine} isEditing={isEditing} setIsEditing={setIsEditing} />
          )}
        </Stack>

        {!(isEditing || isSearchMode) && (
          <Stack direction="row" row={1} className={isMine ? classes.extraInfoMine : classes.extraInfo}>
            <ReactionFlow isShow={reactionList.length > 0} reactionList={reactionList} />

            <ThreadFlow
              isShow={threadInfo.messageThreadTotal > 0 && !isThreadMode}
              isMine={isMine}
              parentMessageId={message.sourceId}
              messageThreadTotal={threadInfo.messageThreadTotal}
              unreadMsgThreadTotal={threadInfo.unreadMsgThreadTotal}
            />
          </Stack>
        )}
      </Box>

      <SendingStatus
        isHovered={isHovered}
        sendingMsgStatus={sendingMsgStatus}
        messageSourceId={message.sourceId}
        isMine={isMine}
      />
    </>
  );
};

export default memo(ChatItem);

const SENDING_STATUS = {
  sending: "is_sending_message",
  failed: "failed_sending_message",
  success: "success_sending_message",
};
const SendingStatus = ({ sendingMsgStatus, isHovered, isMine, messageSourceId }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [msgLabelKey, setMsgLabelKey] = useState(""); // Sent-Received-Seen

  useEffect(() => {
    let isMounted = true;
    if (msgLabelKey === LangConstant.TXT_SEEN) return;

    if ([SENDING_STATUS.sending, SENDING_STATUS.failed].includes(sendingMsgStatus)) {
      setMsgLabelKey("");
    } else if (isMine) {
      getMyMessageStatus(messageSourceId).then(messageLabelKey => {
        if (isMounted) setMsgLabelKey(messageLabelKey);
      });
    } else if (!isMine) {
      setMsgLabelKey(LangConstant.TXT_SEEN);
    }
    return () => {
      isMounted = false;
    };
  }, [sendingMsgStatus, isHovered]);

  return (
    <Box mt={1}>
      {SENDING_STATUS.sending === sendingMsgStatus && (
        <Typography className={classes.sentStatus}>{getLabel(LangConstant.TXT_SENDING)}</Typography>
      )}
      {SENDING_STATUS.failed === sendingMsgStatus && (
        <Typography className={clsx(classes.sentStatus, classes.failedStatus)}>
          {getLabel(LangConstant.TXT_SEND_FAILED)} <Error />
        </Typography>
      )}

      {msgLabelKey && (
        <Typography className={clsx(classes.sentStatus, !isHovered && classes.sentStatusHidden)}>
          {getNSLang(LangConstant.NS_HOME_CONVERSATION, msgLabelKey)}
        </Typography>
      )}
    </Box>
  );
};

const getReactionList = async (prefixKey, parentMessageId) => {
  try {
    const records = await getInteractor(prefixKey).LocalMessageService.getChildMessages(parentMessageId, [
      SystemConstant.SEND_TYPE.reaction,
      SystemConstant.SEND_TYPE.deleteReaction,
    ]);

    const msgReactionList = toCamel(records).sort((current, prev) => current.created - prev.created);
    const handleReactionObj = msgReactionList.reduce((newReactList, react) => {
      const account = react.senderId;
      newReactList[account] = (newReactList[account] || []).concat(react);

      return newReactList;
    }, {});
    const finalReactionArr = Object.values(handleReactionObj)
      .map(data => (data.length > 0 ? data[data.length - 1] : data[0]))
      .filter(react => react.sendType === SystemConstant.SEND_TYPE.reaction);

    return finalReactionArr;
  } catch (error) {
    console.log(error);
  }

  return [];
};

const countThreadMessage = async (prefixKey, threadId) => {
  let countThreadInfo = { messageThreadTotal: 0, unreadMsgThreadTotal: 0 };
  if (threadId) {
    try {
      const threadInfo = await getInteractor(prefixKey).LocalThreadService.getByThreadId(threadId);

      if (threadInfo) {
        countThreadInfo.messageThreadTotal = threadInfo.total_reply || 0;
        countThreadInfo.unreadMsgThreadTotal = threadInfo.total_unread || 0;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return countThreadInfo;
};

const getMyMessageStatus = async messageSourceId => {
  const [seenMemberCount, receivedMemberCount] = await Promise.all([
    getInteractor().LocalSeenMemberService.count({ source_id: messageSourceId }),
    getInteractor().LocalReceivedMemberService.count({
      source_id: messageSourceId,
    }),
  ]);

  return seenMemberCount > 0
    ? LangConstant.TXT_SEEN
    : receivedMemberCount > 0
    ? LangConstant.TXT_RECEIVED
    : LangConstant.TXT_SENT;
};

const useStyles = makeStyles({
  chatItemRoot: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    paddingTop: 8,
    paddingBottom: 0,
  },

  chatItemRootFromMe: {
    flexDirection: "row",
  },

  messageContent: {
    maxWidth: "calc(90% - 80px)",
    cursor: "pointer",
  },

  chatMenuButton: {
    width: 24,
    height: 24,
    minHeight: 10,
    minWidth: 10,
    padding: 4,
  },

  extraInfo: {
    position: "absolute",
    bottom: -10,
    left: 80,
    display: "flex",
    flexDirection: "row-reverse",
  },

  extraInfoMine: {
    position: "absolute",
    bottom: -10,
    right: 30,
    display: "flex",
  },

  messageWithExtra: {
    marginBottom: 10,
  },

  sentStatus: {
    textAlign: "end",
    padding: "0 28px",
    fontSize: "0.5625rem",
    fontWeight: 450,
    opacity: 1,
    transition: "opacity 0.2s ease-in-out",
  },

  sentStatusHidden: {
    opacity: 0,
  },

  failedStatus: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "red",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "&>svg": {
      fontSize: "1.125rem",
      marginLeft: 2,
    },
  },
});
