import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import { CallEnd, HorizontalRule } from "@mui/icons-material";
import clsx from "clsx";
import { HomeServerIcon } from "components/icons";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { convertHex2rgba, convertString2JSON, toCamel } from "utils";
import { CallingContext, isGroupOrChannelType } from "../index";
import UserInCall from "./UserInCall";
import { CallingActions, ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";

const CallLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { callingGroupDetail, onSendMessage, localStream, connections, selectedScreenStream, onStopSharing } =
    useContext(CallingContext);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);
  const isCallAccepted = useSelector(state => state.callingRedux.isCallAccepted);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const participants = useSelector(state => state.callingRedux.callingParticipants);
  const isDisconnected = useSelector(state => state.callingRedux.isDisconnected);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const [selectedServer, setSelectedServer] = useState(null);
  const [isShowUserInCall, setIsShowUserInCall] = useState(false);

  const handleSendCallingMsg = params => {
    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.checking) return;

    onSendMessage(params);
  };

  const onEnd = () => {
    const callingType = callingGroupDetail?.groupType;
    const callingId = callingGroupDetail?.id;

    // Reset calling
    dispatch(
      CallingActions.callingReset({
        callingGroupDetail: null,
      }),
    );

    let isPersonal = callingType === SystemConstant.GROUP_CHAT_TYPE.personal;
    let callBackEnd = async () => {
      console.log("On end call from main call UI");

      let lastCall = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(callingId));

      const callMessage = toCamel(
        (await getInteractor().LocalMessageService.findOne({
          source_id: lastCall.sourceId,
        })) || {},
      );

      let currentCallingMessage = createdMessage[callingId];
      if (
        currentCallingMessage ||
        (lastCall &&
          lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
          JSON.parse(lastCall.callingMembers).includes(deviceId))
      ) {
        let endMessageParent = currentCallingMessage ? currentCallingMessage : callMessage;
        let endContent = {
          room_id: convertString2JSON(endMessageParent?.content)?.room_id,
        };

        if (isPersonal) {
          if (isReceiver) {
            console.log("Send message end to caller");

            handleSendCallingMsg({
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.end,
              roomId: endContent.room_id,
            });

            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingId]: null,
                },
              }),
            );
          } else {
            if (isCallAccepted) {
              console.log("Send message end to receiver");

              handleSendCallingMsg({
                content: JSON.stringify(endContent),
                parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
                threadId: null,
                callStatus: SystemConstant.MESSAGE_CALL_STATUS.end,
                roomId: endContent.room_id,
              });

              dispatch(
                CallingActions.callingSet({
                  createdMessage: {
                    ...createdMessage,
                    [callingId]: null,
                  },
                }),
              );
            } else {
              console.log("Send message missed to receiver");

              handleSendCallingMsg({
                content: JSON.stringify(endContent),
                parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
                threadId: null,
                callStatus: isDisconnected
                  ? SystemConstant.MESSAGE_CALL_STATUS.end
                  : SystemConstant.MESSAGE_CALL_STATUS.missed,
                roomId: endContent.room_id,
              });

              dispatch(
                CallingActions.callingSet({
                  createdMessage: {
                    ...createdMessage,
                    [callingId]: null,
                  },
                }),
              );
            }
          }
        } else if (isGroupOrChannelType(callingType)) {
          let endContent = {
            room_id: convertString2JSON(endMessageParent?.content)?.room_id,
          };
          if (participants?.length > 1) {
            console.log("Send message waiting to group");
            handleSendCallingMsg({
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
              roomId: endContent?.room_id,
            });

            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingId]: null,
                },
              }),
            );
          } else if (Array.isArray(participants) && participants[0]?.id === accountId) {
            // TODO: Send message with call status "end" and send type "groupCall"
            console.log("Send message waiting/end to group", { createdMsg: createdMessage[callingId] });
            handleSendCallingMsg({
              sendType: SystemConstant.SEND_TYPE.groupCall,
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
              roomId: endContent?.room_id,
            });

            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingId]: null,
                },
              }),
            );
          }
        }
      }

      dispatch(CallingActions.callingReset());
      dispatch(
        ConversationActions.conversationSet({
          idGroupCallAvatar: null,
        }),
      );

      StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, null);

      localStream?.getVideoTracks()?.forEach(track => track.stop());
      localStream?.getAudioTracks()?.forEach(track => track.stop());
    };

    // remove comment if don't use jitsi
    if (!isReceiver && isPersonal) {
      // console.log(connections);
      // connections.forEach(obj => {
      //   if (!obj.finishGatheringIce){
      //     obj.connection.forceGathering(callBackEnd)
      //     obj.connection.close();
      //   } else {
      //     callBackEnd();
      //   }
      // });
      callBackEnd();
    } else {
      connections.forEach(obj => {
        obj.connection.close();
      });
      callBackEnd();
    }
  };

  useEffect(() => {
    if (selectedScreenStream?.stream && !selectedScreenStream?.stream?.active) {
      onStopSharing();
    }
  }, [selectedScreenStream?.stream?.active]);

  useEffect(() => {
    let branch = StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER);
    if (branch) {
      setSelectedServer(branch);
    }
  }, []);

  useEffect(() => {
    let endCallTimeout = null;
    if (!navigator.onLine && isDisconnected) {
      endCallTimeout = setTimeout(() => {
        setTimeout(() => {
          onEnd();
          dispatch(CallingActions.callingReset());
        }, 2000);
      }, AppConstant.CALL_WAITING_TIME + 15000);
    }

    return () => {
      clearTimeout(endCallTimeout);
    };
  }, [isDisconnected]);

  return (
    <>
      <Box className={classes.root} id={CALLING_ROOT}>
        <Box className={classes.container}>
          <Box className={classes.receiveBox}>{children}</Box>
          <TopBar />
        </Box>
        <Box className={classes.bottomActionBar}>
          {!isInAnotherCall && (
            <IconButton className={clsx(classes.actionButton, classes.endButton)} onClick={onEnd}>
              <CallEnd className={classes.iconAction} />
            </IconButton>
          )}
          <Box className={classes.homeServerIcon}>
            <HomeServerIcon />
            <Typography className={classes.serverName}>{selectedServer?.name}</Typography>
          </Box>
        </Box>
      </Box>

      {isShowUserInCall && <UserInCall isOpen={isShowUserInCall} onClose={() => setIsShowUserInCall(false)} />}
    </>
  );
};

CallLayout.propTypes = {
  action: PropTypes.shape({
    shareScreenClick: PropTypes.func,
    addUser: PropTypes.func,
    videoAction: PropTypes.func,
    voiceAction: PropTypes.func,
  }),
};

CallLayout.defaultProps = {
  action: {
    shareScreenClick: () => {},
    addUser: () => {},
    videoAction: () => {},
    voiceAction: () => {},
  },
};

const TopBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onHideCallDialog = () => {
    dispatch(CallingActions.callingSet({ isHideCallingDialog: true }));
  };

  return (
    <Box className={classes.topBar}>
      <IconButton className={classes.iconButton} onClick={onHideCallDialog}>
        <HorizontalRule className={classes.iconInButton} />
      </IconButton>
    </Box>
  );
};

const CALLING_ROOT = "CALLING_ROOT";

export default CallLayout;

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  bottomActionBar: {
    width: "100%",
    height: 88,
    backgroundColor: "#1F1F1F",
    bottom: 0,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  actionButton: {
    backgroundColor: "#3A3A3A",
    width: 48,
    height: 48,
    borderRadius: "50%",
    margin: "0 27px",
    "&:hover": {
      backgroundColor: convertHex2rgba("#3A3A3A", 0.7),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  iconAction: {
    color: "white",
    fontSize: "1.375rem",
  },

  endButton: {
    backgroundColor: "#EB544B",
  },

  container: {
    height: "100%",
    width: "100%",
    padding: 24,
    position: "relative",
    backgroundColor: "black",
  },

  topBar: {
    display: "flex",
    justifyContent: "end",
  },

  iconButton: {
    width: 36,
    height: 36,
    padding: 9,
    backgroundColor: "black",
    marginLeft: 12,
    "&:hover": {
      backgroundColor: convertHex2rgba("#000000", 0.8),
    },
  },

  iconInButton: {
    height: 18,
    width: 18,
    color: "white",
  },

  receiveBox: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 88,
  },

  homeServerIcon: {
    position: "absolute",
    right: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  serverName: {
    color: "white",
    fontSize: "0.625rem",
    fontWeight: 400,
    marginTop: 6,
  },
}));
