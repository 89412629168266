import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { FormatConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import AccountCoverEdit from "./AccountCoverEdit";
import { Box, Button, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { formatPhoneNumber } from "utils";
import { makeStyles } from "@mui/styles";
import { AppDrawer } from "components";
import AccountItem from "./AccountItem";
import EditProfile from "./EditProfile";
import { convertMillisecondsToDate, formatBirthday } from "utils/date.utils";

const YourProfile = ({ accountDetail, onChange }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const profileObjContent = getLabel(LangConstant.OBJ_ACCOUNT_PROFILE, { returnObjects: true });
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });

  const [isShow, setIsShow] = useState(false);

  const handleShowProfileFunc = open => () => setIsShow(open);

  const genderObject = {
    0: profileObjContent.undefinedGender,
    1: profileObjContent.male,
    2: profileObjContent.female,
    3: profileObjContent.other,
  };

  return (
    <>
      <AccountItem text={accountObjContent.profile} onClick={handleShowProfileFunc(true)} />

      <AppDrawer open={isShow} onClose={handleShowProfileFunc(false)} title={profileObjContent.title}>
        <AccountCoverEdit data={accountDetail} onChange={onChange} />
        <ProfileItem label={profileObjContent.name} text={accountDetail.name} />
        <ProfileItem label={profileObjContent.phone} text={formatPhoneNumber(accountDetail.phone)} />
        <ProfileItem label={profileObjContent.email} text={accountDetail?.mail} />
        <ProfileItem
          label={profileObjContent.birthday}
          text={convertMillisecondsToDate(
            new Date(formatBirthday(accountDetail?.birthday)).getTime(),
            FormatConstant.FM_DD_MM_YYYY,
          )}
        />
        <ProfileItem label={profileObjContent.address} text={accountDetail?.address} />
        <ProfileItem
          label={profileObjContent.gender}
          text={accountDetail?.gender ? genderObject[accountDetail?.gender] : ""}
        />
        <ProfileItem label={profileObjContent.position} text={accountDetail.positionName} />
        <ProfileItem label={profileObjContent.unit} text={accountDetail.unitName} />
        <ProfileItem label={profileObjContent.department} text={accountDetail.departmentName} />
        <ButtonEditProfile data={accountDetail} onChange={onChange} />
      </AppDrawer>
    </>
  );
};

YourProfile.propTypes = {
  accountDetail: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.string,
  }),

  onChange: PropTypes.func,
};

YourProfile.defaultProps = {
  accountDetail: {
    name: "",
    phone: "",
    email: "",
    birthday: "",
  },
};

export default memo(YourProfile);

const ProfileItem = ({ label, text }) => {
  const classes = useStyles();

  return (
    <>
      <Box p={2}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.text}>{text}</Typography>
      </Box>
      <Divider sx={{ color: "#F5F5F5" }} />
    </>
  );
};
ProfileItem.defaultProps = {};

const ButtonEditProfile = ({ data, onChange }) => {
  const classes = useStyles();
  const [isShowEditProfile, setIsShowEditProfile] = useState(false);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const profileObjContent = getLabel(LangConstant.OBJ_ACCOUNT_PROFILE, { returnObjects: true });

  const clickEditProfile = () => {
    setIsShowEditProfile(!isShowEditProfile);
  };

  return (
    <>
      <Box className={classes.boxEditProfile}>
        <Button className={classes.buttonEditProfile} onClick={clickEditProfile}>
          {profileObjContent.editProFile}
        </Button>
      </Box>

      <AppDrawer
        open={isShowEditProfile}
        onClose={() => setIsShowEditProfile(false)}
        icon={<CloseIcon />}
        title={profileObjContent.editYourProfile}
      >
        <EditProfile profile={data} onChange={onChange} />
      </AppDrawer>
    </>
  );
};

const useStyles = makeStyles({
  label: {
    fontSize: "0.9375rem",
    fontWeight: 400,
    color: "#1E272E",
  },
  text: {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#424242",
    marginTop: 8,
  },
  buttonEditProfile: {
    backgroundColor: "#008FE8",
    width: "100%",
    height: "48px",
    margin: "auto",
    marginBottom: 20,
    color: "white",
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    "&:hover": {
      backgroundColor: "#008FE8",
    },
  },
  boxEditProfile: {
    padding: "0 14px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },

  infoBox: {
    overflowY: "auto",
  },
});
