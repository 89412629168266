import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const SettingUIIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_844_4993)">
        <path
          d="M11.9992 23.3L8.64922 20H3.99922V15.35L0.699219 12L3.99922 8.65001V4.00001H8.64922L11.9992 0.700012L15.3492 4.00001H19.9992V8.65001L23.2992 12L19.9992 15.35V20H15.3492L11.9992 23.3ZM11.9992 17C13.3826 17 14.5617 16.5125 15.5367 15.5375C16.5117 14.5625 16.9992 13.3833 16.9992 12C16.9992 10.6167 16.5117 9.43751 15.5367 8.46251C14.5617 7.48751 13.3826 7.00001 11.9992 7.00001C10.6159 7.00001 9.43672 7.48751 8.46172 8.46251C7.48672 9.43751 6.99922 10.6167 6.99922 12C6.99922 13.3833 7.48672 14.5625 8.46172 15.5375C9.43672 16.5125 10.6159 17 11.9992 17ZM11.9992 20.5L14.4992 18H17.9992V14.5L20.4992 12L17.9992 9.50001V6.00001H14.4992L11.9992 3.50001L9.49922 6.00001H5.99922V9.50001L3.49922 12L5.99922 14.5V18H9.49922L11.9992 20.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_844_4993">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

SettingUIIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
SettingUIIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(SettingUIIcon);
