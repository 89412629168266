import { Box, Button, Slider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppDrawer, AvatarGroup } from "components";
import { useAlertContext } from "components/context/AlertContext";
import { AppConstant, KeyConstant, LangConstant } from "const";
import { getLabel } from "language";
import { MyMessage, OtherMessage } from "pages/HomePage/components/MessengerChat/ChatItem/MessengerPeople";
import { memo, useMemo, useState } from "react";
import { StorageUtil } from "utils";
import { getCommonLang, getNSLang } from "utils/lang.utils";

const SettingFontSize = ({ open, onClose }) => {
  const classes = useStyles();
  const { showAlert } = useAlertContext();

  const currentFontSize = StorageUtil.getCommonKey(KeyConstant.KEY_FONT_SIZE) || AppConstant.BASE_FONT_SIZE;
  const defaultFontSize = useMemo(() => currentFontSize, []);

  const [fontSize, setFontSize] = useState(defaultFontSize);

  const handleChangeFontSize = event => {
    const newFontSize = event.target.value;
    setFontSize(newFontSize);
  };

  const handleSaveSetting = () => {
    StorageUtil.setCommonKey(KeyConstant.KEY_FONT_SIZE, fontSize);
    document.documentElement.style.setProperty("--font-size", `${fontSize}px`);
    showAlert({ content: getCommonLang("TXT_SETTING_SUCCESS") });
  };

  const isChanged = fontSize !== currentFontSize;

  return (
    <AppDrawer open={open} onClose={onClose} title={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_FONT_SIZE")}>
      <Box className={classes.root}>
        <Stack mb={2} spacing={1}>
          <Typography className={classes.noteText}>
            {getNSLang(LangConstant.NS_HOME, "TXT_FONT_SIZE_CONVERSATION")}
          </Typography>
          <PreviewConversation previewFontSize={fontSize} />
        </Stack>

        <Stack mb={2} spacing={1}>
          <Typography className={classes.noteText}>
            {getNSLang(LangConstant.NS_HOME, "TXT_FONT_SIZE_SIDEBAR")}
          </Typography>
          <PreviewSideBar previewFontSize={fontSize} />
        </Stack>

        <Stack mb={2} spacing={1}>
          <Typography className={classes.noteText}>
            {getNSLang(LangConstant.NS_HOME, "TXT_FONT_SIZE_TITLE_APP")}
          </Typography>
          <PreviewAppTab previewFontSize={fontSize} />
        </Stack>

        <Typography className={classes.noteText}>{getNSLang(LangConstant.NS_HOME, "TXT_CHANGE_FONT_SIZE")}</Typography>
        <Stack direction="row" alignItems="center" mb={2}>
          <Typography fontSize="10px">A</Typography>
          <Slider
            onChange={handleChangeFontSize}
            defaultValue={defaultFontSize}
            shiftStep={2}
            step={2}
            marks
            min={10}
            max={22}
            className={classes.fontSizeSlider}
          />
          <Typography fontSize="22px">A</Typography>
        </Stack>

        <Button fullWidth variant="contained" onClick={handleSaveSetting} disabled={!isChanged}>
          {getLabel("TXT_SAVE")}
        </Button>
      </Box>
    </AppDrawer>
  );
};

export default memo(SettingFontSize);

const PreviewConversation = props => {
  const classes = useStyles(props);
  const previewObjContent = getNSLang(LangConstant.NS_HOME, "OBJ_PREVIEW_CONTENT", {
    returnObjects: true,
  });

  const myMessage = {
    content: previewObjContent.previewMyMsg,
  };

  const otherMessage = {
    content: previewObjContent.previewOtherMsg,
    isAvatar: true,
    senderName: previewObjContent.previewGroupName,
    sentTime: previewObjContent.previewTime,
  };

  return (
    <Box className={clsx(classes.previewBox, classes.messageBox)}>
      <OtherMessage message={otherMessage} isAvatar={true} />
      <Box className={classes.myMessage}>
        <MyMessage message={myMessage} />
      </Box>
    </Box>
  );
};

const PreviewSideBar = props => {
  const classes = useStyles(props);
  const previewObjContent = getNSLang(LangConstant.NS_HOME, "OBJ_PREVIEW_CONTENT", {
    returnObjects: true,
  });

  return (
    <Box className={classes.previewBox}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <AvatarGroup />
          <Stack alignItems="flex-start">
            <Typography className={clsx(classes.previewGroupName, "preview-chat")}>
              {previewObjContent.previewGroupName}
            </Typography>
            <Typography className="preview-last-message">{previewObjContent.previewLastMessage}</Typography>
          </Stack>
        </Stack>
        <Box>
          <Typography fontWeight={400} className="preview-time-text" color="#666666">
            {previewObjContent.previewTime}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const PreviewAppTab = props => {
  const classes = useStyles(props);
  const previewObjContent = getNSLang(LangConstant.NS_HOME, "OBJ_PREVIEW_CONTENT", {
    returnObjects: true,
  });

  return (
    <Box className={classes.previewBox}>
      <Typography textAlign="left" fontWeight={400} color="#1E272E" className="preview-app-tab">
        {previewObjContent.previewAppTab}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "8px 16px",
  },

  fontSizeSlider: {
    margin: "0px 12px",
  },

  previewGroupName: {
    fontWeight: 600,
    marginBottom: 2,
  },

  noteText: {
    fontSize: "0.875rem",
    color: theme.palette.grey[400],
  },

  myMessage: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 8,
  },

  messageBox: {
    backgroundColor: theme.palette.primary.light,
  },

  previewBox: props => ({
    "--preview-ratio": `calc(${props.previewFontSize || AppConstant.BASE_FONT_SIZE} / ${AppConstant.BASE_FONT_SIZE})`,
    height: "fit-content",
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
    border: `solid 0.5px ${theme.palette.grey[200]} `,

    "& .preview-chat": {
      fontSize: `calc(var(--preview-ratio) * 16px)`,
    },

    "& .preview-last-message": {
      fontSize: `calc(var(--preview-ratio) * 14px)`,
    },

    "& .preview-time-text": {
      fontSize: `calc(var(--preview-ratio) * 12px)`,
    },

    "& .preview-app-tab": {
      fontSize: `calc(var(--preview-ratio) * 15px)`,
    },
  }),
}));
