import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MoreHoriz } from "@mui/icons-material";
import clsx from "clsx";
import PopupContact from "../Sidebar/ViewModeTabs/PopupContact";
import { useSelector } from "react-redux";
import GroupItem from "./GroupItem";
import { AvatarInfo } from "components";
import { StorageUtil } from "utils";
import { KeyConstant } from "const";

const DataListByLetter = ({ letter, dataList, onItemClick, isGroup = false }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={clsx(classes.firstLetter, "semiBold-sm-txt")}>{letter}</Typography>

      {dataList.map((item, index) =>
        isGroup ? (
          <GroupItem key={index} data={item} onClick={() => onItemClick(item)} />
        ) : (
          <ContactItem key={index} data={item} onClick={() => onItemClick(item)} />
        ),
      )}
    </Box>
  );
};

DataListByLetter.propTypes = {
  letter: PropTypes.string,
  dataList: PropTypes.array,
  onItemClick: PropTypes.func,
};

DataListByLetter.defaultProps = {
  letter: "",
  dataList: [],
  onItemClick: () => {},
};

export default DataListByLetter;

const ContactItem = ({ data, onClick }) => {
  const classes = useStyles();
  const chatItemRef = useRef(null);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const nameAfterChange = useSelector(state => state.contactRedux.nameAfterChange);
  const idContactPhoneBook = useSelector(state => state.contactRedux.idContactPhoneBook);

  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contact, setContact] = useState({});

  const onShowContactItemMenu = event => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const onOpenPopupContact = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (nameAfterChange && idContactPhoneBook === contact.id) {
      setContact(preState => ({ ...preState, name: nameAfterChange }));
    }
  }, [nameAfterChange, idContactPhoneBook]);

  useEffect(() => {
    setContact(data);
  }, [data]);

  return (
    <Box
      className={clsx(classes.conversationRoot, isHovered && classes.hoveredBox, "no-select-text")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={chatItemRef}
    >
      <Box className={classes.contactItemRoot} onClick={onClick}>
        <AvatarInfo
          avatar={{
            avatarId: data.avatarId,
          }}
          title={data.name}
          sendToMe={data.id === accountId}
          className={classes.rootSpacing}
        />

        <IconButton className={classes.timeAndAction} onClick={onShowContactItemMenu}>
          <MoreHoriz />
        </IconButton>
      </Box>

      <Popover
        classes={{ paper: classes.popover }}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <PopupContact data={data} onOpenPopupContact={onOpenPopupContact} />
      </Popover>
    </Box>
  );
};

ContactItem.propTypes = {
  data: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "auto",
  },

  rootSpacing: {
    padding: "10px 0px",
  },

  firstLetter: {
    lineHeight: "1rem",
  },

  contactItemRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  contactName: {
    lineHeight: "1.125rem",
    height: "fit-content",
    flex: 1,
  },

  chatItemAvatar: {
    width: 50,
    height: 70,
  },

  popover: {
    borderRadius: 20,
    padding: "12px 6px",
    overflow: "visible",
    boxShadow: "none",
    filter: "drop-shadow(3px 4px 14px rgba(0, 0, 0, 0.25))",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-13%",
      left: "46%",
      width: 20,
      height: 20,
      backgroundColor: theme.palette.white,
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },

  groupContent: {
    display: "flex",
    marginLeft: 16,
    flex: 1,
    borderBottom: "0.5px solid " + theme.palette.divider,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  timeAndAction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },

  conversationRoot: {
    display: "flex",
    cursor: "pointer",
    width: "100%",
  },

  hoveredBox: {
    backgroundColor: "#F1F3F6",
  },
}));
