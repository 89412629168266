import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import { LangConstant, PathConstant } from "const";
import { getCommonLang } from "utils/lang.utils";
import { useDispatch } from "react-redux";
import { AuthActions } from "redux-store";
import { getSavedServer } from "utils/branch.utils";

const OTPLocked = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);

  const onGoBack2Login = () => {
    const savedServer = getSavedServer();
    if (savedServer.id) {
      history.replace(PathConstant.ROOT);
    } else {
      dispatch(
        AuthActions.authSet({
          phoneNumber: null,
        }),
      );
    }

    onClose();
  };

  return (
    <Box className={classes.otpMainForm}>
      <Typography className={clsx(classes.otpLockedTitle, "bold-xl-txt")}>
        {getLabel(LangConstant.TXT_OTP_LOCKED)}
      </Typography>
      <Typography className={classes.otpLockedMessage}>{getLabel(LangConstant.TXT_OTP_LOCKED_FULL_MESSAGE)}</Typography>
      <Button variant="contained" className={clsx(classes.resendButton, "semiBold-lg-txt")} onClick={onGoBack2Login}>
        {getCommonLang(LangConstant.TXT_OK)}
      </Button>
    </Box>
  );
};

OTPLocked.propTypes = {};

export default OTPLocked;

const useStyles = makeStyles(theme => ({
  otpMainForm: {
    backgroundColor: theme.palette.white,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 80px",
    [theme.breakpoints.down(1350)]: {
      padding: "0 50px",
    },
  },

  otpLockedTitle: {
    textAlign: "center",
    lineHeight: "1.25rem",
  },

  resendButton: {
    width: "100%",
    lineHeight: "1.25rem",
    paddingTop: 14,
    paddingBottom: 14,
  },

  otpLockedMessage: {
    fontSize: "0.9375rem",
    fontWeight: 400,
    lineHeight: "1.125rem",
    textAlign: "center",
    marginTop: 8,
    marginBottom: 16,
  },
}));
