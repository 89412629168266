import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { AppDrawer } from "components";
import { makeStyles } from "@mui/styles";
import SelectingListContact from "../SelectingListContact";
import { useAccountList, useContactList, useCreateConversation } from "hooks";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";

const AddingGroupDrawer = ({ onComplete, ...otherProps }) => {
  const classes = useStyles();

  const { contactList } = useContactList();
  const { accountList } = useAccountList();
  const { createGroup } = useCreateConversation();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const newGroupId = useSelector(state => state.groupInfoRedux.newGroupId);

  const [groupName, setGroupName] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const onChangeGroupName = event => {
    setGroupName(event.target.value);
  };

  const onCreateGroup = memberList => {
    setIsFetching(true); // Start adding group
    createGroup(memberList, groupName);
  };

  useEffect(() => {
    // callback when complete adding group
    if (isFetching && newGroupId) {
      if (onComplete) onComplete();
    }
  }, [isFetching, newGroupId]);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const selectList = isBranchServer
    ? accountList.filter(item => item.id !== accountId)
    : contactList.filter(item => item.contactId !== accountId);

  return (
    <AppDrawer title={chatObjContent.createGroup} {...otherProps}>
      <InputBase
        fullWidth
        className={classes.inputGroupName}
        placeholder={chatObjContent.groupPlaceHolder}
        onChange={onChangeGroupName}
        inputProps={{ maxLength: SystemConstant.MAX_NAME_LENGTH }}
      />
      <SelectingListContact
        data={selectList}
        onComplete={onCreateGroup}
        minimumLimit={SystemConstant.MINIMUM_SELECT_CONTACT}
      />
    </AppDrawer>
  );
};

AddingGroupDrawer.propTypes = {
  isConferenceFlow: PropTypes.bool,
  onComplete: PropTypes.func,

  ...AppDrawer.propTypes,
};

AddingGroupDrawer.defaultProps = {};

export default AddingGroupDrawer;

const useStyles = makeStyles({
  inputGroupName: {
    padding: 16,
    "&, & input": { fontWeight: 700, fontSize: "0.875rem", color: "#1E272E", textAlign: "center" },
  },
});
