import React, { useState, memo, Fragment, useEffect } from "react";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { Button, Stack, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppDrawer, NoticeDialog } from "components";
import { getLabel } from "language";
import { StorageUtil, isNotEqual } from "utils";
import { ElectronService } from "services/local.service";
import { getSavedServer } from "utils/branch.utils";
import SettingAppLogo from "./SettingAppLogo";

const SettingLogo = ({ open, onClose }) => {
  const classes = useStyles();
  const { t: getHomeLabel } = useTranslation(LangConstant.NS_HOME);
  const settingObjContent = getHomeLabel("OBJ_SETTING_APP", {
    returnObjects: true,
  });

  const successChanging = settingObjContent.success;

  const [isNotice, setIsNotice] = useState(false);
  const [defaultSetting, setDefaultSetting] = useState();
  const [changingSetting, setChangingSetting] = useState();

  const handleChangeLogo = branchId =>
    setChangingSetting(preState => ({ ...preState, [KeyConstant.KEY_BRANCH_ID]: branchId }));

  const handleSaveSetting = () => {
    const storageSetting = StorageUtil.getCommonKey(KeyConstant.KEY_SETTING_APP) || {};
    StorageUtil.setCommonKey(KeyConstant.KEY_SETTING_APP, { ...storageSetting, ...changingSetting });

    if (defaultSetting[KeyConstant.KEY_BRANCH_ID] !== changingSetting[KeyConstant.KEY_BRANCH_ID]) {
      // Set logo app
      ElectronService.setLogoApp(changingSetting[KeyConstant.KEY_BRANCH_ID]);
    }

    setIsNotice(true);
  };

  const handleCloseNotice = () => {
    setIsNotice(false);
    onClose();
  };

  useEffect(() => {
    const storageSetting = StorageUtil.getCommonKey(KeyConstant.KEY_SETTING_APP) || {};
    const currentBranchId = getSavedServer().id;
    const setting = {
      ...storageSetting,
      [KeyConstant.KEY_BRANCH_ID]:
        storageSetting[KeyConstant.KEY_BRANCH_ID] || currentBranchId || SystemConstant.GLOBAL_BRANCH_ID,
    };

    setDefaultSetting(setting);
    setChangingSetting(setting);
  }, [open]);

  if (!defaultSetting) return <Fragment />;

  const isChanged = isNotEqual(changingSetting, defaultSetting);
  return (
    <>
      <AppDrawer open={open} onClose={onClose} title={settingObjContent.appIcon}>
        <Stack spacing={1} className={classes.settingList}>
          <Paper elevation={0}>
            <SettingAppLogo currentBranchId={defaultSetting[KeyConstant.KEY_BRANCH_ID]} onChange={handleChangeLogo} />
          </Paper>

          <Paper elevation={0} sx={{ flexGrow: 1 }}>
            <Button fullWidth variant="contained" disabled={!isChanged} onClick={handleSaveSetting}>
              {getLabel("TXT_SAVE")}
            </Button>
          </Paper>
        </Stack>
      </AppDrawer>

      {isNotice && (
        <NoticeDialog
          open={isNotice}
          content={successChanging}
          submitProps={{
            onClick: handleCloseNotice,
          }}
        />
      )}
    </>
  );
};

SettingLogo.propTypes = {};

export default memo(SettingLogo);

const useStyles = makeStyles(theme => ({
  settingList: {
    background: theme.palette.grey[100],
    paddingTop: theme.spacing(1),

    "& > *": {
      padding: theme.spacing(1, 2),
    },
  },

  logoList: {
    width: "100%",
    overflowY: "hidden",
    overflowX: "auto",
    padding: theme.spacing(1, 3, 0, 3),
  },

  logoItem: {
    color: theme.palette.primary.main,
    textAlign: "center",

    "&:hover": {
      "& $logoImage": {
        border: `1px solid rgba(0, 143, 232, 0.5)`,
      },
    },
  },

  notOutline: {
    borderColor: "transparent",
  },

  logoImage: {
    padding: 6,
    margin: theme.spacing(0, 1),

    "& img": {
      maxWidth: "100%",
      height: 40,
      objectFit: "contain",
    },
  },
}));
