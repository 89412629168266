import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getCommonLang } from "utils/lang.utils";
import { AddAdmin, RemoveCircle, GroupRemove } from "components/icons";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { ConfirmDialog, AppDrawer, AppAvatar } from "components";
import { useCreateConversation } from "hooks";
import { getInteractor } from "services/local.service";
import { AccountActions, GroupInfoActions } from "redux-store";
import { Call, Message, VideoCall } from "@mui/icons-material";
import { StorageUtil } from "utils";
import BackgroundImage from "../Account/BackgroundImage";
import { getSavedServer } from "utils/branch.utils";

// TODO: Should remove accountBlockArray and make clearly props
const MemberDetail = ({
  data,
  groupDetail,
  onClose,
  onCloseParent,
  dataAdmin,
  isAdminGroup,
  accountBlockArray,
  onRemoveMember,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { createWithPersonal } = useCreateConversation();

  const [isMe, setIsMe] = useState(false);

  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const isInCall = useSelector(state => state.callingRedux.isInCall);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const contactPhoneContent = getCommonLang(LangConstant.TXT_CONFIRM);
  const confirmDetail = getLabel(LangConstant.FM_CONFIRM_DETAIL, {
    confirm: data?.name,
  });
  const confirmBlock = getLabel("FM_CONFIRM_BLOCK_DETAIL", { groupName: data?.name });
  const confirmRemove = getLabel(
    groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group
      ? LangConstant.FM_CONFIRM_REMOVE
      : LangConstant.FM_CONFIRM_REMOVE_CHANNEL_MEMBER,
    {
      memberName: data?.name,
    },
  );

  const [isConfirmAdd, setIsConfirmAdd] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [isConfirmUnBlock, setIsConfirmUnBlock] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  //TODO check it
  const [isBlocked, setIsBlocked] = useState(false);
  const [isCheckBlock, setIsCheckBlock] = useState(false);

  const onClickAddNew = () => {
    dispatch(
      GroupInfoActions.addAdmin({
        groupId: groupDetail.id,
        memberId: data.id,
        memberInviteType: SystemConstant.ROLE_OBJ.admin,
      }),
    );
    setIsConfirmAdd(false);
    onClose();
  };

  const onClickRemove = () => {
    if (isMe) return;
    dispatch(
      GroupInfoActions.removeMemberGroup({
        groupId: groupDetail.id,
        memberId: data?.id,
      }),
    );

    setIsRemove(false);
    onRemoveMember(data?.id);
  };

  const onBlockDetail = () => {
    dispatch(AccountActions.blockUser({ groupId: groupDetail.id, blockedAccountId: data?.id }));
    setIsConfirm(false);
    onClose();
  };

  const onUnblockDetail = () => {
    dispatch(AccountActions.unblockUser({ groupId: groupDetail.id, blockedAccountId: data?.id }));
    setIsConfirmUnBlock(false);
    onClose();
  };

  const onMessenger = () => {
    createWithPersonal(data);

    onClose();
    onCloseParent();
  };

  const onCallVoice = () => {
    createWithPersonal(data, { isDemandVoiceCall: true });

    onClose();
    onCloseParent();
  };

  const onCallVideo = () => {
    createWithPersonal(data, { isDemandVideoCall: true });

    onClose();
    onCloseParent();
  };

  useEffect(() => {
    setIsCheckBlock(accountBlockArray.filter(item => item.contactId === data?.id).length > 0);
  }, [data, accountBlockArray]);

  useEffect(() => {
    if (data !== null && dataAdmin !== undefined) {
      let filterUndefined = dataAdmin.filter(item => item !== undefined);
      let listAdmin = filterUndefined.filter(item => item.id === data.id);
      setIsAdmin(listAdmin.length > 0);
      setIsMe(data.id === accountId);

      getInteractor()
        .LocalContactService.getContact(accountId, data.id)
        .then(checkingContact => setIsBlocked(checkingContact?.status === SystemConstant.CONTACT_STATUS.block));
    } else {
      setIsAdmin(true);
    }
  }, [data, dataAdmin]);

  return (
    <AppDrawer
      anchor="right"
      open={Boolean(data)}
      onClose={onClose}
      hideBackdrop={false}
      classes={{
        root: classes.drawerRoot,
      }}
    >
      <Card className={classes.coverBox} elevation={0}>
        <BackgroundImage backgroundId={data?.backgroundId} />
        <Box className={classes.avatarBox}>
          <AppAvatar className={classes.avatar} avatarId={data?.avatarId} />
        </Box>
      </Card>
      <Typography variant="subtitle1" className={classes.textTitle}>
        {data?.name}
      </Typography>
      <Box className={classes.actionContainer}>
        {!isMe && (
          <Box className={classes.girdDetail}>
            <Grid justifyContent="center" alignItems="center" container>
              <Grid item xs={3} className={classes.personalActionButton}>
                <IconButton className={classes.iconBtn} onClick={onCallVoice} disabled={isInCall || !hasInternet}>
                  <Call />
                </IconButton>
                <Typography variant="body2">{getLabel(LangConstant.TXT_VOICE_CALL)}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.personalActionButton}>
                <IconButton className={classes.iconBtn} onClick={onCallVideo} disabled={isInCall || !hasInternet}>
                  <VideoCall />
                </IconButton>
                <Typography variant="body2">{getLabel(LangConstant.TXT_VIDEO_CALL)}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.personalActionButton} onClick={onMessenger}>
                <IconButton className={classes.iconBtn} onClick={onMessenger}>
                  <Message />
                </IconButton>
                <Typography variant="body2">{getLabel(LangConstant.TXT_MESSENGER_MEMBER)}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {isAdminGroup && (
          <Button
            className={clsx(classes.personalActionItem, { [classes.hiddenComponent]: isAdmin })}
            onClick={() => setIsConfirmAdd(true)}
          >
            <Typography className={classes.detailAction}>{getLabel(LangConstant.TXT_ADD_ADMIN_MEMBER)}</Typography>
            <AddAdmin className={classes.icon} />
          </Button>
        )}
        {!isMe && getSavedServer()?.type === SystemConstant.SERVER_TYPE.server && (
          <Box>
            {isCheckBlock || isBlocked ? (
              <Button className={classes.personalActionItem} onClick={() => setIsConfirmUnBlock(true)}>
                <Typography className={classes.detailAction}>{getLabel("TXT_UN_BLOCK")}</Typography>
                <RemoveCircle className={classes.icon} />
              </Button>
            ) : (
              <Button className={classes.personalActionItem} onClick={() => setIsConfirm(true)}>
                <Typography className={classes.detailAction}>{getLabel("TXT_BLOCK")}</Typography>
                <RemoveCircle className={classes.icon} />
              </Button>
            )}
          </Box>
        )}
        {isAdminGroup && !isMe && (
          <Button className={classes.personalActionItem} onClick={() => setIsRemove(true)}>
            <Typography className={classes.detailAction}>
              {groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group
                ? getLabel(LangConstant.TXT_REMOVER_MEMBER)
                : getLabel(LangConstant.TXT_REMOVE_MEMBER_CHANNEL)}
            </Typography>
            <GroupRemove className={classes.icon} />
          </Button>
        )}
      </Box>

      <ConfirmDialog
        open={isConfirmAdd}
        onClose={() => setIsConfirmAdd(false)}
        title={getLabel(LangConstant.TXT_ADD_GROUP_ADMIN)}
        content={confirmDetail}
        submitProps={{
          submitText: contactPhoneContent,
          className: classes.colorButton,
          onClick: onClickAddNew,
        }}
        cancelProps={{
          onClick: () => setIsConfirmAdd(false),
          className: classes.cancel,
        }}
      />
      <ConfirmDialog
        open={isConfirm}
        onClose={() => setIsConfirm(false)}
        title={getLabel("TXT_BLOCK_AND_CALL")}
        content={confirmBlock}
        submitProps={{
          submitText: contactPhoneContent,
          onClick: onBlockDetail,
          className: classes.submitPropsConfirmDialog,
        }}
        cancelProps={{
          className: classes.cancelPropsConfirmDialog,
          onClick: () => setIsConfirm(false),
        }}
      />
      <ConfirmDialog
        open={isConfirmUnBlock}
        onClose={() => setIsConfirmUnBlock(false)}
        title={getLabel("TXT_UN_BLOCK")}
        content={confirmBlock}
        submitProps={{
          className: classes.colorButtonRemove,
          submitText: contactPhoneContent,
          onClick: onUnblockDetail,
        }}
        cancelProps={{
          onClick: () => setIsConfirmUnBlock(false),
          className: classes.cancel,
        }}
      />
      <ConfirmDialog
        open={isRemove}
        onClose={() => setIsRemove(false)}
        title={getLabel(LangConstant.TXT_CONFIRM_REMOVE)}
        content={confirmRemove}
        submitProps={{
          className: classes.colorButtonRemove,
          submitText: getCommonLang(LangConstant.TXT_DELETE),
          onClick: onClickRemove,
        }}
        cancelProps={{
          onClick: () => setIsRemove(false),
          className: classes.cancel,
        }}
      />
    </AppDrawer>
  );
};

export default memo(MemberDetail);

const useStyles = makeStyles(theme => ({
  textTitle: {
    textAlign: "center",
  },

  girdDetail: {
    textAlign: "center",
    marginTop: 12,
    marginBottom: 15,
  },

  colorButtonRemove: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.dark,
    },
  },

  colorButton: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
  },

  cancel: {
    width: "auto",
    color: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: theme.palette.white,
      color: theme.palette.grey[500],
    },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "darkgray",
    marginBottom: 36,
  },

  coverEmpty: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    fontSize: "3rem",
  },

  name: {
    fontWeight: 700,
    fontSize: "0.9375rem",
  },

  drawerRoot: {
    "&>.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },

  detailAction: {
    fontWeight: 400,
  },

  personalActionButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
  },

  personalActionItem: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    justifyContent: "space-between",
    textTransform: "none",
    color: theme.palette.black,
    height: 40,
  },

  icon: {
    width: "unset",
    height: "unset",
  },

  hiddenComponent: {
    display: "none",
  },

  cancelPropsConfirmDialog: {
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.grey[500],
    },
  },

  submitPropsConfirmDialog: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },

  iconBtn: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: 4,
  },
}));
