import { Circle } from "@mui/icons-material";
import { Avatar, Badge, Box, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { SystemConstant } from "const";
import { forwardRef } from "react";
import { formatLimitNumber } from "utils";
import { getCommonLang } from "utils/lang.utils";

const ServerItem = ({ data, classes = {}, isOnline, onClick, ...otherProps }, ref) => {
  const defaultClasses = useStyles();
  const isLoggedOut = data.loginState !== SystemConstant.STATE.active;

  return (
    <ListItem disablePadding onClick={onClick} {...otherProps}>
      <ListItemButton ref={ref} className={clsx(defaultClasses.serverItem, classes.serverItem)}>
        <Badge
          badgeContent={formatLimitNumber(data.totalUnread)}
          color="primary"
          classes={{ root: defaultClasses.numNotify, badge: "badge" }}
        >
          <Avatar src={data.branchIcon} className={defaultClasses.avatar} />
        </Badge>
        <Box>
          <ListItemText
            primary={
              <Box display="flex" alignItems="center">
                <Typography className={defaultClasses.text}>{data.name || ""}</Typography>
                {!isLoggedOut && (
                  <Circle
                    className={clsx(
                      isOnline ? defaultClasses.circleOnline : defaultClasses.circleOffline,
                      classes.statusIcon,
                    )}
                  />
                )}
              </Box>
            }
          />
          <ListItemText
            primary={
              <Typography className={defaultClasses.subTitle}>
                {getCommonLang(isLoggedOut ? "TXT_LOGGED_OUT_BRANCH" : "")}
              </Typography>
            }
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default forwardRef(ServerItem);

const useStyles = makeStyles({
  serverItem: {
    borderRadius: "10px",
    minHeight: 66,
  },

  avatar: {
    width: 36,
    height: 36,
  },

  text: {
    fontSize: 15,
    fontWeight: 700,
    color: "#1E272E",
    marginLeft: 24,
    marginRight: 8,
  },

  circleOnline: {
    color: "#35CC3F",
    width: 12,
    height: 12,
  },

  circleOffline: {
    color: "yellow",
    width: 12,
    height: 12,
  },

  subTitle: {
    fontSize: 12,
    marginLeft: 24,
    marginRight: 8,
    color: "#6C7078",
  },

  numNotify: {
    "& .badge": {
      top: 4,
      right: -5,
      backgroundColor: "#EE4F49",
      border: "2px solid white",
    },
  },
});
