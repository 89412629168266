import { Box, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import { useMessageStyle } from "./ChatItem.helper";
import { AttachmentUtil, FileUtil, StorageUtil } from "utils";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ForwardingIcon } from "components/icons";
import { FolderOutlined, DownloadOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { getInteractor } from "services/local.service";
import FileAvatar from "components/avatar/FileAvatar";
import { useEffect, useRef, useState } from "react";
import { useCleanUpEffect, useDownloadFile } from "hooks";
import { SCROLL_TRIGGER_CLASS } from "../useQueryMessage";

const FileBox = ({ mediaData, isMine = false, isShowOptMenu = false, disablePadding = false, groupId, onForward }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const statusRef = useRef();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { downloadFile, openFolder } = useDownloadFile();
  const { isMounted } = useCleanUpEffect();

  const [fileAvatarUrl, setFileAvatarUrl] = useState("");
  const [uploadStatus, setUploadStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleDownloadFile = () => {
    if (isUploadSuccess) {
      downloadFile([mediaData], groupId);
    } else {
      alert(getLabel(LangConstant.TXT_FILE_NOT_FOUND));
    }
  };

  const handleOpenFileFolder = () => openFolder(mediaData);

  useEffect(() => {
    statusRef.current = mediaData.status;
    setUploadStatus(mediaData.status);
    if (mediaData.filePath) {
      setFileAvatarUrl(mediaData.filePath);
    } else if (mediaData.attachmentId) {
      getInteractor(prefixKey)
        .LocalFileService.get(mediaData.attachmentId)
        .then(fileRecord => {
          if (fileRecord) {
            const url = AttachmentUtil.getLocalPath(fileRecord.url, mediaData.metaData.fileName);
            if (isMounted()) setFileAvatarUrl(url);
          }
        });
    }
  }, [mediaData]);

  useEffect(() => {
    const checkStatusTimeout = setTimeout(() => {
      if (statusRef.current === SystemConstant.UPLOAD_STATUS.inProgress) {
        setUploadStatus(SystemConstant.UPLOAD_STATUS.failed);
        getInteractor(prefixKey).LocalFileService.updateFileFail(mediaData.attachmentId);
      }
    }, AppConstant.CHECK_UPLOAD_TIMEOUT);

    return () => clearTimeout(checkStatusTimeout);
  }, []);

  const isUploading = uploadStatus === SystemConstant.UPLOAD_STATUS.inProgress;
  const isUploadFailed = uploadStatus === SystemConstant.UPLOAD_STATUS.failed;
  const isUploadSuccess = uploadStatus === SystemConstant.UPLOAD_STATUS.success;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      className={clsx(
        messengerClasses.messengerRoot,
        !disablePadding && messengerClasses.spacing,
        classes.contentContainer,
        isMine && messengerClasses.myMessage,
        isUploadFailed && classes.disableStyle,
        SCROLL_TRIGGER_CLASS,
      )}
    >
      <FileAvatar url={fileAvatarUrl} file={mediaData} />

      <Box flex={1}>
        <Typography variant="subtitle2" color="inherit" className="ellipsis">
          {mediaData.metaData.fileName}
        </Typography>

        <Typography variant="caption" color="inherit" className="ellipsis">
          {FileUtil.convertFileSizeUnit(mediaData.metaData.size)}
        </Typography>

        {isUploading && <LinearProgress color="inherit" />}
      </Box>

      {isShowOptMenu && isUploadSuccess && (
        <Stack direction="row" className={classes.groupIconList}>
          <IconButton color={isMine ? "inherit" : "#000000"} onClick={handleOpenFileFolder}>
            <FolderOutlined />
          </IconButton>
          <IconButton color={isMine ? "inherit" : "#000000"} onClick={onForward}>
            <ForwardingIcon />
          </IconButton>
          <IconButton color={isMine ? "inherit" : "#000000"} onClick={handleDownloadFile}>
            <DownloadOutlined />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default FileBox;

const useStyles = makeStyles(theme => ({
  contentContainer: {
    width: "100%",
    borderRadius: 18,
    paddingTop: 8,
    paddingBottom: 8,

    "&:hover $groupIconList": {
      display: "flex",
    },
  },

  filePreviewIcon: {
    width: 48,
    height: 48,
    backgroundColor: theme.palette.white,
  },

  groupIconList: {
    display: "none",
  },

  forwardingIcon: {
    width: 24,
    height: 24,
  },

  disableStyle: {
    cursor: "default",
    opacity: 0.5,
    "&:hover $groupIconList": {
      display: "none",
    },
  },
}));
