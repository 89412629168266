export default {
  TXT_ACCOUNT: "Me",
  TXT_SERVERS: "Máy chủ",
  TXT_BRAND_SERVERS: "Máy chủ nhánh",
  TXT_CHATS: "Nhắn tin",
  TXT_CONTACT: "Danh bạ",
  TXT_NO_MESSAGE: "Không có tin nhắn",
  TXT_CHANNEL: "Kênh",
  TXT_PERSONAL: "Cá nhân",
  TXT_GROUP: "Nhóm",
  TXT_CHAT_SEARCH_PLACE_HOLDER: "Tìm kiếm người, nhóm & tin nhắn",
  TXT_NO_NOTIFICATION: "Không có thông báo",
  TXT_CONFIRM_REMOVE_PHOTO: "Xác nhận xóa ảnh",
  TXT_SURE_REMOVE: "Bạn có chắc chắn muốn xóa ảnh này không?",
  TXT_DELETE: "Xoá",
  TXT_CANCEL: "Huỷ",
  TXT_NO_INTERNET: "Không có kết nối mạng",
  TXT_CONFERENCE: "Hội nghị",
  TXT_CHANGE_ROLE: "Chuyển quyền thành thiết bị chủ",
  TXT_CONFIRM_CHANGE_ROLE_TITLE: "Xác nhận chuyển quyền",
  TXT_CONFIRM_CHANGE_ROLE_CONTENT:
    "Để tránh mất dữ liệu, hãy đảm bảo thiết bị chuyển quyền của bạn có đầy đủ dữ liệu. Vui lòng click Xác nhận để thực hiện",
  TXT_CHANGE_ROLE_OTP_TITLE: "Nhập mã SMS OTP xác thực",
  TXT_VERIFY: "Xác thực",
  TXT_VIEW_MESSAGE_THREAD: "Xem tin nhắn luồng",
  TXT_VIEW_MORE: "Xem thêm",
  TXT_MEMBER_OVER_TOOLTIP: "Số thành viên trong nhóm vượt giới hạn cuộc gọi",
  TXT_FLOW_MESSAGE: "Tin nhắn luồng",
  TXT_NO_UNSEEN_THREAD: "Không có tin nhắn luồng",
  TXT_REPLY: "Trả lời",
  TXT_NO_MEMBER_CHOOSE: "Chưa có thành viên",
  TXT_REPLY_THREAD_MESSAGE: "Trả lời tin nhắn luồng",
  TXT_AVATAR: "Ảnh cá nhân",
  TXT_COVER: "Ảnh bìa",
  TXT_SHARE_BY_OTHER: "Được chia sẻ",
  TXT_HAS_NEW_MESSAGE: "Tin nhắn mới",
  TXT_READ_ALL_MSG_TITLE: "Đọc tất cả tin nhắn",
  TXT_READ_ALL_MSG_SUCCESS: "Bạn đã đọc tất cả tin nhắn",
  TXT_READ_ALL_MSG_FAIL: "Đọc tất cả tin nhắn không thành công",
  TXT_SETTING_UI: "Thiết lập giao diện",
  TXT_SETTING_PRIVATE: "Quyền riêng tư và bảo mật",
  TXT_SETTING_FONT_SIZE: "Thiết lập cỡ chữ",
  TXT_FONT_SIZE_CONVERSATION: "Cỡ chữ tin nhắn",
  TXT_FONT_SIZE_SIDEBAR: "Cỡ chữ trong trò chuyện",
  TXT_FONT_SIZE_TITLE_APP: "Cỡ chữ tiêu đề",
  TXT_CHANGE_FONT_SIZE: "Kéo để thay đổi cỡ chữ",

  FM_WELCOME: `Chào mừng tới Trios, {{name}}!`,
  FM_TIME_FORMAT: "{{amount}} {{timeUnit}}",
  FM_MENTION_NOTIFICATION: "<b>{{accountName}}</b> đã nhắc đến bạn trong <b>{{groupName}}</b>: “{{message}}”",
  FM_MENTION_INBOX: "<b>{{accountName}}</b> đã nhắc đến bạn trong tin nhắn: “{{message}}”",
  FM_OTP_NOTIFICATION: "<b>Mã Smart OTP</b> đã được tạo : “{{message}}” ",
  FM_OWNER_NOTIFICATION: "<b>Thông báo:</b> “{{message}}” ",
  FM_LEAVE_GROUP_channel: "<b>{{senderName}}</b> đã rời kênh.",
  FM_LEAVE_GROUP_group: "<b>{{senderName}}</b> đã rời nhóm.",
  FM_GROUP_REMOVE_MEMBER_channel: "<b>{{senderName}}</b> đã xóa <b>{{accountRemove}}</b> khỏi kênh.",
  FM_GROUP_REMOVE_MEMBER_group: "<b>{{senderName}}</b> đã xóa <b>{{accountRemove}}</b> khỏi nhóm.",
  FM_DOWNLOADED_CONTENT: "Đang tải: {{ downloaded }} / {{ total }} ({{ percentage }}%)",
  FM_DOWNLOAD_SPEED: "Tốc độ tải xuống: {{ speed }} Mb/s",
  FM_CHANGE_ROLE_OTP_CONTENT: "Chúng tôi đã gửi mã OTP tới {{ phone }}. Vui lòng nhập mã để chuyển quyền thiết bị chủ",
  FM_NUMBER_THREAD_REPLY: "{{number}} trả lời",
  FM_CHANNEL_TAG: "Kênh ({{number}})",
  FM_GROUP_TAG: "Nhóm ({{number}})",
  FM_PERSONAL_TAG: "Cá nhân ({{number}})",
  FM_NUMBER_FLOW_MESSAGE: "Tin nhắn luồng ({{number}})",
  FM_MEMBER: "Thành viên ({{number}})",
  FM_CHOOSE_MEMBER: "Thành viên đã chọn ({{number}})",
  FM_INACTIVE_PAGE: "Bạn đã bị vô hiệu hóa trên {{branchName}}.\nVui lòng liên hệ quản trị để tiếp tục sử dụng",
  FM_PHONE_CODE: "+{{ code }}",
  FM_CONFERENCE_MEMBER_ERROR: "Số thành viên tối đa tham gia cuộc gọi hội nghị là {{max_person}} người",
  FM_FAVORITE_CHAT: "Mục ưa thích ({{number}})",
  FM_UNREAD_CHAT: "Tin nhắn chưa đọc ({{number}})",

  OBJ_ACCOUNT_ME: {
    title: "Cá nhân",
    setAway: "Chuyển sang trạng thái vắng mặt",
    setActive: "Chuyển sang trạng thái hoạt động",
    profile: "Trang cá nhân",
    device: "Quản lý thiết bị",
    license: "Bản quyền của bạn",
    about: "Về chúng tôi",
    delete: "Xóa tài khoản",
    logout: "Đăng xuất",
    setting: "Thiết lập",
    manageFile: "Quản lý tệp",
  },
  OBJ_ACCOUNT_PROFILE: {
    title: "Thông tin cá nhân",
    name: "Tên",
    phone: "Số điện thoại",
    email: "Email",
    birthday: "Ngày sinh",
    editProFile: "Thay đổi thông tin",
    takePhoto: "Chụp ảnh",
    uploadPhoto: "Tải lên ảnh",
    viewPhoto: "Xem ảnh",
    removePhoto: "Xóa ảnh",
    editYourProfile: "Chỉnh sửa thông tin cá nhân",
    address: "Địa chỉ",
    gender: "Giới tính",
    male: "Nam",
    female: "Nữ",
    other: "Khác",
    undefinedGender: "Giới tính không xác định",
    position: "Vị trí",
    unit: "Đơn vị",
    department: "Phòng ban",
  },
  OBJ_EDIT_PROFILE: {
    fullName: "Nhập tên của bạn",
    enterFullName: "Nhập tên của bạn",
    email: "Email",
    enterEmail: "Nhập email của bạn",
    birthday: "Ngày sinh",
    address: "Địa chỉ",
    enterAddress: "Nhập địa chỉ của bạn",
    save: "Lưu",
    gender: "Giới tính",
    male: "Nam",
    female: "Nữ",
    other: "Khác",
    undefinedGender: "Giới tính không xác định",
  },
  OBJ_ACCOUNT_LICENSE: {
    title: "Bản quyền",
    contact: `Muốn nâng cấp bản quyền? <br>Hãy gọi <a href="tel:{phone}" data-rel=""><span>{phone}</span></a>`,
  },
  OBJ_ACCOUNT_ABOUT: {
    title: "Về chúng tôi",
    email: `Email: <br><a href="mailto:{email}"><span>{email}</span></a>`,
    contact: `Liên hệ: <br><a href="tel:{phone}" data-rel=""><span>{phone}</span></a>`,
  },
  OBJ_ACCOUNT_DEVICE: {
    title: "Quản lý thiết bị",
    description: "Thiết bị sử dụng Trios",
    masterDevice: "Thiết bị chủ",
    otherDevice: "Thiết bị phụ",
    descriptionFormat: "Trios Version {{version}} ({{deviceType}})",
    statusOfflineFormat: "Vắng mặt, hoạt động lần cuối khoảng {{fuzzyTime}} trước.",
    statusOnlineFormat: "Đang hoạt động.",
    currentDevice: "Thiết bị hiện tại",
    logoutFromThisDevice: "Đăng xuất",
    version: "Phiên bản: {{version}}",
    buildVersion: "Phiên bản: {{version}} ({{buildVersion}})",
  },
  OBJ_ADDING_CHAT: {
    title: "Hội thoại mới",
    titleGroup: "Nhóm mới",
    titleChanel: "Kênh mới",
    tabMessage: "Tin nhắn",
    tabChannel: "Kênh",
    createGroup: "Tạo nhóm chat mới",
    createChannel: "+ Tạo kênh mới",
    people: "Người dùng",
    emptyMessage: "Bạn chưa có liên hệ nào",
    emptyChannel: "Hiện chưa có kênh nào",
    groupPlaceHolder: "Nhập tên nhóm (Tùy chọn)",
    btnDone: "Xong",
    btnContinue: "Tiếp tục",
    txtName: "Tên",
    channelPlaceHolder: "Ví dụ (Nhóm-quản-lý)",
    txtPrivacy: "Quyền riêng tư",
    txtMakePrivacy: "Kênh riêng tư",
    txtPrivacyDescription: "Khi bạn đặt kênh thành riêng tư. Chỉ những người được mời mới có thể xem được",
    titleConference: "Gọi hội nghị",
  },
  OBJ_ADDING_CONTACT: {
    title: "Thêm danh bạ",
    phoneNumber: "Số điện thoại",
    search: "Tìm kiếm",
    addContact: "Thêm bạn",
    callContact: "Gọi điện",
    videoContact: "Gọi video",
    mesContact: "Nhắn tin",
    notification: "Thông báo",
    notRegisterPhone: "Số điện thoại này chưa được đăng kí Trios",
    changeNickname: "Thay đổi biệt danh",
    name: "Nhập tên",
    newName: "Nhập tên mới",
    change: "Thay đổi",
    cancel: "Huỷ",
    deleteContact: "Xoá",
    deleteContactPopup: "Xoá liên hệ",
    deleteContactTitlePopup:
      "Bạn có muốn xóa liên hệ này khỏi danh bạ? \nNó có nghĩa là xóa {{ name }} khỏi danh sách bạn bè",
    currentUser: "(Người dùng hiện tại)",
  },
  OBJ_DEVICE_TYPE_TEXT: {
    android: "Android",
    ios: "IOS",
    window: "Windows",
    unknown: "Unknown device",
    linux: "Linux",
    mac: "Mac",
  },
  OBJ_INIT_GROUP_CALL: {
    groupMembers: "Thành viên nhóm ({0})",
    membersChoose: "Thành viên đã chọn ({0})",
  },
  OBJ_SETTING_APP: {
    appIcon: "Biểu tượng ứng dụng",
    selectedLogo: "Đang sử dụng",
    success: "Thiết lập cấu hình thành công",
  },

  OBJ_PREVIEW_CONTENT: {
    previewGroupName: "Trios",
    previewLastMessage: "Bạn: Xin chào, ...",
    previewTime: "09:20",
    previewAppTab: "Thiết lập",
    previewMyMsg: "Xin chào, khỏe không?",
    previewOtherMsg: "Xin chào",
  },
};
