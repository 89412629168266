import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import clsx from "clsx";
import StringFormat from "string-format";
import { AvatarInfo } from "components";
import { PinIcon } from "components/icons";
import { useContext, useEffect, useState } from "react";
import { CallingContext } from "pages/Call";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import MicOffIcon from "@mui/icons-material/MicOff";

const UserInCall = ({ isOpen, onClose }) => {
  const classes = useStyle();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const waitingForCall = useSelector(state => state.callingRedux.waitingForCall);
  const { handUpListId, videoFlagArr, mutedArray, pinnedList, sharingList, remoteStreamArr, callingGroupDetail } =
    useContext(CallingContext);
  const [userConnected, setUserConnected] = useState([]);

  useEffect(() => {
    const tmpArr = remoteStreamArr.map(item => item.accountId);
    const groupMembers = callingGroupDetail.groupMembers || [];
    const usersConnected = groupMembers.filter(item => tmpArr.includes(item.id));

    setUserConnected(usersConnected);
  }, [remoteStreamArr]);

  useEffect(() => {
    if (userConnected?.length === handUpListId?.length) {
      return;
    } else {
      let tmpList = [...remoteStreamArr];
      handUpListId.forEach(item => {
        let tmpObj = tmpList.find(obj => obj.accountId === item);
        let indexMe = tmpList.indexOf(tmpObj);
        if (indexMe > 0) {
          let tmp = tmpList[0];
          tmpList[0] = tmpList[indexMe];
          tmpList[indexMe] = tmp;
        }
      });

      const groupMembers = callingGroupDetail.groupMembers || [];
      let userHanded = [];
      tmpList.forEach(item => {
        let object = groupMembers.find(obj => obj?.id === item.accountId);
        if (Boolean(object)) {
          userHanded = [...userHanded, object];
        }
      });
      setUserConnected(userHanded);
    }
  }, [handUpListId]);

  return (
    <Dialog open={isOpen} classes={{ paper: classes.userInCall }}>
      <DialogTitle className={classes.titleBox}>
        <Typography className={classes.title}>{getLabel(LangConstant.TXT_USER_IN_CALL_TITLE)}</Typography>
        <IconButton onClick={onClose}>
          <HighlightOffIcon className={classes.iconButton} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Button fullWidth variant="text" className={classes.copyLink} startIcon={<ContentCopyIcon />}>
          {getLabel(LangConstant.TXT_COPY_LINK_CALL)}
        </Button>

        <Box>
          {Boolean(waitingForCall) && waitingForCall.length > 0 && (
            <Typography variant="subtitle2" className={clsx(classes.subTitle)}>
              {StringFormat(getLabel(LangConstant.TXT_USER_WAITING), waitingForCall.length)}
            </Typography>
          )}
          {waitingForCall.map(item => {
            return (
              <Box key={item.id} className={classes.inCallItem}>
                <AvatarInfo
                  secondTitle={getLabel(LangConstant.TXT_WAITING)}
                  title={item.contactName || item.name || item.phone}
                  avatar={{ avatarId: item.avatarId, name: item.name }}
                  titleProps={{ color: "white" }}
                  sx={{ padding: 0 }}
                />
                <Box className={classes.userAction}>
                  <IconButton>
                    <CheckCircleOutlineIcon color="success" />
                  </IconButton>
                  <IconButton>
                    <HighlightOffIcon color="error" />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box>
          <Typography variant="subtitle2" className={clsx(classes.subTitle)}>
            {StringFormat(getLabel(LangConstant.TXT_USER_IN_CALL), userConnected.length)}
          </Typography>
          {userConnected.map(item => {
            let isSharing = sharingList.filter(obj => obj.accountId === item.id).length > 0;
            let isMute = mutedArray.includes(item.id);
            let isVideoing = !videoFlagArr.includes(item.id);
            let isRaiseHand = handUpListId.includes(item.id);
            let isPinned = pinnedList.filter(obj => obj.accountId === item.id).length > 0;
            return (
              <Box key={item.id} className={classes.inCallItem}>
                <AvatarInfo
                  title={item.contactName || item.name || item.phone}
                  avatar={{ avatarId: item.avatarId, name: item.name }}
                  titleProps={{ color: "white" }}
                  sx={{ padding: 0 }}
                />
                <Box className={classes.userAction}>
                  {isSharing && <PresentToAllIcon className={classes.iconButton} />}
                  {isVideoing && <VideocamIcon className={classes.iconButton} />}

                  {isPinned && <PinIcon color="white" className={classes.iconButton} />}
                  {isMute ? <MicOffIcon className={classes.iconButton} /> : <MicIcon className={classes.iconButton} />}
                  {isRaiseHand && <BackHandOutlinedIcon className={clsx(classes.iconButton, classes.iconSize)} />}
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserInCall;

const useStyle = makeStyles(theme => ({
  userInCall: {
    width: "80%",
    minWidth: "600px",
    height: "fit-content",
    maxHeight: "800px",
    backgroundColor: "#242526",
    color: "white",
  },

  titleBox: {
    display: "flex",
    alignItems: "center",
  },

  title: {
    margin: "auto",
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "white",
  },

  subTitle: {
    fontSize: "0.75rem",
    color: "white",
    textAlign: "left",
    margin: "10px 0px",
  },

  copyLink: {
    color: "white",
    background: "#3A3A3A",
    padding: "12px",
  },

  inCallItem: {
    display: "flex",
    justifyContent: "space-between",
    margin: "5px 0px",
  },

  userAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },

  iconButton: {
    color: "white",
    marginLeft: "5px",
  },

  iconSize: {
    fontSize: "20px",
  },
}));
