import palette from "./palette";

export default {
  fontFamily: ["Open Sans", "Roboto", "Arial", "sans-serif"].join(","),
  h1: {
    color: palette.grey[700],
    fontWeight: 700,
    fontSize: "3.75rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h2: {
    color: palette.grey[700],
    fontWeight: 700,
    fontSize: "3rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h3: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "2.25rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h4: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "1.75rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h5: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: "1.5rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h6: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: "1.25rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  subtitle1: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "1rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  subtitle2: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "0.9375rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  body1: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: "0.9375rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  body2: {
    color: palette.grey[700],
    fontWeight: 400,
    fontSize: "0.75rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  button: {
    color: palette.white,
    fontWeight: 700,
    fontSize: "0.875rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  caption: {
    color: palette.grey[700],
    fontWeight: 400,
    fontSize: "0.625rem",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
};
