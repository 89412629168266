import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isString } from "utils";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const ConfirmDialog = ({
  title,
  content,
  errorContent,
  submitProps: { submitText, ...otherSubmitProps },
  cancelProps: { cancelText, ...otherCancelProps },
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t: getLabel } = useTranslation();
  const primaryContent = isString(content) ? (
    <Typography variant="body2" className={classes.contentText}>
      {content}
    </Typography>
  ) : (
    content
  );

  return (
    <Dialog fullScreen={isFullScreen} classes={{ paper: classes.root }} {...otherProps}>
      <DialogTitle className={classes.title}>{title || ""}</DialogTitle>
      <DialogContent className={classes.contentRoot}>{primaryContent}</DialogContent>
      <Box className={classes.action}>
        <Button color="secondary" variant="contained" size="large" fullWidth {...otherSubmitProps}>
          {submitText || getLabel(LangConstant.TXT_OK)}
        </Button>
        <Button size="large" fullWidth {...otherCancelProps}>
          {cancelText || getLabel(LangConstant.TXT_CANCEL)}
        </Button>
      </Box>
      {errorContent && <Typography className={classes.errorContent}>{errorContent}</Typography>}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  errorContent: PropTypes.string,
  submitProps: PropTypes.object,
  cancelProps: PropTypes.object,
};
ConfirmDialog.defaultProps = {
  submitProps: {},
  cancelProps: {},
};

export default ConfirmDialog;

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    padding: theme.spacing(1.5, 3),
    textAlign: "center",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 700,
  },
  contentRoot: {
    padding: 0,
  },
  contentText: {
    fontSize: "0.9375rem",
    color: theme.palette.text.secondary,
  },
  action: {
    marginTop: 16,

    "& > *": {
      marginTop: 4,
      marginBottom: 4,
    },
    "& > *:last-child": {
      color: "#A8A8A8",
    },
  },

  errorContent: {
    textAlign: "center",
    color: "red",
  },
}));
