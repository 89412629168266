import { memo, useEffect, useState } from "react";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useCleanUpEffect } from "hooks";
import { StorageUtil } from "utils";
import { useConversationContext } from "../ConversationContext";
import { getInteractor } from "services/local.service";
import { getNSLang } from "utils/lang.utils";
import LineChat from "../LineChat";

const LeaveGroupMessage = ({ message }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const { isMounted } = useCleanUpEffect();

  const { groupDetail } = useConversationContext();
  const isChannel = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;

  const [content, setContent] = useState("");

  const handleMessageContent = async () => {
    if (message.sendType === SystemConstant.SEND_TYPE.leaveGroup && message.senderId) {
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const removeAccountId = message.content;

      if (message.senderId && removeAccountId) {
        const senderAccount = await getInteractor(prefixKey).LocalAccountService.get(message.senderId, branchId);
        const removedAccount =
          message.senderId !== removeAccountId
            ? await getInteractor(prefixKey).LocalAccountService.get(removeAccountId, branchId)
            : {};

        const leaveGroupObj = {
          senderName: senderAccount.name,
          accountRemovedName: removedAccount.name,
        };

        const displayContent = getNSLang(
          LangConstant.NS_HOME,
          message.senderId === removeAccountId ? "FM_LEAVE_GROUP" : "FM_GROUP_REMOVE_MEMBER",
          {
            context: isChannel ? "channel" : "group",
            senderName: message.senderId === accountId ? "Bạn" : leaveGroupObj.senderName,
            accountRemove: removedAccount.id === accountId ? "bạn" : removedAccount.name,
          },
        );

        if (isMounted()) setContent(displayContent);
      }
    }
  };

  useEffect(() => {
    handleMessageContent();
  }, [message]);

  return <LineChat data={content} />;
};

export default memo(LeaveGroupMessage);
