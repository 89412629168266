import { useEffect, useState } from "react";
import { KeyConstant, SystemConstant } from "const";
import { useSelector } from "react-redux";
import { ConversationSelectors } from "redux-store";
import { createSelector } from "reselect";
import { getInteractor } from "services/local.service";
import { StorageUtil, toCamel } from "utils";
import useCleanUpEffect from "./useCleanUpEffect";
import { getLatestMessage } from "utils/view.utils";
import { getCommonLang } from "utils/lang.utils";
import orderBy from "lodash/orderBy";

const memoizedReduxState = createSelector(
  [ConversationSelectors.getSelectedGroupId, ConversationSelectors.getChangePinList],
  (selectedGroupId, changePinList) => ({
    selectedGroupId,
    changePinList,
  }),
);
const usePinList = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const { isMounted } = useCleanUpEffect();
  const { selectedGroupId, changePinList } = useSelector(memoizedReduxState);

  const [pinList, setPinList] = useState([]);

  const handleGetPinList = async () => {
    try {
      const localPinList = await getInteractor(prefixKey).LocalGroupMessageService.find({
        group_id: selectedGroupId,
        state: SystemConstant.STATE.active,
        type: SystemConstant.GROUP_MESSAGE_TYPE.pin,
      });

      const refactorPinList = localPinList.map(item => {
        const maxTime = item.modified ? Math.max([item.modified, item.created]) : item.created;
        return { ...item, maxTime };
      });

      const sortedPinList = orderBy(refactorPinList, "maxTime", "desc");

      const pinListDetail = [];
      for (let index = 0; index < sortedPinList.length; index++) {
        const pin = sortedPinList[index];
        let pinMessage = await getInteractor(prefixKey).LocalMessageService.findOne({
          source_id: pin.source_message_id,
        });

        if (pinMessage && pinMessage.id) {
          pinMessage = await getLatestMessage(prefixKey, toCamel(pinMessage));
          const senderAccount = await getInteractor(prefixKey).LocalAccountService.get(pinMessage.senderId, branchId);
          pinListDetail.push(
            toCamel({
              ...pin,
              pinMessage: {
                ...pinMessage,
                avatarId: senderAccount.avatarId,
                avatarUrl: senderAccount.avatarUrl,
                senderName: senderAccount.name,
              },
            }),
          );
        } else {
          pinListDetail.push(
            toCamel({
              ...pin,
              pinMessage: {
                content: getCommonLang("TXT_NOT_FOUND_MESSAGE"),
                isNotFound: true,
                groupId: selectedGroupId,
              },
            }),
          );
        }
      }

      if (isMounted()) setPinList(pinListDetail);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedGroupId) handleGetPinList();
  }, [changePinList, selectedGroupId]);

  return { pinList, pinLength: pinList.length };
};

export default usePinList;
