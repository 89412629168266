import React, { memo } from "react";
import { Box, Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AccountStatus, AppAvatar } from "components";
import BackgroundImage from "./BackgroundImage";

const AccountCoverView = ({ isActive, backgroundId, name, avatarId }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Card className={classes.coverBox} elevation={0}>
        <BackgroundImage backgroundId={backgroundId} />
        <Box className={classes.avatarBox}>
          <AppAvatar avatarId={avatarId} name={name} size={120} className={classes.customAvatar} />
        </Box>
      </Card>
      <Typography className={classes.name}>{name}</Typography>
      <Box>
        <AccountStatus isActive={isActive} />
      </Box>
    </Box>
  );
};

export default memo(AccountCoverView);

const useStyles = makeStyles({
  root: {
    "& > *": { textAlign: "center" },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  customAvatar: {
    border: "2px solid white",
  },

  name: {
    fontWeight: 700,
    fontSize: "0.9375rem",
  },
});
