import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { formatSentTime } from "utils/date.utils";
import { StorageUtil, getDataFromPrefixKey, isJSONString, toCamel } from "utils";
import { LangConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { ConversationActions } from "redux-store";
import { isGroupOrChannelType } from "pages/Call";
import { useStyles } from "./NotificationInviteItem";
import { AvatarGroup } from "components";

const NotificationTextItem = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const [account_id, branch_id] = getDataFromPrefixKey(prefixKey);

  const [senderDetail, setSenderDetail] = useState({
    avatarId: null,
    name: "",
    groupName: "",
    groupId: "",
    groupType: "",
  });
  const [message, setMessage] = useState({});

  const handleClickNotification = async () => {
    // Using find function because the function need to update sql to query data
    const groupList = await getInteractor().LocalGroupService.find({ account_id, branch_id, id: senderDetail.groupId });
    const group = groupList[0];

    if (group && group.id) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: message.groupId,
          threadingId: message.threadId,
          scrollToChildId: message.state === SystemConstant.STATE.active ? message.parentId || message.sourceId : null,
        }),
      );
    }
  };

  useEffect(() => {
    if (false === Boolean(data)) return;

    const queryParams = {
      ...data,
      options: isJSONString(data.options) ? toCamel(JSON.parse(data.options)) : {},
    };
    getInteractor(prefixKey)
      .LocalNotificationService.getNormalNotificationDetail(queryParams)
      .then(noticeDetail => {
        setSenderDetail({
          ...noticeDetail,
          name: noticeDetail.accountName,
          avatarId: noticeDetail.accountAvatar,
        });
        setMessage(toCamel({ ...noticeDetail.message }));
      });
  }, [data]);

  return (
    <Box className={classes.mentionNotificationItemRoot} onClick={handleClickNotification} {...otherProps}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <AvatarGroup avatarId={senderDetail.avatarId} group={{ groupName: senderDetail.name }} />
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(
                isGroupOrChannelType(senderDetail.groupType)
                  ? LangConstant.FM_MENTION_NOTIFICATION
                  : LangConstant.FM_MENTION_INBOX,
                {
                  accountName: senderDetail.name,
                  groupName: senderDetail.groupName,
                  message: message.content,
                },
              ),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationTextItem;
