import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Button, Dialog, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import { getNSLang } from "utils/lang.utils";
import { StorageUtil, formatEmoij, isArrayNotEquals, swapArrayElements, toCamel } from "utils";
import { AppConstant, LangConstant } from "const";
import { SearchBar, InfiniteScroll } from "components";
import { getInteractor } from "services/local.service";
import { useFavoriteEmoji } from "hooks";

const FullEmojiMenu = props => {
  const { onSelectEmoji, onClose, open, anchorEl, ...otherProps } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { favoriteEmoji } = useFavoriteEmoji();
  const { searchValue, limit, emojiCategories, searchEmoji, changeLimitEmoji } = useEmojiList();

  const [isOpenCustomizeEmojiDialog, setIsOpenCustomizeEmojiDialog] = useState(false);

  const onSelect = value => {
    onSelectEmoji(value);
  };

  const onClickCustomizeButton = () => {
    setIsOpenCustomizeEmojiDialog(true);
    onClosePopup();
  };

  const onCloseCustomizeEmojiDialog = () => {
    setIsOpenCustomizeEmojiDialog(false);
  };

  const onClosePopup = () => {
    searchEmoji("");
    onClose();
  };

  const onScrollToBottom = () => changeLimitEmoji(limit + DEFAULT_LIMIT_RECORD);

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={onClosePopup}
        anchorEl={anchorEl}
        open={open}
        classes={{ paper: classes.paper }}
        {...otherProps}
      >
        <Box className={classes.root}>
          <SearchBar value={searchValue} onChange={searchEmoji} classes={{ root: classes.searchBar }} />
          <Box className={classes.emojiWrapper}>
            <Box className={classes.header}>
              <Typography className={clsx(classes.heading, "regular-sm-txt")}>
                {getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_STATIC_REACTION)}
              </Typography>
              <Typography className={clsx(classes.customButton, "semiBold-sm-txt")} onClick={onClickCustomizeButton}>
                {getLabel(LangConstant.TXT_CUSTOMIZE)}
              </Typography>
            </Box>
            <Box className={classes.body}>
              {favoriteEmoji.map((emoji, index) => (
                <Box
                  key={index}
                  className={classes.emojiItem}
                  dangerouslySetInnerHTML={{ __html: formatEmoij(emoji) }}
                  onClick={() => onSelect(emoji)}
                />
              ))}
            </Box>
          </Box>
          <InfiniteScroll className={classes.fullEmojiContainer} onScrollToBottom={onScrollToBottom}>
            {emojiCategories.map(emoji => (
              <Box key={emoji.group} className={classes.paper}>
                <Box className={classes.header}>
                  <Typography className={clsx(classes.heading, "regular-sm-txt")}>{emoji.group}</Typography>
                </Box>
                <Box className={classes.body}>
                  {emoji.emojiList.map((emoji, index) => (
                    <Box
                      key={index}
                      className={classes.emojiItem}
                      dangerouslySetInnerHTML={{ __html: formatEmoij(emoji.hexCode) }}
                      onClick={() => onSelect(emoji.hexCode)}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Popover>

      {isOpenCustomizeEmojiDialog && (
        <CustomizeStaticEmojiDialog
          favoriteEmoji={favoriteEmoji}
          open={isOpenCustomizeEmojiDialog}
          onClose={onCloseCustomizeEmojiDialog}
        />
      )}
    </>
  );
};

export default FullEmojiMenu;

FullEmojiMenu.propTypes = {
  onSelectEmoji: PropTypes.func,
  onClose: PropTypes.func,

  open: PropTypes.bool,
};

FullEmojiMenu.defaultProps = {
  onSelectEmoji: () => {},
  onClose: () => {},

  open: false,
};

const DEFAULT_LIMIT_RECORD = 30;

const CustomizeStaticEmojiDialog = props => {
  const { open, favoriteEmoji, onClose, ...otherProps } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { updateFavoriteEmoji } = useFavoriteEmoji();
  const { searchValue, limit, emojiCategories, searchEmoji, changeLimitEmoji } = useEmojiList();

  const [staticEmoji, setStaticEmoji] = useState(favoriteEmoji);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const onClickStaticEmoji = emoji => {
    setSelectedEmoji(emoji);
  };

  const onClickNormalEmoji = emoji => {
    if (selectedEmoji) {
      let newStaticEmoji = [];
      if (staticEmoji.includes(emoji)) {
        let newEmojiIndex = staticEmoji.indexOf(emoji);
        let selectedEmojiIndex = staticEmoji.indexOf(selectedEmoji);
        newStaticEmoji = swapArrayElements(staticEmoji, selectedEmojiIndex, newEmojiIndex);
      } else {
        newStaticEmoji = staticEmoji.map(staticEmojiItem => {
          return staticEmojiItem === selectedEmoji ? emoji : staticEmojiItem;
        });
      }
      setStaticEmoji(newStaticEmoji);
      setSelectedEmoji(emoji);
    }
  };

  const onClickResetButton = () => {
    setStaticEmoji(favoriteEmoji);
    setSelectedEmoji(null);
    searchEmoji("");
  };

  const onClickSaveButton = () => {
    if (isArrayNotEquals(favoriteEmoji, staticEmoji)) {
      searchEmoji("");
      updateFavoriteEmoji(staticEmoji);
      onCloseDialog();
    }
  };

  const onCloseDialog = () => {
    setSelectedEmoji(null);
    searchEmoji("");
    onClose();
  };

  const onScrollToBottom = () => {
    changeLimitEmoji(limit + DEFAULT_LIMIT_RECORD);
  };

  useEffect(() => {
    if (isArrayNotEquals(favoriteEmoji, staticEmoji)) setStaticEmoji(favoriteEmoji);
  }, [favoriteEmoji]);

  return (
    <Dialog open={open} onClose={onCloseDialog} classes={{ paper: classes.paper }} {...otherProps}>
      <Box className={classes.root}>
        <Box className={classes.emojiWrapper}>
          <Box className={classes.body}>
            {staticEmoji.map((emoji, index) => (
              <Box
                key={index}
                className={clsx(classes.emojiItem, {
                  [classes.unSelectedEmoji]: emoji !== selectedEmoji,
                })}
                onClick={() => onClickStaticEmoji(emoji)}
                dangerouslySetInnerHTML={{ __html: formatEmoij(emoji) }}
              />
            ))}
          </Box>
        </Box>
        <Typography className={clsx(classes.customizeEmojiText, "regular-sm-txt")}>
          {getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_CUSTOM_EMOJI)}
        </Typography>
        <SearchBar value={searchValue} onChange={searchEmoji} classes={{ root: classes.searchBar }} />
        <InfiniteScroll className={classes.fullEmojiContainer} onScrollToBottom={onScrollToBottom}>
          {emojiCategories.map(emoji => (
            <Box key={emoji.group} className={classes.paper}>
              <Box className={classes.header}>
                <Typography className={clsx(classes.heading, "regular-sm-txt")}>{emoji.group}</Typography>
              </Box>
              <Box className={classes.body}>
                {emoji.emojiList.map((emoji, index) => (
                  <Box
                    key={index}
                    className={classes.emojiItem}
                    onClick={() => onClickNormalEmoji(emoji.hexCode)}
                    dangerouslySetInnerHTML={{ __html: formatEmoij(emoji.hexCode) }}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
        <Box className={classes.footer}>
          <Button
            color="primary"
            variant="text"
            className={classes.textButton}
            onClick={onClickResetButton}
            disabled={!isArrayNotEquals(staticEmoji, AppConstant.DEFAULT_EMOJI)}
          >
            {getLabel(LangConstant.TXT_RESET)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={onClickSaveButton}
            disabled={!isArrayNotEquals(favoriteEmoji, staticEmoji)}
          >
            {getLabel(LangConstant.TXT_SAVE)}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CustomizeStaticEmojiDialog.propTypes = {
  onClose: PropTypes.func,

  open: PropTypes.bool,
};

CustomizeStaticEmojiDialog.defaultProps = {
  onClose: () => {},

  open: false,
};

const useEmojiList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [emojiCategories, setEmojiCategories] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_LIMIT_RECORD);

  const searchEmoji = value => setSearchValue(value);
  const changeLimitEmoji = newLimit => setLimit(newLimit);

  useEffect(() => {
    let isMounted = true;
    const fetchEmojiListTimeout = setTimeout(() => {
      getInteractor(StorageUtil.getCurrentPrefixKey())
        .LocalEmojiService.findEmoji(searchValue, limit)
        .then(result => {
          if (!isMounted) return;

          const emojiList = toCamel(result);
          const handleEmojiObj = emojiList.reduce((finalEmojiList, currentEmoji) => {
            const group = currentEmoji.emojiGroup;
            finalEmojiList[group] = (finalEmojiList[group] || []).concat(currentEmoji);

            return finalEmojiList;
          }, {});

          const handleEmojiArr = Object.entries(handleEmojiObj).map(([group, emojiList]) => {
            return { group: group, emojiList: emojiList };
          });

          if (isMounted) setEmojiCategories(handleEmojiArr);
        });
    }, AppConstant.DEBOUNCE_TIME);

    return () => {
      isMounted = false;
      clearTimeout(fetchEmojiListTimeout);
    };
  }, [searchValue, limit]);

  return { searchValue, limit, emojiCategories, searchEmoji, changeLimitEmoji };
};

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },

  root: {
    width: 360,
    padding: "20px 14px",
    marginBottom: 12,
    marginRight: 12,
    backgroundColor: theme.palette.white,
    borderRadius: 20,
    boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.1)",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    position: "sticky",
    backgroundColor: theme.palette.white,
  },

  heading: {
    lineHeight: "1rem",
    color: theme.palette.grey[500],
  },

  customButton: {
    lineHeight: "1rem",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    cursor: "pointer",
  },

  body: {
    display: "flex",
    flexWrap: "wrap",
  },

  emojiItem: {
    height: 30,
    width: "calc(360px / 12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 12,
    fontSize: "1.875rem",
    cursor: "pointer",
    userSelect: "none",
  },

  fullEmojiContainer: {
    height: 250,
    overflowY: "scroll",
  },

  customizeEmojiText: {
    lineHeight: "1rem",
    color: theme.palette.black,
    textAlign: "center",
    marginBottom: 12,
  },

  searchBar: {
    marginBottom: 12,
  },

  footer: {
    marginTop: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  containedButton: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.white,
    },
  },

  textButton: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    "&:disabled": {
      color: theme.palette.primary.light,
    },
  },

  unSelectedEmoji: {
    opacity: 0.5,
  },
}));
