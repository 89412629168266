import React, { useState } from "react";
import PropTypes from "prop-types";
import { LinearProgress, Popover, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { getCommonLang } from "utils/lang.utils";

const DownloadProcess = ({ content, isShow }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [isHidingProcessDialog, setIsHidingProcessDialog] = useState(false);

  return (
    <>
      {isHidingProcessDialog && isShow ? (
        <Box
          className={classes.hiddenProcessBox}
          onClick={() => {
            setIsHidingProcessDialog(false);
          }}
        >
          <Typography className={classes.downloadedPercent}>{content?.downloadedPercentage}%</Typography>
          <LinearProgress
            className={classes.hiddenLinearProcess}
            variant="determinate"
            value={content?.downloadedPercentage}
          />
        </Box>
      ) : (
        <Popover
          open={isShow}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialogPaper,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          hideBackdrop={true}
        >
          <Typography className={classes.processContent}>
            {getLabel(LangConstant.FM_DOWNLOADED_CONTENT, {
              percentage: content?.downloadedPercentage,
              downloaded: content?.downloadedContent,
              total: content?.totalContent,
            })}
          </Typography>
          <Typography className={classes.downloadSpeed}>
            {getLabel(LangConstant.FM_DOWNLOAD_SPEED, {
              speed: content?.downloadSpeed,
            })}
          </Typography>

          <LinearProgress variant="determinate" value={content?.downloadedPercentage} />
          <Box className={classes.dialogAction}>
            <Button
              variant="text"
              className={classes.hideButton}
              onClick={() => {
                setIsHidingProcessDialog(true);
              }}
            >
              {getCommonLang(LangConstant.TXT_HIDE)}
            </Button>
          </Box>
        </Popover>
      )}
    </>
  );
};

DownloadProcess.propTypes = {
  content: PropTypes.objectOf({
    downloadSpeed: PropTypes.number,
    downloadedPercentage: PropTypes.number,
    downloadedContent: PropTypes.number,
    totalContent: PropTypes.number,
  }),

  isShow: PropTypes.bool,
};

DownloadProcess.defaultProps = {
  content: {
    downloadSpeed: 0,
    downloadedPercentage: 0,
    downloadedContent: 0,
    totalContent: 0,
  },

  isShow: false,
};

export default DownloadProcess;

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    width: "max-content",
    height: "max-content",
  },

  dialogPaper: {
    padding: 10,
    minWidth: "max-content",
    minHeight: "max-content",
    maxWidth: "max-content",
    maxHeight: "max-content",
  },

  processContent: {
    fontSize: "1rem",
    fontWeight: 550,
    textAlign: "center",
  },

  downloadSpeed: {
    fontSize: "1rem",
    fontWeight: 550,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },

  dialogAction: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  hideButton: {
    textTransform: "none",
    marginTop: 5,
    fontSize: "1rem",
    fontWeight: 450,
  },

  hiddenLinearProcess: {
    height: 4,
    zIndex: theme.zIndex.tooltip + 1,
    transition: "height 0.2s ease-in-out",
    "&:hover": {
      height: 18,
    },
  },

  hiddenProcessBox: {
    cursor: "pointer",
    position: "absolute",
    width: "100vw",
    top: 0,
    left: 0,
    "&:hover": {
      "&>p": {
        opacity: 1,
      },
    },
  },

  downloadedPercent: {
    position: "absolute",
    right: "50%",
    transform: "translate(-50%, 0%)",
    zIndex: theme.zIndex.tooltip + 2,
    top: 0,
    fontSize: "0.875rem",
    fontWeight: 500,
    opacity: 0,
    transition: "opacity 0.2s ease-in-out",
  },
}));
