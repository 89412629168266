import { Fragment, useEffect, useState } from "react";
import { ExpandCircleDownOutlined, Message } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  Icon as IconMui,
  IconButton,
  List,
  ListItem as ListItemMui,
  ListItemButton as ListItemButtonMui,
  Stack,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { ConfirmDialog } from "components";
import { UnpinIcon } from "components/icons";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { usePinList } from "hooks";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { getPinContent } from "utils/message.utils";
import { useConversationContext } from "./ConversationContext";
import ChatTypography from "./ChatItem/ChatTypography";

const PinList = () => {
  const classes = useStyles();
  const { pinList, pinLength } = usePinList();

  const [isExpand, setIsExpand] = useState(false);

  const handleClick = () => {
    setIsExpand(!isExpand);
  };

  if (pinLength === 0) return <Fragment />;

  return (
    <Box className={classes.root}>
      {pinLength === 1 && (
        <>
          <Divider />
          <ListItem>
            <PinItem pin={pinList[0]} />
          </ListItem>
          <Divider />
        </>
      )}
      {pinLength > 1 && (
        <ClickAwayListener onClickAway={() => setIsExpand(false)}>
          <Box className={classes.pinListRoot}>
            <ListItem>
              <ListItemButton onClick={handleClick}>
                <Stack direction="row" justifyContent="space-between" width="100%">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {!isExpand && <Message color="primary" />}
                    <Typography className={classes.listTitle}>
                      {getNSLang(LangConstant.NS_HOME_CONVERSATION, "FM_TOTAL_PIN", { number: pinLength })}
                    </Typography>
                  </Stack>
                  <Icon>
                    <ExpandCircleDownOutlined color="primary" className={isExpand ? classes.revertIcon : ""} />
                  </Icon>
                </Stack>
              </ListItemButton>
            </ListItem>

            <Collapse in={isExpand} className={classes.customCollapse}>
              <List component="div" disablePadding>
                {pinList.map(item => (
                  <Box key={item.id}>
                    <ListItem>
                      <PinItem pin={item} onClosePinList={() => setIsExpand(false)} />
                    </ListItem>
                    <Divider />
                  </Box>
                ))}
              </List>
            </Collapse>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default PinList;

/**
 * pin: {id, groupId, pinMessage, type, options, state, created, modified}
 */
const PinItem = ({ pin, onClosePinList }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showAlert } = useAlertContext();
  const { groupDetail } = useConversationContext();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);

  const handleUnpin = () => {
    dispatch(
      ConversationActions.pinMessage({
        sourceId: pin.sourceMessageId,
        type: SystemConstant.GROUP_MESSAGE_TYPE.pin,
        state: SystemConstant.PIN_TYPE.unpin,
        groupId: pin.groupId,
      }),
    );
    setIsOpenConfirm(false);
  };

  const handleJump2Msg = () => {
    if (pin.pinMessage && false === Boolean(pin.pinMessage.isNotFound)) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: pin.pinMessage.groupId,
          threadingId: pin.pinMessage.threadId,
          scrollToChildId: pin.pinMessage.state === SystemConstant.STATE.active ? pin.pinMessage.sourceId : null,
        }),
      );
    } else {
      showAlert({ content: getCommonLang("TXT_NOT_FOUND_MESSAGE"), alertProps: { severity: "error" } });
    }

    if (onClosePinList) onClosePinList();
  };

  const handleClickUnpin = event => {
    event.stopPropagation();
    setIsOpenConfirm(true);
  };

  useEffect(() => {
    if (pin.pinMessage) {
      const { senderName, senderId, isNotFound } = pin.pinMessage;
      const isMyMessage = senderId === StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const pinContent = getPinContent(pin.pinMessage, true);

      setTitle(pinContent);
      setSubtitle(
        isNotFound
          ? null
          : getNSLang(LangConstant.NS_HOME_CONVERSATION, "FM_PIN_OWNER", {
              senderName: isMyMessage ? getNSLang(LangConstant.NS_CALLING, "TXT_YOU") : senderName,
            }),
      );
    } else {
      setTitle(null);
      setSubtitle(null);
    }
  }, [pin]);

  const isShowUnpinIcon = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal || groupDetail.isAdmin;

  return (
    <>
      <Stack className={classes.pinItemRoot} onClick={handleJump2Msg}>
        <Stack direction="row" alignItems="center" height="2.5rem">
          <Message color="primary" />
          <Stack ml={1}>
            <ChatTypography className="ellipsis" messageContent={title} messageId={pin.pinMessage.id}>
              {title}
            </ChatTypography>
            <Typography variant="body2" className="ellipsis">
              {subtitle}
            </Typography>
          </Stack>
        </Stack>

        {isShowUnpinIcon && (
          <IconButton onClick={handleClickUnpin}>
            <UnpinIcon />
          </IconButton>
        )}
      </Stack>

      {isOpenConfirm && (
        <ConfirmDialog
          open
          title={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CONFIRM_UNPIN_TITLE")}
          content={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CONFIRM_UNPIN_SUBTITLE")}
          submitProps={{ submitText: getCommonLang("TXT_UNPIN"), onClick: handleUnpin }}
          cancelProps={{ onClick: () => setIsOpenConfirm(false) }}
        />
      )}
    </>
  );
};

const ListItem = styled(ListItemMui)({
  padding: 0,
});

const ListItemButton = styled(ListItemButtonMui)({
  padding: "8px 22px",
  borderStyle: "solid",
  borderColor: "#E6E8EB",
  borderTopWidth: "0.1px",
  borderBottomWidth: "0.1px",
});

const Icon = styled(IconMui)({
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "fit-content",
  },

  customCollapse: {
    width: "100%",
    height: "fit-content",
    maxHeight: "calc(7.5rem + 30px)",
    position: "absolute",
    zIndex: 6,
    background: "#ffffff",
    boxShadow: "0px 6px 7px rgba(0, 0, 0, 0.1)",
    overflow: "auto",
  },

  revertIcon: {
    transform: "rotate(180deg)",
  },

  pinListRoot: {
    width: "100%",
    position: "relative",
  },

  pinItemRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px 0px",
    padding: "0px 22px",
    cursor: "pointer",
  },

  listTitle: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
});
