import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { WelcomeImage } from "const/image.const";
import { useAccount } from "hooks";
import { ConversationSelectors } from "redux-store";
import { createSelector } from "reselect";

const memoizedReduxState = createSelector(
  [ConversationSelectors.getSelectedGroupId, ConversationSelectors.getThreadingId],
  (selectedGroupId, selectedThreadId) => {
    return Boolean(selectedGroupId || selectedThreadId);
  },
);

const Welcome = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const {
    currentAccount: { name },
  } = useAccount();

  const isHidden = useSelector(memoizedReduxState);

  return (
    <Stack className={isHidden ? "hidden" : classes.root} spacing={2}>
      <img src={WelcomeImage} alt="welcome" className={classes.welcomeImg} />
      <Typography className={classes.welcomeText}>{getLabel(LangConstant.FM_WELCOME, { name })}</Typography>
    </Stack>
  );
};

Welcome.propTypes = { name: PropTypes.string };

export default memo(Welcome);

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#F7F7F8",
  },

  welcomeImg: {
    maxHeight: 300,
    objectFit: "contain",
  },

  welcomeText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    color: "#2A2C2F",
  },
});
