import { useState } from "react";
import styled from "@emotion/styled";
import { PlayArrow } from "@mui/icons-material";
import { Box, ListItemAvatar, Avatar as MuiAvatar } from "@mui/material";
import { FilePreviewIcon } from "components/icons";
import { FileUtil, isAudio, isImage, isVideo } from "utils";
import { getThumbnailUrlCache } from "services/attachment.service";
import { useLazyEffect } from "hooks";

// file = { attachmentId, aesKeyInfo, metaData }
// url: local path
const FileAvatar = ({ url, file }) => {
  const fileName = file.metaData?.fileName || "";
  const mimeType = file.metaData?.mimeType || "";
  const filePreviewIconColor = fileName ? FileUtil.getFileColor(fileName) : "";

  const [fileUrl, setFileUrl] = useState();

  useLazyEffect(() => {
    let isMounted = true;
    if ((isImage(mimeType) || isVideo(mimeType)) && url) {
      getThumbnailUrlCache(file, url).then(thumbnail => {
        if (isMounted) setFileUrl(thumbnail.mediaPath);
      });
    }

    return () => {
      isMounted = false;
    };
  }, [file, url]);

  return (
    <ListItemAvatar>
      <Avatar alt={`Avatar - ${fileName}`}>
        {(isImage(mimeType) || isVideo(mimeType)) && fileUrl ? (
          <img src={fileUrl} alt={fileName} />
        ) : isAudio(mimeType) ? (
          <Box className="flex-center">
            <PlayArrow fontSize="large" />
          </Box>
        ) : (
          <FilePreviewIcon color={filePreviewIconColor} />
        )}
      </Avatar>
    </ListItemAvatar>
  );
};

export default FileAvatar;

const Avatar = styled(MuiAvatar)(props => ({
  width: 48,
  height: 48,
  backgroundColor: props.theme.palette.white,
  color: props.theme.palette.grey[400],

  "& img, & video": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "& .flex-center": {
    width: "100%",
    height: "100%",
    backgroundColor: props.theme.palette.grey[400],
    color: props.theme.palette.white,
  },
}));
