import React, { memo, useCallback, Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AvatarInfo, SearchBar } from "components";
import EmptyContact from "./EmptyContact";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { Box, Divider, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import AddingGroupButton from "./AddingGroupButton";
import clsx from "clsx";
import { useAccountList, useContactList, useCreateConversation } from "hooks";
import { TabPanel } from "@mui/base";
import { getSearchResult } from "utils/view.utils";
import { getSavedServer } from "utils/branch.utils";
import { StorageUtil } from "utils";

const AddingMessageTab = ({ onCloseDrawer }) => {
  const classes = useStyles();
  const searchRef = useRef();

  const { isFetching, contactList } = useContactList();
  const { accountList } = useAccountList();
  const { createWithPersonal } = useCreateConversation();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });
  const isBranchServer = getSavedServer().id !== SystemConstant.GLOBAL_BRANCH_ID;
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const [viewingList, setViewingList] = useState([]);

  const onCreateSelect = useCallback(
    contact => () => {
      createWithPersonal(contact);
      onCloseDrawer();
    },
    [],
  );

  const handleChangeSearchInput = content => {
    searchRef.current = content;

    let viewList = [];
    if (isBranchServer && content) {
      viewList = getSearchResult(content, accountList || [], ["name", "phone"]);
    } else {
      viewList = getSearchResult(content, contactList || [], ["name", "phone"]);
    }

    setViewingList(viewList);
  };

  useEffect(() => {
    handleChangeSearchInput(searchRef.current);
  }, [contactList, accountList]);

  const isEmptyChatting = false === isFetching && contactList.length === 0;

  return (
    <TabPanel value={AppConstant.CHATTING_TAB_MODE.message}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={handleChangeSearchInput} />
      </Box>
      {isEmptyChatting ? (
        <EmptyContact displayText={chatObjContent.emptyMessage} />
      ) : (
        <>
          <AddingGroupButton onComplete={onCloseDrawer} />
          <Typography variant="subtitle2" className={clsx(classes.subTitle, classes.rootSpace)}>
            {chatObjContent.people}
          </Typography>
          <List>
            {viewingList.map((item, index) => (
              <Box key={`${item.avatarId}-${index}`}>
                {index > 0 && <Divider variant="inset" component="li" />}
                <AvatarInfo
                  avatar={{
                    avatarId: item.avatarId,
                    name: item.contactName || item.name,
                  }}
                  title={item.contactName || item.name || item.phone}
                  onClick={onCreateSelect(item)}
                  button={true}
                  sendToMe={item.id === currentAccountId}
                />
              </Box>
            ))}
          </List>
        </>
      )}
    </TabPanel>
  );
};

AddingMessageTab.propTypes = {
  onCloseDrawer: PropTypes.func,
};

export default memo(AddingMessageTab);

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  subTitle: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
  },
}));
