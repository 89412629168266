import { memo } from "react";
import { AppDrawer } from "components";
import { getNSLang } from "utils/lang.utils";
import { LangConstant } from "const";
import { withDrawerLayout } from "components/hoc";
import { SettingLogoIcon } from "components/icons";
import { FormatSize, NavigateNext } from "@mui/icons-material";
import SettingFontSize from "./SettingFontSize";
import AccountItem from "../../AccountItem";
import SettingLogo from "./SettingLogo";

const SettingLogoDrawer = withDrawerLayout(
  SettingLogo,
  <AccountItem
    text={getNSLang(LangConstant.NS_HOME, "OBJ_SETTING_APP.appIcon", {
      returnObjects: true,
    })}
    startIcon={<SettingLogoIcon />}
    endIcon={<NavigateNext />}
  />,
);

const SettingFontSizeDrawer = withDrawerLayout(
  SettingFontSize,
  <AccountItem
    text={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_FONT_SIZE")}
    startIcon={<FormatSize />}
    endIcon={<NavigateNext />}
  />,
);

const SettingUI = ({ open, onClose }) => {
  return (
    <AppDrawer open={open} onClose={onClose} title={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_UI")}>
      <SettingLogoDrawer />
      <SettingFontSizeDrawer />
    </AppDrawer>
  );
};

export default memo(SettingUI);
