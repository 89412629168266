import React, { memo } from "react";
import { LangConstant } from "const";
import { AppDrawer } from "components";
import { getNSLang } from "utils/lang.utils";

const SettingPrivateAndSecurity = ({ open, onClose }) => {
  return (
    <AppDrawer open={open} onClose={onClose} title={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_PRIVATE")}></AppDrawer>
  );
};

SettingPrivateAndSecurity.propTypes = {};

export default memo(SettingPrivateAndSecurity);
