import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant } from "const";
import { InfiniteScroll } from "components";
import { ConversationActions, ConversationSelectors } from "redux-store";
import { CHAT_WRAPPER_ID } from "../ViewMode.helper";
import MessageItem from "../MessageItem";
import { createSelector } from "reselect";
import useQueryMessage from "../useQueryMessage";
import { StorageUtil } from "utils";

const memoizedReduxState = createSelector(
  [ConversationSelectors.getSelectedGroupId, ConversationSelectors.getSearchingMessage],
  (selectedGroupId, searchingMessage) => {
    return {
      selectedGroupId: searchingMessage.selectedMsgSourceId ? selectedGroupId : null,
      selectedMsgSourceId: searchingMessage.selectedMsgSourceId,
      isNotFound: searchingMessage.messageSourceIdList?.length === 0,
    };
  },
);

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getSelectedGroupId, state => state.conversationRedux.incomingMessages],

  (selectedGroupId, incomingMessages) => {
    const messageInGroup = incomingMessages[selectedGroupId];
    const messageList = messageInGroup ? Object.values(messageInGroup) : [];
    const newestMessage = messageList[messageList.length - 1];
    const isNewLocalMessage =
      newestMessage && newestMessage.senderDeviceId === StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID) ? true : false;

    return isNewLocalMessage ? newestMessage : null;
  },
);

const SearchMode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  const { selectedGroupId, selectedMsgSourceId, isNotFound } = useSelector(memoizedReduxState);
  const incomingLocalMessage = useSelector(memoizedIncomingState);
  const { showMessage, isLoadMore, getNewerMessage, getOlderMessage } = useQueryMessage({
    selectedGroupId,
    scrollToChildId: selectedMsgSourceId,
  });

  const handleCloseSearching = () => {
    dispatch(
      ConversationActions.conversationSet({
        isSearchMode: false,
        searchingMessage: {
          selectedMsgSourceId: null,
          messageSourceIdList: [],
          searchValue: "",
        },
      }),
    );
  };

  useEffect(() => {
    if (incomingLocalMessage) {
      handleCloseSearching();
    }
  }, [incomingLocalMessage]);

  return isNotFound ? (
    <Typography className={isNotFound ? classes.notFoundText : "hidden"}>
      {getLabel(LangConstant.TXT_NOT_FOUND)}
    </Typography>
  ) : (
    <InfiniteScroll
      id={CHAT_WRAPPER_ID}
      className={classes.wrapChatItem}
      hasMore={isLoadMore}
      isReverse={true}
      onScrollToTop={getNewerMessage}
      onScrollToBottom={getOlderMessage}
      onClickScrollButton={handleCloseSearching}
    >
      {showMessage.map(messageItem => (
        <MessageItem key={messageItem.id} message={messageItem} isSearchMode={true} />
      ))}
    </InfiniteScroll>
  );
};

SearchMode.propTypes = {};

SearchMode.defaultProps = {};

export default memo(SearchMode);

const useStyles = makeStyles(theme => ({
  wrapChatItem: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    padding: "0 22px",
    paddingBottom: 15,
    paddingTop: 70,
  },

  notFoundText: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[200],
  },
}));
