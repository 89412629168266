import { Box, Dialog, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";

const SuccessDialog = ({ open, onClose, title, content, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} onClose={onClose} {...otherProps}>
      <Box className={classes.iconBox}>
        <CheckCircleIcon sx={{ fontSize: "3.125rem" }} color="success" />
      </Box>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.content}>{content}</Typography>
    </Dialog>
  );
};

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessDialog;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: 300,
    maxWidth: 300,
    padding: 20,
  },
  iconBox: {
    borderRadius: "50%",
    width: 64,
    height: 64,
    backgroundColor: "#E4FFF1",
    margin: "auto",
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    textAlign: "center",
    fontWeight: "bold",
  },

  content: {
    textAlign: "center",
    fontSize: "0.75rem",
  },
}));
