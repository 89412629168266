import React, { memo, useEffect, useState } from "react";
import { CardMedia, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { getExternalLinkFromString } from "utils";
import { usePreviewLink } from "hooks";
import ChatTypography from "../MessengerChat/ChatItem/ChatTypography";

const DataLink = ({ data }) => {
  const [listDataLink, setListDataLink] = useState([]); // {link, senderName, isDivider, messageId}

  useEffect(() => {
    if (data) {
      const urlArray = getExternalLinkFromString(data.content).map(item => ({
        link: item,
        senderName: data.senderName,
        messageId: data.id,
      }));

      setListDataLink(urlArray);
    }
  }, [data]);

  return (
    <Stack spacing={2}>
      {listDataLink.map((linkData, index) => (
        <LinkItem key={index} previewUrl={linkData.link} messageId={linkData.messageId} />
      ))}
    </Stack>
  );
};

export default DataLink;

const LinkItem = memo(({ previewUrl, messageId }) => {
  const { linkMetaData } = usePreviewLink({ previewUrl, messageId });
  const classes = useStyles();

  return (
    <Box className={classes.userAvatarAndName}>
      <CardMedia component="img" className={classes.cover} src={linkMetaData.image} />
      <Box className={clsx(classes.customerDetailBox)}>
        <Box className={classes.boxBodyUserInfo}>
          <ChatTypography
            messageId={messageId}
            messageContent={previewUrl}
            className={clsx("ellipsis", classes.linkData)}
          />
        </Box>
        <Box className={classes.boxRating}>
          <Typography className={clsx("ellipsis", classes.customerDetail)}>{linkMetaData.title}</Typography>
        </Box>
        <Typography className={clsx("ellipsis", classes.typographyReview)}>{linkMetaData.senderName}</Typography>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  cover: {
    width: 76,
    height: 76,
    borderRadius: 4,
    objectFit: "cover",
  },

  userAvatarAndName: {
    width: "100%",
    display: "flex",
    paddingTop: 12,
    margin: 0,
  },

  customerDetailBox: {
    width: "100%",
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  borderBottom: {
    borderBottom: "0.5px solid #E6E8EB",
  },

  customerDetail: {
    lineHeight: "1.125rem",
    height: "fit-content",
    paddingTop: 3,
    color: theme.palette.black,
    fontWeight: 400,
    fontSize: "0.75rem",
  },

  typographyReview: {
    lineHeight: "0.875rem",
    maxWidth: "fit-content",
    width: "100%",
    fontWeight: 600,
    fontSize: "0.625rem",
    color: theme.palette.grey[500],
  },

  linkData: {
    textDecoration: "none",
    fontSize: "0.9375rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
