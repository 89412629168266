import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { LangConstant, SystemConstant } from "const";
import { withDrawerLayout } from "components/hoc";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AddingConferenceIcon } from "components/icons";
import { InfiniteScroll, SearchBar } from "components";
import { getNSLang } from "utils/lang.utils";
import clsx from "clsx";
import AddingChannelDrawer from "pages/HomePage/components/AddingChat/AddingChannel/AddingChannelDrawer";
import ConferenceItem from "pages/HomePage/components/AddingChat/ConferenceItem";

const AddingChannelWithIcon = withDrawerLayout(
  AddingChannelDrawer,
  <IconButton>
    <AddingConferenceIcon />
  </IconButton>,
);

const ConferenceTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [groupList, setGroupList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleStartConference = () => {
    dispatch(
      ConversationActions.conversationSet({
        isAddConference: true,
      }),
    );
  };

  const handleSearch = value => {
    setSearchValue(value);
    getInteractor()
      .LocalGroupService.searchGroupByCondition({
        keyword: value,
        group_type: [SystemConstant.GROUP_CHAT_TYPE.channel, SystemConstant.GROUP_CHAT_TYPE.group],
      })
      .then(conversationList => {
        setGroupList(conversationList);
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={handleSearch} value={searchValue} />
      </Box>

      <Stack direction="row" justifyContent="space-between" alignItems="center" p="0px 16px">
        <Typography variant="subtitle2" className={classes.subTitle}>
          {getNSLang(LangConstant.NS_HOME, "FM_GROUP_TAG", { number: groupList.length })}
        </Typography>
        <AddingChannelWithIcon onComplete={handleStartConference} isConferenceFlow={true} />
      </Stack>

      <InfiniteScroll className={clsx(classes.rootSpace, classes.scrollBox)}>
        {groupList.map(item => (
          <ConferenceItem key={item.id} data={item} />
        ))}
      </InfiniteScroll>
    </>
  );
};

export default ConferenceTab;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  subTitle: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    textAlign: "left",
    marginTop: 10,
  },

  scrollBox: {
    maxHeight: "calc(100vh - 347px)",
    overflowY: "auto",
  },
}));
