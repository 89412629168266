import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import { PhoneCallback, MissedVideoCall } from "@mui/icons-material";
import CallingAction from "redux-store/calling.redux";
import { AppConstant, FormatConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { MissedCallIcon } from "components/icons";
import { convertJSONObject, convertString2JSON, toCamel, uuid } from "utils";
import { convertMillisecondsToDate, convertMillisecondsToHMS } from "utils/date.utils";
import InitGroupCallPopup from "./InitGroupCallPopup";
import { isGroupOrChannelType } from "pages/Call";
import LineChat from "./LineChat";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { useConversationContext } from "./ConversationContext";
import { AvatarGroup } from "components";
import { useCleanUpEffect } from "hooks";

const PersonalCallHistory = ({ data }) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { groupDetail } = useConversationContext();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const { isMounted } = useCleanUpEffect();
  const callLengthObj = getLabel(LangConstant.OBJ_CALL_LENGTH, { returnObjects: true });

  const isFetchHistoryTimestamp = useSelector(state => state.callingRedux.isFetchHistoryTimestamp);
  const isNotifyEnded = useSelector(state => state.callingRedux.isNotifyEnded);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);

  const isGroup = data && isGroupOrChannelType(groupDetail.groupType);
  const isMissedCall =
    data.callStatus === SystemConstant.MESSAGE_CALL_STATUS.missed ||
    data.callStatus === SystemConstant.MESSAGE_CALL_STATUS.reject;
  const isVideoCall =
    data.sendType === SystemConstant.SEND_TYPE.personalVideoCall ||
    data.sendType === SystemConstant.SEND_TYPE.groupVideoCall;

  const [account, setAccount] = useState({ name: groupDetail.groupName || "No Name", avatarId: null });
  const [callLengthText, setCallLengthText] = useState("");
  const [callingDetail, setCallingDetail] = useState(null);
  const [historyText, setHistoryText] = useState("");
  const [isFinishGettingData, setIsFinishGettingData] = useState(false);
  const [parentMsg, setParentMsg] = useState(null);
  const [isOpenInitGroupCall, setIsOpenInitGroupCall] = useState(false);
  const [recallDetails, setRecallDetails] = useState(null);

  const handleCallBack = () => {
    if (isGroup) {
      setIsOpenInitGroupCall(true);
    } else {
      dispatch(
        CallingAction.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
          callingGroupDetail: toCamel({ ...groupDetail, room_id: uuid() }),
          isVideoCall: isVideoCall,
        }),
      );

      dispatch(CallingAction.onCallCheck({ accountId: groupDetail.groupMembers[0].id }));
    }
  };

  const handleCloseInitGroupCall = () => setIsOpenInitGroupCall(false);

  const handleUpdateContent = newCallingDetail => {
    if (newCallingDetail) {
      setIsFinishGettingData(true);

      // Update calling length text
      const [callingLengthFormat, callingLengthData] = getCallingLengthFormat(
        newCallingDetail.callLength,
        isMissedCall,
        callLengthObj,
      );
      setCallLengthText(getLabel(callingLengthFormat, callingLengthData));

      // Update calling history text
      const callHistoryText = getLabel(
        !newCallingDetail.parentId
          ? LangConstant.FM_STARTED_CALL
          : newCallingDetail.callStatus === SystemConstant.MESSAGE_CALL_STATUS.accept
          ? LangConstant.FM_JOINED_CALL
          : LangConstant.FM_LEAVE_CALL,
        {
          user:
            newCallingDetail.senderId === accountId ? getLabel(LangConstant.TXT_YOU) : newCallingDetail.sender?.name,
        },
      );

      setHistoryText(callHistoryText);
    }
  };

  const handleInitState = async () => {
    let currentMessage = { ...data };
    const isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    if (false === isPersonal && data.callStatus === SystemConstant.MESSAGE_CALL_STATUS.reject) return; // skip reject message in group call

    const callHistory = toCamel(
      await getInteractor().LocalCallHistoryService.getByRoomId(toCamel(convertJSONObject(data.content)).roomId),
    );

    let sender = {};
    if (data.senderId !== accountId) {
      sender = groupDetail.groupMembers.find(item => item.id === data.senderId);
      if (sender && isMounted()) setAccount(sender);
    }

    if (Boolean(data.parentId)) {
      const parentMessage = toCamel(
        (await getInteractor().LocalMessageService.findOne({
          source_id: data.parentId,
        })) || {},
      );
      if (parentMessage && Object.keys(parentMessage).length > 0 && isMounted()) {
        setParentMsg(parentMessage);
        if (isPersonal && parentMessage.senderId !== accountId) {
          sender = groupDetail.groupMembers.find(item => item.id === parentMessage.senderId);
          if (sender) setAccount(sender);
        }
        let records = toCamel(await getInteractor().LocalMessageService.getMessageWithParentId(parentMessage.sourceId));

        const isEndGroupCall =
          !isPersonal &&
          Array.isArray(records) &&
          callHistory.status === SystemConstant.CALL_HISTORY_STATUS.end &&
          !Boolean(
            records.find(item => item.created > data.created && item.sendType !== SystemConstant.SEND_TYPE.reconnect),
          );
        const isEndPersonal = isPersonal && data.callStatus === SystemConstant.MESSAGE_CALL_STATUS.end;

        if (isEndGroupCall || isEndPersonal) {
          let callLength = 0;

          if (isPersonal) {
            if (records && Array.isArray(records)) {
              const callingList = await getInteractor().LocalCallHistoryService.find(
                {
                  room_id: callHistory.roomId,
                },
                "ORDER BY created ASC",
              );

              // Get callingHistory when there are at less 2 people joined the calling
              let startCallingTime = 0;
              const callingHistory = callingList.find(item => {
                const joinedMembers = convertString2JSON(item.joined_members, []);
                if (joinedMembers.length >= 2) return true;
                return false;
              });
              if (callingHistory) {
                startCallingTime = callingHistory.created;
              }

              if (startCallingTime > 0) {
                callLength = Math.abs(data.created - startCallingTime);
              } else {
                callLength = 0;
              }
            }
          } else {
            callLength = Math.abs(data.created - parentMessage.created);
          }

          currentMessage = {
            ...data,
            creator: parentMessage.senderId,
            callLength: callLength,
            isEnd: true,
          };
        }
      }
    }

    if (isMounted()) {
      const newCallingDetail = {
        ...currentMessage,
        sender: sender,
        creator: currentMessage.creator || sender?.id,
      };
      setCallingDetail(newCallingDetail);
      handleUpdateContent(newCallingDetail);
    }
  };

  useEffect(() => {
    if (data && data.id) {
      handleInitState();
    }
  }, [data, isFetchHistoryTimestamp, isNotifyEnded]);

  useEffect(() => {
    if (data && data.id) {
      getInteractor()
        .LocalCallHistoryService.getByRoomId(toCamel(convertJSONObject(data.content)).roomId)
        .then(recordCalling => {
          const reCall = toCamel(recordCalling);
          const reCallOptions = reCall.options ? toCamel(JSON.parse(reCall.options)) : {};

          if (isMounted()) {
            setRecallDetails({
              ...reCall,
              id: reCall.groupId,
              avatarId: reCallOptions.avatarUrl,
              groupName: reCallOptions.callName,
              groupMembers: groupDetail.groupMembers,
            });
          }
        });
    }
  }, [data]);

  const isMine = parentMsg ? parentMsg.senderId === accountId : callingDetail?.senderId === accountId;
  const classesObj = {
    [classes.receivedRoot]: !isMine && !isGroup,
    [classes.groupCallHistory]: isGroup,
    hidden: !isFinishGettingData,
  };
  const createdTime = isMessageSendToday(data.created)
    ? convertMillisecondsToDate(data.created, FormatConstant.FM_HH_MM)
    : convertMillisecondsToDate(data.created, FormatConstant.FM_HH_MM_DD_MM_YYYY);

  return (
    <Box className={clsx(classes.root, classesObj)}>
      {!isMine && !isGroup && <AvatarGroup avatarId={account?.avatarId} className={classes.avatar} />}
      {callingDetail && (
        <Box className={clsx(classes.endBox, isGroup && classes.endBoxFullSize)}>
          {((DISPLAY_CALL_STATUS_ARR.includes(callingDetail.callStatus) && !isGroup) || callingDetail?.isEnd) && (
            <Box className={clsx(classes.wrapper, isGroup && classes.selfCenter)}>
              <Box className={classes.historyWrapper}>
                <HistoryIcon isMine={isMine} isMissedCall={isMissedCall} isVideoCall={isVideoCall} />
                <Box className={classes.historyDetail}>
                  <HistoryTypography
                    isMine={isMine}
                    isMissedCall={isMissedCall}
                    isVideoCall={isVideoCall}
                    account={
                      isMine
                        ? {
                            name: groupDetail.groupName || "No Name",
                            avatarId: null,
                          }
                        : account
                    }
                    isGroup={isGroup}
                  />

                  <Box className={classes.timeWrapper}>
                    {!isMissedCall && <Typography className={classes.secondText}>{callLengthText}</Typography>}
                    <Typography className={classes.secondText}>{createdTime}</Typography>
                  </Box>
                </Box>
              </Box>
              <Button
                className={classes.button}
                variant="contained"
                disabled={isOpenCallingDialog !== AppConstant.CALLING_STATUS.notStart || false === hasInternet}
                fullWidth
                onClick={handleCallBack}
              >
                {getLabel(LangConstant.TXT_CALL_AGAIN)}
              </Button>
            </Box>
          )}

          {isGroup && <LineChat data={historyText} />}
        </Box>
      )}

      {isOpenInitGroupCall && (
        <InitGroupCallPopup open={isOpenInitGroupCall} onClose={handleCloseInitGroupCall} data={recallDetails} />
      )}
    </Box>
  );
};

PersonalCallHistory.propTypes = {
  data: PropTypes.shape({
    options: PropTypes.string,
    senderId: PropTypes.string.isRequired,

    sendType: PropTypes.number.isRequired,
    callStatus: PropTypes.oneOf(Object.values(SystemConstant.MESSAGE_CALL_STATUS)),
    created: PropTypes.number.isRequired,

    callLength: PropTypes.number,
  }),
};

const HistoryIcon = ({ isMine, isMissedCall, isVideoCall }) => {
  const classes = useStyles();

  if (isMissedCall) {
    if (isVideoCall) {
      return (
        <Box
          className={clsx(classes.iconWrapper, {
            [classes.receivedIconWrapper]: !isMine,
          })}
        >
          <MissedVideoCall />
        </Box>
      );
    } else {
      return (
        <Box
          className={clsx(classes.iconWrapper, {
            [classes.receivedIconWrapper]: !isMine,
          })}
        >
          <MissedCallIcon sx={{ p: 0.5 }} />
        </Box>
      );
    }
  } else {
    return (
      <Box className={classes.iconWrapper}>
        <PhoneCallback />
      </Box>
    );
  }
};

const HistoryTypography = ({ isMine, isMissedCall, isVideoCall, account, isGroup }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const you = {
    capitalCase: getLabel(LangConstant.TXT_YOU),
    lowerCase: getLabel(LangConstant.TXT_YOU).toLowerCase(),
  };

  if (isGroup) {
    return (
      <Typography className={clsx(classes.primaryText, "ellipsis semiBold-sm-txt")}>
        {isVideoCall ? getLabel(LangConstant.TXT_VIDEO_CALL_ENDED) : getLabel(LangConstant.TXT_CALL_ENDED)}
      </Typography>
    );
  } else {
    if (isMissedCall) {
      if (isVideoCall) {
        return (
          <Typography className={clsx(classes.primaryText, "ellipsis semiBold-sm-txt")}>
            {isMine
              ? getLabel(LangConstant.FM_MISSED_VIDEO_CALL, { first: account.name, second: you.lowerCase })
              : getLabel(LangConstant.FM_MISSED_VIDEO_CALL, { first: you.capitalCase, second: account.name })}
          </Typography>
        );
      } else {
        return (
          <Typography className={clsx(classes.primaryText, "ellipsis semiBold-sm-txt")}>
            {isMine
              ? getLabel(LangConstant.FM_MISSED_CALL, { first: account.name, second: you.lowerCase })
              : getLabel(LangConstant.FM_MISSED_CALL, { first: you.capitalCase, second: account.name })}
          </Typography>
        );
      }
    } else {
      if (isVideoCall) {
        return (
          <Typography className={clsx(classes.primaryText, "ellipsis semiBold-sm-txt")}>
            {getLabel(
              LangConstant.FM_RECENT_VIDEO_CALL,
              isMine
                ? { first: you.capitalCase, second: account.name }
                : { first: account.name, second: you.lowerCase },
            )}
          </Typography>
        );
      } else {
        return (
          <Typography className={clsx(classes.primaryText, "ellipsis semiBold-sm-txt")}>
            {getLabel(
              LangConstant.FM_RECENT_CALL,
              isMine
                ? { first: you.capitalCase, second: account.name }
                : { first: account.name, second: you.lowerCase },
            )}
          </Typography>
        );
      }
    }
  }
};

export default memo(PersonalCallHistory);

const DISPLAY_CALL_STATUS_ARR = [
  SystemConstant.MESSAGE_CALL_STATUS.end,
  SystemConstant.MESSAGE_CALL_STATUS.missed,
  SystemConstant.MESSAGE_CALL_STATUS.reject,
];

const isMessageSendToday = milliseconds => {
  let today = new Date().setUTCHours(0, 0, 0, 0);

  return milliseconds >= today;
};

const getCallingLengthFormat = (callLength, isMissedCall, callLengthObj) => {
  let callingLengthFormat = "";
  let callingLengthData = callLength ? convertMillisecondsToHMS(callLength) : {};
  let { hours, minutes, seconds } = callingLengthData;

  if (callLength && !isMissedCall) {
    if (hours > 0) {
      if (minutes > 0) {
        if (seconds > 0) {
          callingLengthFormat = callLengthObj.FM_HH_MM_SS;
        } else {
          callingLengthFormat = callLengthObj.FM_HH_MM;
        }
      } else {
        callingLengthFormat = callLengthObj.FM_HH;
      }
    } else {
      if (minutes > 0) {
        if (seconds > 0) {
          callingLengthFormat = callLengthObj.FM_MM_SS;
        } else {
          callingLengthFormat = callLengthObj.FM_MM;
        }
      } else {
        callingLengthFormat = callLengthObj.FM_SS;
      }
    }
  } else if (!isMissedCall) {
    callingLengthFormat = callLengthObj.FM_SS;
    callingLengthData.seconds = "--";
  }

  return [callingLengthFormat, callingLengthData];
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  receivedRoot: {
    justifyContent: "flex-start",
  },

  groupCallHistory: {
    justifyContent: "center",
  },

  wrapper: {
    padding: 12,
    minWidth: 246,
    backgroundColor: "#F1F3F6",
    borderRadius: 20,
    width: "fit-content",
    marginTop: 10,
  },

  selfCenter: {
    alignSelf: "center",
  },

  historyWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },

  primaryText: {
    lineHeight: "1rem",
    color: theme.palette.black,
    userSelect: "none",
    width: 200,
  },

  secondText: {
    color: theme.palette.grey[200],
    fontSize: "0.75rem",
    fontWeight: 400,
    marginRight: 2,
    userSelect: "none",
  },

  normalCallIcon: {
    width: 16,
    height: 16,
  },

  iconWrapper: {
    backgroundColor: "#C4C4C4",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 8,
    color: theme.palette.white,
  },

  receivedIconWrapper: {
    backgroundColor: "#EB544B",
  },

  button: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    fontSize: "0.9375rem",
    fontWeight: 600,
    userSelect: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      boxShadow: "none",
    },
  },

  timeWrapper: {
    display: "flex",
  },

  avatar: {
    width: 40,
    height: 40,
    marginRight: 12,
  },

  endBox: {
    display: "flex",
    flexDirection: "column-reverse",
  },

  endBoxFullSize: {
    width: "100%",
  },
}));
