import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { call, put } from "redux-saga/effects";
import { removeDuplicateInArray, toSnake, uuid } from "utils";
import { getInteractor } from "services/local.service";
import store, { ConversationActions, SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { getSavedServer } from "utils/branch.utils";
import { synchPinList } from "pubsub/services/conversation.service";
import { checkCurrentBranchByPrefix, formatPagingParams } from "./saga.helper";

export function* uploadMessageFile(action) {
  let errorReason;

  try {
    const { upload, sendType } = action.data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let response = yield call(yield remoteApiFactory.getBranchApi(prefixKey).sendMessageFile, upload);
    if (response && response.status === ApiConstant.STT_OK && response?.data?.id) {
      yield put(
        ConversationActions.conversationSuccess({
          uploadingAttachment: {
            processStatus: AppConstant.PROCESS_STATUS.success,
            attachmentId: response.data.id,
            sendType: sendType,
          },
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    errorReason = error;
  }

  if (errorReason)
    yield put(
      ConversationActions.conversationFailure(errorReason, {
        uploadingAttachment: {
          processStatus: AppConstant.PROCESS_STATUS.error,
        },
      }),
    );
}

export const updateMessageStatus = async action => {
  try {
    const { data } = action;
    const { isReadAll, isReadAllThread, ...payload } = data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    // Send request to server with chunk size = 20
    const messageIds = data.messageIds || [];
    const notifyIds = data.notifyIds || [];
    const updateIds = [...messageIds, ...notifyIds];
    const updateField = messageIds.length > 0 ? "messageIds" : "notifyIds";
    const isUpdateMessage = messageIds.length > 0;

    let isUpdateSuccess = true;
    if (updateIds.length > 0) {
      let offset = 0;
      while (offset < updateIds.length) {
        // Filter processing message id
        const processStatusIds = getMessageIdByStatus(prefixKey, payload.status);
        const updateItems =
          processStatusIds.length === 0
            ? updateIds.slice(offset, offset + SystemConstant.REQUEST_CHUNK_SIZE)
            : updateIds
                .slice(offset, offset + SystemConstant.REQUEST_CHUNK_SIZE)
                .filter(id => !processStatusIds.includes(id));
        // Saving processing message ids
        storeMessageIdByStatus(prefixKey, payload.status, updateItems);

        if (updateItems && updateItems.length > 0) {
          const data2Req = { ...payload };
          data2Req[updateField] = updateItems;
          const response = await remoteApiFactory.getBranchApi(prefixKey).updateMessageStatus(toSnake(data2Req));

          if (response && response.status === ApiConstant.STT_OK && isUpdateMessage) {
            await getInteractor(prefixKey).LocalMessageService.update(
              { status: data.status, modified: Date.now() },
              { id: updateItems },
            );
            await getInteractor(prefixKey).LocalGroupService.updateUnread(updateItems);
            await getInteractor(prefixKey).LocalThreadService.updateUnread(updateItems);
            store.dispatch(
              ConversationActions.conversationSet({
                updateMessageStatus: {
                  updateMessageIds: updateItems,
                  modified: Date.now(),
                },
              }),
            );
          } else if (response.status !== ApiConstant.STT_OK && isUpdateMessage) {
            isUpdateSuccess = false;
          }
        }
        offset += SystemConstant.REQUEST_CHUNK_SIZE;
      }
    }

    if (isUpdateMessage) {
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      const savingSeenMembers = data.messageIds.map(sourceId => ({
        source_id: sourceId,
        member_account_id: accountId,
        seen_at: Date.now(),
      }));

      await getInteractor(prefixKey).LocalSeenMemberService.save(savingSeenMembers);
    }

    if (isReadAll && isUpdateSuccess) {
      await getInteractor(prefixKey).LocalGroupService.update({ unread: 0 });
    }

    if (isReadAllThread && isUpdateSuccess) {
      await getInteractor(prefixKey).LocalThreadService.update({ total_unread: 0 });
    }

    if (isReadAll || isReadAllThread) {
      store.dispatch(
        SystemActions.systemSet({
          toast: {
            content: isReadAllThread
              ? getNSLang(
                  LangConstant.NS_HOME_CONVERSATION,
                  isUpdateSuccess ? "TXT_READ_ALL_THREAD" : "TXT_READ_ALL_THREAD_FAIL",
                )
              : getNSLang(LangConstant.NS_HOME, isUpdateSuccess ? "TXT_READ_ALL_MSG_SUCCESS" : "TXT_READ_ALL_MSG_FAIL"),
            severity: isUpdateSuccess ? "success" : "error",
            modified: Date.now(),
          },
        }),
      );
    }

    const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
    if (isCurrentBranch) {
      store.dispatch(
        ConversationActions.conversationSuccess({
          isUpdateViewMode: Date.now(),
        }),
      );
    }

    // Remove ids that is updated
    if (updateIds.length > 0) removeMessageIdByStatus(prefixKey, payload.status, updateIds);
  } catch (error) {
    console.log(error);
  }
};

export function* markReadMessageInGroup(action) {
  const { selectedGroupId, threadingId } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  try {
    let unreadMessageList = [];

    if (threadingId) {
      unreadMessageList = yield getInteractor(prefixKey).LocalMessageService.getUnreadInThread(threadingId);
    } else if (selectedGroupId) {
      unreadMessageList = yield getInteractor(prefixKey).LocalMessageService.getUnreadMessageInGroup({
        group_id: selectedGroupId,
        account_id: accountId,
        branch_id: branchId,
      });
    }

    if (Array.isArray(unreadMessageList) && unreadMessageList.length > 0) {
      const unreadMsgIds = unreadMessageList.map(item => item.id);
      yield updateMessageStatus({
        data: {
          messageIds: unreadMsgIds,
          status: SystemConstant.MESSAGE_STATUS.read,
        },
      });
    } else if (unreadMessageList && unreadMessageList.length === 0) {
      if (threadingId) {
        const thread = yield getInteractor(prefixKey).LocalThreadService.findOne({ thread_id: threadingId });
        if (thread && thread.total_unread > 0) {
          yield getInteractor(prefixKey).LocalThreadService.update({ total_unread: 0 }, { thread_id: threadingId });

          yield put(
            ConversationActions.conversationSuccess({
              isUpdateViewMode: Date.now(),
            }),
          );
        }
      } else if (selectedGroupId) {
        const group = yield getInteractor(prefixKey).LocalGroupService.findOne({ id: selectedGroupId });
        if (group && group.unread > 0) {
          yield getInteractor(prefixKey).LocalGroupService.update({ unread: 0 }, { id: selectedGroupId });

          yield put(
            ConversationActions.conversationSuccess({
              isUpdateViewMode: Date.now(),
            }),
          );
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getMessageDetail(action) {
  try {
    const { sourceId } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const payload = formatPagingParams({
      sourceId,
      paging: 0,
    });

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getMessageDetail, toSnake(payload));
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data.data;
      const seenMembers = responseData.seen_members;
      const receivedMembers = responseData.received_members;

      let isNeedUpdateUI = false;
      if (Array.isArray(seenMembers) && seenMembers.length > 0) {
        isNeedUpdateUI = true;
        yield getInteractor(prefixKey).LocalSeenMemberService.save(seenMembers);
      }

      if (Array.isArray(receivedMembers) && receivedMembers.length > 0) {
        isNeedUpdateUI = true;
        yield getInteractor(prefixKey).LocalReceivedMemberService.save(receivedMembers);
      }

      if (isNeedUpdateUI) {
        yield put(
          ConversationActions.conversationSuccess({
            fetchSeenMember: Date.now(),
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* pinMessage(action) {
  try {
    const data = toSnake(action.data);
    const prefixKey = action.prefixKey || StorageUtil.getCurrentPrefixKey();
    const { group_id, ...payload } = data;
    const isPin = Boolean(data.state);

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).pinMessage, toSnake(payload));

    if (response.status === ApiConstant.STT_OK) {
      /**
       * 1. Save to local db
       * 2. Toast
       * 3. Send Pin message
       */
      const responseData = response.data;
      yield getInteractor(prefixKey).LocalGroupMessageService.save([responseData]);
      yield put(
        SystemActions.systemSet({
          toast: {
            content: getCommonLang(isPin ? "TXT_PIN_SUCCESS" : "TXT_UNPIN_SUCCESS"),
            modified: Date.now(),
          },
        }),
      );

      const pinMessageContent = {
        pin_source_message_id: data.source_id,
        pin_sender_id: StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey),
        pin_type: data.state,
      };

      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
      const saveMessage = {
        account_id: accountId,
        branch_id: getSavedServer().id,
        content: JSON.stringify(pinMessageContent),
        created: Date.now(),
        device_id: deviceId,
        group_id: group_id,
        id: uuid(),
        mentions: null,
        modified: 0,
        options: null,
        parent_id: null,
        send_type: SystemConstant.SEND_TYPE.pinMessage,
        sender_device_id: deviceId,
        sender_id: accountId,
        source_id: uuid(),
        state: 1,
        status: 1,
        thread_id: null,
        call_status: null,
      };
      yield put(
        ConversationActions.sendMessage(
          {
            groupId: group_id,
            sendType: SystemConstant.SEND_TYPE.pinMessage,
            content: JSON.stringify(pinMessageContent),
            parentId: null,
            branchId: getSavedServer().id,
            mentionIdsArr: [],
            threadId: null,
            currentMessage: saveMessage,
          },
          prefixKey,
        ),
      );
    } else {
      yield put(
        SystemActions.systemSet({
          toast: {
            content: getCommonLang(isPin ? "TXT_PIN_FAILED" : "TXT_UNPIN_FAILED"),
            severity: "error",
            modified: Date.now(),
          },
        }),
      );
    }
  } catch (error) {
    console.log("send pin message to server error: ", error);
  }
}

export function* synchPinListRemote(action) {
  const { groupId, prefixKey } = action;

  yield synchPinList(prefixKey, groupId);
}
const getMessageIdByStatus = (prefixKey, status) => {
  const processStatusIds = StorageUtil.getItem(KeyConstant.KEY_STATUS_MESSAGE_IDS, prefixKey) || {};
  return Array.isArray(processStatusIds[status]) ? processStatusIds[status] : [];
};

const storeMessageIdByStatus = (prefixKey, status, updateStatusIds) => {
  const processStatusIds = StorageUtil.getItem(KeyConstant.KEY_STATUS_MESSAGE_IDS, prefixKey) || {};
  processStatusIds[status] = removeDuplicateInArray((processStatusIds[status] || []).concat(updateStatusIds));
  StorageUtil.setItem(KeyConstant.KEY_STATUS_MESSAGE_IDS, processStatusIds, prefixKey);
};

const removeMessageIdByStatus = (prefixKey, status, removeStatusIds) => {
  const processStatusIds = StorageUtil.getItem(KeyConstant.KEY_STATUS_MESSAGE_IDS, prefixKey) || {};
  processStatusIds[status] = (processStatusIds[status] || []).filter(id => removeStatusIds.includes(id));
  StorageUtil.setItem(KeyConstant.KEY_STATUS_MESSAGE_IDS, processStatusIds, prefixKey);
};
