import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { removeDuplicateInArray } from "utils";
import { KeyConstant, LangConstant } from "const";
import { StorageUtil } from "utils";
import { getSavedServer } from "utils/branch.utils";

const KeywordsPopup = ({ onKeywordClick }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  const [searchHistoryList, setSearchHistoryList] = useState([]);

  const onClearHistoryItem = (e, index) => {
    const savedServer = getSavedServer();
    e.stopPropagation();
    let newState = searchHistoryList.filter((state, id) => id !== index);
    let localStorageHistory = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
    let newLocalStorageHistory = Object.assign(localStorageHistory, { [savedServer?.id]: newState });
    StorageUtil.setItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS, newLocalStorageHistory);
    setSearchHistoryList(newState);
  };

  const onClearAllHistory = () => {
    const savedServer = getSavedServer();
    let localStorageHistory = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
    let newLocalStorageHistory = Object.assign(localStorageHistory, { [savedServer?.id]: [] });
    StorageUtil.setItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS, newLocalStorageHistory);
    setSearchHistoryList([]);
  };

  const onClickKeywordItem = (item, index) => {
    const savedServer = getSavedServer();
    let newState = searchHistoryList.filter((state, id) => id !== index);
    newState = [item, ...newState];
    let localStorageHistory = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
    let newLocalStorageHistory = Object.assign(localStorageHistory, { [savedServer?.id]: newState });

    setSearchHistoryList(newState);
    StorageUtil.setItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS, newLocalStorageHistory);
    onKeywordClick(item);
  };

  useEffect(() => {
    const savedServer = getSavedServer();
    let localStorageHistory = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
    let localStorageHistoryInServer = localStorageHistory[savedServer?.id] || [];
    let removeDuplicateArr = removeDuplicateInArray(localStorageHistoryInServer);
    setSearchHistoryList(removeDuplicateArr);
    StorageUtil.setItem(
      KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
      Object.assign(localStorageHistory, { [savedServer?.id]: removeDuplicateArr }),
    );
  }, []);

  return (
    <Box className={classes.emptyTextFieldWrapper}>
      <Box className={classes.heading}>
        <Typography className={clsx(classes.title, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_KEYWORDS)}
        </Typography>
        <Typography
          className={clsx(classes.clearAll, "semiBold-sm-txt", {
            hidden: searchHistoryList.length === 0,
          })}
          onClick={onClearAllHistory}
        >
          {getLabel(LangConstant.TXT_CLEAR_ALL)}
        </Typography>
      </Box>
      <Box className={classes.scrollBox}>
        {searchHistoryList.length > 0 ? (
          searchHistoryList.map((searchHistoryItem, index) => (
            <Box
              key={index}
              className={classes.searchHistoryItem}
              onClick={() => onClickKeywordItem(searchHistoryItem, index)}
            >
              <Box className={classes.searchLeftHistoryItem}>
                <Search className={classes.searchIcon} />
                <Typography className={clsx(classes.searchHistoryName, "ellipsis")}>{searchHistoryItem}</Typography>
              </Box>
              <Box className={classes.searchRightHistoryItem}>
                <Close className={classes.closeIcon} onClick={e => onClearHistoryItem(e, index)} />
              </Box>
            </Box>
          ))
        ) : (
          <Typography className={classes.emptyKeywordText}>{getLabel(LangConstant.TXT_NO_SEARCH_KEYWORDS)}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default KeywordsPopup;

KeywordsPopup.propTypes = {
  onKeywordClick: PropTypes.func,
};

KeywordsPopup.defaultProps = {
  onKeywordClick: () => {},
};

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    height: 40,
  },

  title: {
    color: "#7F7F80",
    lineHeight: "1rem",
  },

  clearAll: {
    color: theme.palette.secondary.main,
    lineHeight: "1rem",
    textTransform: "uppercase",
    cursor: "pointer",
  },

  emptyKeywordText: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    color: theme.palette.black,
    textAlign: "center",
    paddingBottom: 24,
  },

  scrollBox: {
    maxHeight: 330,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  searchHistoryItem: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },

  searchLeftHistoryItem: {
    display: "flex",
    width: "100%",
  },

  searchIcon: {
    width: 17,
    height: 17,
    color: theme.palette.black,
    marginRight: 15,
    marginTop: 4,
  },

  searchHistoryName: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    color: theme.palette.black,
    paddingRight: 12,
    width: "100%",
    WebkitLineClamp: 2,
  },

  closeIcon: {
    width: 14,
    height: 14,
    color: theme.palette.black,
    marginTop: 4,
  },
}));
