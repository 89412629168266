import { KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { deepCloneJsonObject } from "utils";

const StorageUtil = window.electronUtils.storage;
const AttachmentUtil = window.electronUtils.attachment;

export const PREFIX_MENTION = window.electronUtils.message.PREFIX_MENTION;
export const SUFFIX_MENTION = window.electronUtils.message.SUFFIX_MENTION;
export const replaceId2Name = async (msgContent, groupMembers, isHighlight = true) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  if (!(msgContent && Array.isArray(groupMembers) && groupMembers.length > 0)) return msgContent || "";

  const memberList = deepCloneJsonObject(groupMembers);
  // Add mention all if not exist
  if (memberList.findIndex(member => member.id === SystemConstant.MENTION_ALL.id) < 0)
    memberList.push(SystemConstant.MENTION_ALL);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const tags = msgContent?.match(/@{\w+}/gm) || [];
  const myAccount = await getInteractor(prefixKey).LocalAccountService.get(accountId);
  if (myAccount) {
    memberList.push(myAccount);
  }
  let messageContent = msgContent;
  for (let index = 0; index < tags.length; index++) {
    const tag = tags[index];

    const tagAccountId = tag.replace(PREFIX_MENTION, "").replace(SUFFIX_MENTION, "");
    let mentionAccount = memberList.find(item => item.id === tagAccountId);
    if (!mentionAccount) {
      mentionAccount = await getInteractor(prefixKey).LocalAccountService.get(tagAccountId);
    }

    if (mentionAccount) {
      const tagAccountName = (mentionAccount?.name || "No name").trim();
      messageContent = messageContent.replace(tag, isHighlight ? `<b>@${tagAccountName}</b>` : `@${tagAccountName}`);
    }
  }

  return messageContent;
};

export const replaceName2Id = (text, memberArr) => window.electronUtils.message.replaceName2Id(text, memberArr);

export const getMessageFileList = message => window.electronUtils.message.getMessageFileList(message);

export const convertContentFileMsg = message => window.electronUtils.message.convertContentFileMsg(message);

export const getMetaDataFile = (messageSourceId, fileData) =>
  window.electronUtils.message.getMetaDataFile(messageSourceId, fileData);

export const getExistFileFromMessage = (prefixKey, message) => {
  try {
    const messageFileList = getMessageFileList(message);
    const existLocalFiles = messageFileList.filter(item => {
      const fileName = item.metaData.fileName;
      const attachmentId = item.attachmentId;

      const isValidData = Boolean(prefixKey && attachmentId && fileName);
      return isValidData && AttachmentUtil.exitsLocalFile(prefixKey, attachmentId, fileName);
    });

    return existLocalFiles;
  } catch (error) {
    console.log(error);
  }

  return [];
};

export const getPinContent = (message, isShowDetail) =>
  window.electronUtils.message.getPinContent(message, isShowDetail);
