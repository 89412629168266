import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { toCamel } from "utils";
import { InfiniteScroll } from "components";
import { LangConstant } from "const";
import LineChat from "../LineChat";
import { useTranslation } from "react-i18next";
import { getInteractor } from "services/local.service";
import { ConversationSelectors } from "redux-store";
import { CHAT_WRAPPER_ID, handleNewMessageList, isLocalMessage, refactorMessage } from "../ViewMode.helper";
import isEqual from "lodash/isEqual";
import MessageItem from "../MessageItem";
import { useCleanUpEffect } from "hooks";
import { useConversationContext } from "../ConversationContext";
import { createSelector } from "reselect";
import ScrollTooltip from "components/ScrollTooltip";
import useQueryMessage from "../useQueryMessage";

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    state => state.conversationRedux.scrollToChildId,
    state => state.conversationRedux.incomingMessages,
  ],
  (selectedGroupId, selectedThreadId, scrollToChildId, incomingMessages) => {
    return { selectedGroupId, selectedThreadId, scrollToChildId, incomingMessages: incomingMessages[selectedThreadId] };
  },
);

const ThreadViewMode = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { isMounted } = useCleanUpEffect();
  const { groupDetail } = useConversationContext();

  const { selectedGroupId, selectedThreadId, scrollToChildId, incomingMessages } = useSelector(memoizedReduxState);

  const {
    showMessage,
    isLoadMore,
    scrollDataRef,
    messageListRef,
    getNewerMessage,
    getOlderMessage,
    setMessageList,
    scrollToLastMessage,
  } = useQueryMessage({ selectedGroupId, selectedThreadId, scrollToChildId });

  const [parentMessage, setParentMessage] = useState({});

  const handleGetParentMessage = async () => {
    const parentMsg = await getInteractor().LocalMessageService.findOne({ source_id: selectedThreadId });
    if (!(selectedThreadId && parentMsg?.id)) return;

    // Set parent message
    let displayParentMsg = await refactorMessage(toCamel(parentMsg));
    displayParentMsg = { ...displayParentMsg, isAvatar: true };
    if (!isEqual(displayParentMsg, parentMessage) && isMounted()) setParentMessage(displayParentMsg);
  };

  const handleIncomingMessage = useCallback(async (incomingMessages, selectedGroupId) => {
    const isValid = incomingMessages.length > 0 && scrollDataRef.current.isNewest;
    if (isValid) {
      const newMessageList = await handleNewMessageList(incomingMessages, messageListRef.current, selectedGroupId);
      setMessageList(newMessageList);
    }

    // Scroll to new local message
    const newestMessage = incomingMessages[incomingMessages.length - 1];
    if (isLocalMessage(newestMessage)) {
      scrollToLastMessage();
    }
  }, []);

  useEffect(() => {
    const messageList = incomingMessages ? Object.values(incomingMessages) : null;
    if (Array.isArray(messageList) && messageList.length > 0) handleIncomingMessage(messageList, groupDetail.id);
  }, [incomingMessages]);

  useEffect(() => {
    if (selectedThreadId) {
      handleGetParentMessage();
    }
  }, [selectedThreadId]);

  const isShowParent = showMessage.length === 0 || scrollDataRef.current.isOldest;
  return (
    <InfiniteScroll
      id={CHAT_WRAPPER_ID}
      className={classes.wrapChatItem}
      hasMore={isLoadMore}
      isReverse={true}
      ScrollTooltip={ScrollTooltip}
      onScrollToTop={getNewerMessage}
      onScrollToBottom={getOlderMessage}
      onClickScrollButton={scrollToLastMessage}
    >
      {/* Reply message */}
      {showMessage.map(item => (
        <MessageItem key={item.id} message={item} isThreadMode={true} />
      ))}

      {isShowParent && (
        <>
          {showMessage.length > 0 && (
            <LineChat data={getLabel(LangConstant.FM_NUMBER_THREAD_REPLY, { number: showMessage.length })} />
          )}

          {/* Parent message */}
          <MessageItem message={parentMessage} isThreadMode={true} />
        </>
      )}
    </InfiniteScroll>
  );
};

ThreadViewMode.propTypes = {
  messages: PropTypes.array,
  groupMembers: PropTypes.array,
  groupDetail: PropTypes.object,

  onSendMessage: PropTypes.func,
};

ThreadViewMode.defaultProps = {};

export default memo(ThreadViewMode);

const useStyles = makeStyles({
  wrapChatItem: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    padding: "0 22px",
    paddingBottom: 15,
  },
});
