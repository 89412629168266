import React, { useState, memo } from "react";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { AppDrawer } from "components";
import { withDrawerLayout } from "components/hoc";
import { getNSLang } from "utils/lang.utils";
import { SettingUIIcon } from "components/icons";
import { Security } from "@mui/icons-material";
import AccountItem from "../AccountItem";
import SettingUI from "./SettingUI";
import SettingPrivateAndSecurity from "./SettingPrivateAndSecurity";

const SettingUIDrawer = withDrawerLayout(
  SettingUI,
  <AccountItem text={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_UI")} startIcon={<SettingUIIcon />} />,
);

const SettingPrivateDrawer = withDrawerLayout(
  SettingPrivateAndSecurity,
  <AccountItem text={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_PRIVATE")} startIcon={<Security />} />,
);

const SettingApp = () => {
  const { t: getHomeLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getHomeLabel(LangConstant.OBJ_ACCOUNT_ME, {
    returnObjects: true,
  });

  const [isShowSetting, setShowSetting] = useState(false);

  const handleShowSettingFunc = open => () => setShowSetting(open);

  return (
    <>
      <AccountItem text={accountObjContent.setting} onClick={handleShowSettingFunc(true)} />
      {isShowSetting && (
        <AppDrawer open={isShowSetting} onClose={handleShowSettingFunc(false)} title={accountObjContent.setting}>
          <SettingUIDrawer />
          {/* <SettingPrivateDrawer /> */}
        </AppDrawer>
      )}
    </>
  );
};

SettingApp.propTypes = {};

export default memo(SettingApp);
