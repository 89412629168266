import { memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { InfiniteScroll } from "components";
import { ConversationSelectors } from "redux-store";
import { useConversationContext } from "../ConversationContext";
import { CHAT_WRAPPER_ID, handleNewMessageList, isLocalMessage } from "../ViewMode.helper";
import MessageItem from "../MessageItem";
import { createSelector } from "reselect";
import ScrollTooltip from "components/ScrollTooltip";
import useQueryMessage from "../useQueryMessage";

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    state => state.conversationRedux.scrollToChildId,
    state => state.conversationRedux.incomingMessages,
  ],
  (selectedGroupId, scrollToChildId, incomingMessages) => {
    return {
      selectedGroupId,
      scrollToChildId,
      incomingMessages: incomingMessages[selectedGroupId],
    };
  },
);

const ViewMode = () => {
  const classes = useStyles();

  const { groupDetail: groupDetailContext } = useConversationContext();
  const { selectedGroupId, scrollToChildId, incomingMessages } = useSelector(memoizedReduxState);
  const {
    showMessage,
    isLoadMore,
    scrollDataRef,
    messageListRef,
    getNewerMessage,
    getOlderMessage,
    setMessageList,
    scrollToLastMessage,
  } = useQueryMessage({ selectedGroupId, scrollToChildId });

  const handleIncomingMessage = useCallback(async (incomingMessages, selectedGroupId) => {
    const isValid = incomingMessages.length > 0 && scrollDataRef.current.isNewest;
    if (isValid) {
      const newMessageList = await handleNewMessageList(incomingMessages, messageListRef.current, selectedGroupId);
      setMessageList(newMessageList);
    }

    // Scroll to new local message
    const newestMessage = incomingMessages[incomingMessages.length - 1];
    if (isLocalMessage(newestMessage)) {
      scrollToLastMessage();
    }
  }, []);

  useEffect(() => {
    const messageList = incomingMessages ? Object.values(incomingMessages) : null;
    if (Array.isArray(messageList) && messageList.length > 0) {
      handleIncomingMessage(messageList, groupDetailContext.id);
    }
  }, [incomingMessages]);

  return (
    <InfiniteScroll
      id={CHAT_WRAPPER_ID}
      className={classes.wrapChatItem}
      hasMore={isLoadMore}
      isReverse={true}
      ScrollTooltip={ScrollTooltip}
      onScrollToTop={getNewerMessage}
      onScrollToBottom={getOlderMessage}
      onClickScrollButton={scrollToLastMessage}
    >
      {showMessage.map(messageItem => (
        <MessageItem key={messageItem.id} message={messageItem} />
      ))}
    </InfiniteScroll>
  );
};

ViewMode.propTypes = {};
ViewMode.defaultProps = {};

export default memo(ViewMode);

const useStyles = makeStyles({
  wrapChatItem: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    padding: "0 22px",
    paddingBottom: 15,
  },
});
