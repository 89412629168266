import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Stack, Typography } from "@mui/material";
import CallingAction from "redux-store/calling.redux";
import { AppConstant, KeyConstant, LangConstant, SoundConstant, SystemConstant } from "const";
import { AvatarGroup } from "components";
import { CallingContext, isGroupOrChannelType } from "..";
import { convertJSONObject, convertString2JSON, toCamel, uuid } from "utils";
import { useCallInfo } from "hooks";

const WaitingScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const { onSendMessage, isVideoing, localStream, callingGroupDetail } = useContext(CallingContext);
  const { callInfo } = useCallInfo();

  const isRinging = useSelector(state => state.callingRedux.isRinging);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isCalleeInAnotherCall = useSelector(state => state.callingRedux.isCalleeInAnotherCall);
  const isPingToCallee = useSelector(state => state.callingRedux.isPingToCallee);
  const isCallRejected = useSelector(state => state.callingRedux.isCallRejected);
  const isCallEnded = useSelector(state => state.callingRedux.isCallEnded);
  const isRequestDeviceFail = useSelector(state => state.callingRedux.isRequestDeviceFail);
  const isNoInternetConnection = useSelector(state => state.callingRedux.isNoInternetConnection);
  const isCallMissed = useSelector(state => state.callingRedux.isCallMissed);
  const isDisconnected = useSelector(state => state.callingRedux.isDisconnected);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);
  const isSendMeetInfoSuccess = useSelector(state => state.callingRedux.isSendMeetInfoSuccess);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);
  const message = useSelector(state => state.callingRedux.message);

  const [textKey, setTextKey] = useState(LangConstant.TXT_CALLING);
  const [sound, setSound] = useState(SoundConstant.ConnectionWaitingSound);

  useEffect(() => {
    let closeDialogTimeout, callingResetTimeout;
    const callingId = callInfo?.id;
    const callingType = callInfo?.groupType;
    const callingMessage = createdMessage[callingId];
    const isAvailable = !isDisconnected && !isCalleeInAnotherCall && !isInAnotherCall;

    if (!callingId || !callingMessage || !isAvailable) return;

    // Ringing and waiting 60 seconds then hang up the call
    if (isRinging) {
      console.log("Receiver device is ringing");

      closeDialogTimeout = setTimeout(() => {
        if (isGroupOrChannelType(callingType)) {
          // Handle close connection for group call
        } else {
          console.log("Out of ringing time and send missed message to receiver");

          onSendMessage({
            content: JSON.stringify({
              room_id: convertJSONObject(callingMessage.content)?.room_id,
            }),
            parentId: callingMessage.parentId ? callingMessage.parentId : callingMessage.sourceId,
            callStatus: SystemConstant.MESSAGE_CALL_STATUS.missed,
            roomId: convertJSONObject(callingMessage.content)?.room_id,
          });

          dispatch(
            CallingAction.callingSet({
              isCallMissed: true,
            }),
          );
        }

        callingResetTimeout = setTimeout(() => {
          dispatch(CallingAction.callingReset());
        }, 3000);
      }, AppConstant.CALL_WAITING_TIME);

      setSound(SoundConstant.CallWaitingSound);
      setTextKey(LangConstant.TXT_RINGING);
    }

    return () => {
      if (closeDialogTimeout) clearTimeout(closeDialogTimeout);
      if (callingResetTimeout) clearTimeout(callingResetTimeout);
    };
  }, [isRinging, createdMessage]);

  useEffect(() => {
    let reconnectTimeout, callingResetTimeout;

    // Close dialog and reset state when the call ended
    if (isCallEnded === callInfo?.id) {
      setTextKey(LangConstant.TXT_CALL_FINISH);
    }

    if (isCallRejected) {
      setTextKey(LangConstant.TXT_CALL_REJECTED);
      setSound(SoundConstant.CallRejectedSound);
    }

    if (isCallMissed && !isDisconnected) {
      setTextKey(LangConstant.TXT_MISSED_CALL);
    }

    if (
      (isDisconnected || (navigator && navigator?.online !== undefined && !Boolean(navigator?.online))) &&
      !isCallMissed
    ) {
      setTextKey(LangConstant.TXT_RECONNECTING);
    }

    if (isDisconnected) {
      reconnectTimeout = setTimeout(() => {
        console.log("Out of waiting for reconnect time");
        setTextKey(LangConstant.TXT_FAILED_TO_RECONNECT);

        callingResetTimeout = setTimeout(() => {
          dispatch(CallingAction.callingReset());
        }, 3000);
      }, AppConstant.CALL_WAITING_TIME + 15000);
    }

    return () => {
      if (reconnectTimeout) clearTimeout(reconnectTimeout);
      if (callingResetTimeout) clearTimeout(callingResetTimeout);
    };
  }, [isCallEnded, isCallRejected, isCallMissed, isDisconnected, navigator?.online]);

  useEffect(() => {
    // Check if caller and receiver are on call then change sound and text
    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking && isPingToCallee) {
      if (isInAnotherCall) {
        setTextKey(LangConstant.TXT_IN_ANOTHER_CALL);
      } else if (isCalleeInAnotherCall) {
        setTextKey(LangConstant.TXT_RECEIVER_IN_ANOTHER_CALL);

        console.log("Someone in another call");
        setSound(SoundConstant.CallRejectedSound);

        if (callInfo?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
          // Send start message and immediately send an end message
          let roomId = uuid();
          let parentMessage = uuid();
          // Send start message
          onSendMessage({
            content: JSON.stringify({
              room_id: roomId,
            }),
            parentId: null,
            callStatus: SystemConstant.MESSAGE_CALL_STATUS.accept,
            roomId: roomId,
            sourceId: parentMessage,
          });

          // Send end message
          onSendMessage({
            content: JSON.stringify({
              room_id: roomId,
            }),
            parentId: parentMessage,
            callStatus: SystemConstant.MESSAGE_CALL_STATUS.missed,
            roomId: roomId,
          });
        }
      }
    }
  }, [isInAnotherCall, isPingToCallee, isCalleeInAnotherCall, isOpenCallingDialog]);

  useEffect(() => {
    if (
      isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking &&
      !Boolean(isSendMeetInfoSuccess) &&
      Boolean(callInfo.id)
    ) {
      setTextKey(LangConstant.TXT_MEET_OBJ);
      if (isReceiver && callInfo.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        const messageContent = toCamel(convertString2JSON(message?.content));
        onSendMessage({
          content: JSON.stringify({
            room_id: messageContent.roomId,
          }),
          parentId: messageContent.sourceId,
          callStatus: SystemConstant.MESSAGE_CALL_STATUS.reject,
          roomId: messageContent.roomId,
        });
      }

      const callingResetTimeout = setTimeout(() => {
        dispatch(CallingAction.callingReset());
      }, 3000);
      return () => clearTimeout(callingResetTimeout);
    }
  }, [isOpenCallingDialog, callInfo]);

  useEffect(() => {
    if (isRequestDeviceFail) {
      setTextKey(LangConstant.TXT_REQUEST_DEVICE_FAIL);
    }
  }, [isRequestDeviceFail]);

  useEffect(() => {
    if (isNoInternetConnection) {
      setTextKey(LangConstant.TXT_INTERNET_CONNECTION_FAILED);
    }
  }, [isNoInternetConnection]);

  useEffect(() => {
    let localVideo = document.getElementById(KeyConstant.KEY_USER_VIDEO_TAG);

    if (localVideo && localStream) {
      localStream.getAudioTracks()[0].enabled = false;
      localVideo.srcObject = localStream;
    }
  }, [localStream, isVideoing]);

  return (
    callingGroupDetail && (
      <Stack alignItems="center" className={classes.waitingGroupAvatar}>
        <AvatarGroup
          avatarId={callInfo?.avatarId}
          group={{
            groupType: callInfo?.groupType,
            groupName: callInfo?.groupName,
            privateF: callInfo?.privateF,
          }}
        />
        {callingGroupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal ? (
          <Typography className={classes.calleeName}>
            {callInfo?.groupName || getLabel(LangConstant.TXT_PERSONAL_CALL)}
          </Typography>
        ) : (
          <Typography className={classes.calleeName}>
            {callInfo?.groupName || getLabel(LangConstant.TXT_GROUP_CALL)}
          </Typography>
        )}
        <Typography className={classes.callingText}>{getLabel(textKey)}</Typography>
        <audio src={sound} autoPlay loop className={classes.ringing} id={KeyConstant.KEY_CALLING_MEDIA_ID} />
      </Stack>
    )
  );
};

export default WaitingScreen;

const useStyles = makeStyles(() => ({
  waitingGroupAvatar: {
    position: "absolute",
    width: 150,
    height: 150,
  },

  individualAvt: {
    width: 86 + "px !important",
    height: 86 + "px !important",
    fontSize: "1.25rem" + "px !important",
    border: "0.5px solid black",
  },

  doubleAvt: {
    width: 86 + "px !important",
    height: 86 + "px !important",
    fontSize: "1.25rem" + "px !important",
    border: "0.5px solid black",
  },

  calleeName: {
    lineHeight: "1.25rem",
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "white",
    width: "100%",
    textAlign: "center",
    margin: "8px 0",
  },

  callingText: {
    width: "100%",
    textAlign: "center",
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.375rem",
    color: "white",
  },

  receiverVideoBox: {
    width: "100%",
    height: "100%",
  },

  callerAvatar: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    backgroundColor: "black",
  },
}));
