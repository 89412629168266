import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, CardMedia, Checkbox, FormControlLabel, OutlinedInput, Typography } from "@mui/material";
import clsx from "clsx";
import PhoneInput from "react-phone-input-2";
import { validatePhone } from "utils";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { AppConstant, ImageConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import parsePhoneNumber from "libphonenumber-js";
import { AuthActions, AuthSelectors, BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";
import BranchInfo from "components/BranchInfo";
import { LocalKeyActionService } from "services/local.service";

const LoginForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);
  const authLoginStatus = useSelector(AuthSelectors.authLoginStatus);
  const tmpLoginBranch = useSelector(BranchSelectors.getTmpLoginBranch);

  const [isEnableContinueButton, setIsEnableContinueButton] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(AppConstant.LOCAL_PHONE_CODE);
  const [isNotFoundPhone, setIsNotFoundPhone] = useState(false);

  // TODO: Here we go again... Replace this will real data type.
  const [loginForm, setLoginForm] = useState({});
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const onChangePhone = newPhone => {
    const value = newPhone.replace(/\D/g, "");

    if (value !== phoneNumber) {
      setErrorContent(null);
    }

    setPhoneNumber(value);
    setIsNotFoundPhone(false);
    setIsEnableContinueButton(Boolean(validatePhone(value)));
  };

  const onClickPhoneInput = () => {
    let phoneInput = document.getElementById(PHONE_CODE_BUTTON_ID);
    phoneInput.children[0].children[2].children[0].id = FLAG_BUTTON_ID;
    document.getElementById(FLAG_BUTTON_ID).click();
  };

  const onMovingOn = event => {
    event.preventDefault();
    if (loginForm.accountUuid === null || loginForm.accountUuid === "") return;
    if (validatePhone(phoneNumber)) {
      const phoneWithCode = parsePhoneNumber(phoneNumber, "VN");

      const loginBlockInfo = StorageUtil.getCommonKey(KeyConstant.KEY_LOGIN_BLOCK_INFO);
      if (loginBlockInfo) {
        const currentTimestamp = new Date().getTime();
        const phoneKey = phoneWithCode.number;

        if (currentTimestamp - Number(loginBlockInfo[phoneKey]) < 5 * 60 * 1000) {
          setErrorContent(getLabel(LangConstant.TXT_OTP_LOCKED_FULL_MESSAGE));
          return;
        }
      }

      let submitForm = {
        ...loginForm,
        phone: phoneWithCode.number,
        regionCode: countryCode,
      };
      StorageUtil.setCommonKey(KeyConstant.KEY_PHONE, phoneWithCode.number);

      if (isRememberMe) {
        StorageUtil.setCommonKey(KeyConstant.KEY_REMEMBER_PHONE, true);
        StorageUtil.setCommonKey(KeyConstant.KEY_COUNTRY_CODE, countryCode);
      } else {
        StorageUtil.removeCommonKey(KeyConstant.KEY_PHONE);
        StorageUtil.removeCommonKey(KeyConstant.KEY_REMEMBER_PHONE);
        StorageUtil.removeCommonKey(KeyConstant.KEY_COUNTRY_CODE);
      }
      dispatch(AuthActions.requestLogin(submitForm));
      dispatch(
        AuthActions.authSet({
          phone: getLabel(LangConstant.FM_PHONE_CODE, {
            code: phoneWithCode.number,
          }),
        }),
      );
    }
  };

  const onCheckedChange = event => {
    setIsRememberMe(event.target.checked);
  };

  useEffect(() => {
    switch (authLoginStatus) {
      case AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_INACTIVE_USER"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.block:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_OTP_LOCKED_FULL_MESSAGE"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.notFoundPhone:
        setIsNotFoundPhone(true);
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_UNREGISTERED_MESSAGE"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.systemError:
        setErrorContent(getCommonLang("TXT_SERVER_ERROR"));
        break;

      default:
        setErrorContent(null);
    }
  }, [authLoginStatus]);

  useEffect(() => {
    const phone = tmpLoginBranch.phone;
    const rememberPhone = Boolean(StorageUtil.getItem(KeyConstant.KEY_REMEMBER_PHONE));
    let savedPhone = StorageUtil.getItem(KeyConstant.KEY_PHONE);
    let savedCountryCode = StorageUtil.getItem(KeyConstant.KEY_COUNTRY_CODE);
    if (phone) {
      onChangePhone(phone);
    } else if (rememberPhone && Boolean(savedPhone)) {
      onChangePhone(savedPhone);
    }

    if (Boolean(savedCountryCode)) {
      setCountryCode(savedCountryCode);
    }
    setIsRememberMe(Boolean(StorageUtil.getItem(KeyConstant.KEY_REMEMBER_PHONE)));
  }, [tmpLoginBranch]);

  useEffect(() => {
    LocalKeyActionService.getInitLoginData().then(initLoginData =>
      setLoginForm({ ...initLoginData, version: window.env?.APP_VERSION || initLoginData.version }),
    );
  }, []);

  return (
    <Box className={classes.loginFormContainer}>
      {tmpLoginBranch.id && tmpLoginBranch.id !== SystemConstant.GLOBAL_BRANCH_ID ? (
        <BranchInfo
          title={getLabel(LangConstant.TXT_LOGIN_WELCOME_TITLE)}
          subtitle={getLabel(LangConstant.TXT_LOGIN_WELCOME_SUBTITLE)}
        />
      ) : (
        <CardMedia component="img" src={ImageConstant.LogoImage} className={classes.logoImg} />
      )}
      <Typography className={clsx(classes.loginLabel, "semiBold-xl-txt")}>
        {getLabel(LangConstant.TXT_LOGIN)}
      </Typography>
      <form className={classes.formInput} onSubmit={onMovingOn}>
        <Typography className={clsx(classes.inputLabel, "regular-lg-txt")}>
          {getCommonLang(LangConstant.TXT_PHONE_NUMBER)}
        </Typography>
        <Box className={classes.phoneCodeAndInput}>
          <Box
            className={clsx(classes.phoneCodeButton, "no-select-text")}
            onClick={onClickPhoneInput}
            id={PHONE_CODE_BUTTON_ID}
          >
            <PhoneInput
              country={AppConstant.LOCAL_COUNTRY_CODE}
              onlyCountries={["vn"]}
              onChange={setCountryCode}
              inputClass={classes.countryInput}
              buttonClass={classes.dropdownCountryButton}
              containerClass={classes.phoneInputContainer}
              preferredCountries={[SystemConstant.COUNTRY.vietnam]}
            />
            {getLabel(LangConstant.FM_PHONE_CODE, { code: countryCode })} <ArrowDropDown />
          </Box>
          <OutlinedInput
            classes={{
              root: classes.phoneInputRoot,
              notchedOutline: classes.notchedOutline,
              focused: classes.notchedOutline,
              input: clsx(classes.phoneInput, "regular-md-txt"),
            }}
            value={phoneNumber}
            onChange={event => onChangePhone(event.target.value)}
          />
        </Box>
        {errorContent && (
          <Typography className={clsx(classes.unregisteredMessage, "regular-sm-txt")}>{errorContent}</Typography>
        )}
        <FormControlLabel
          control={<Checkbox />}
          label={getLabel(LangConstant.TXT_REMEMBER_ME)}
          classes={{
            root: classes.saveLoginInfoBox,
            label: clsx(classes.saveLoginInfoLabel, "no-select-text", "regular-md-txt"),
          }}
          onChange={onCheckedChange}
          checked={isRememberMe}
        />
        <Button
          type="submit"
          variant="contained"
          className={clsx("semiBold-lg-txt", classes.continueButton)}
          disabled={!isEnableContinueButton || isNotFoundPhone}
          classes={{ disabled: classes.disabledButton }}
        >
          {getCommonLang(LangConstant.TXT_CONTINUE)}
        </Button>
      </form>
    </Box>
  );
};

const FLAG_BUTTON_ID = "flag-button";
const PHONE_CODE_BUTTON_ID = "phone-code-button";

LoginForm.propTypes = {};

export default LoginForm;

const useStyles = makeStyles(theme => ({
  loginFormContainer: {
    backgroundColor: theme.palette.white,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  formInput: {
    width: "100%",
  },

  logoImg: {
    maxWidth: 150,
  },

  countryInput: {
    display: "none",
  },

  dropdownCountryButton: {
    border: "none !important",
    position: "relative !important",
    height: "100%",
    background: "#F7F7F8",
    "&>div": {
      padding: 0 + " !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#F7F7F8 !important",
    },
    "&>div>div": {
      position: "unset",
      margin: 0,
    },
    "&>div>div>div": {
      display: "none",
    },
  },

  phoneInputContainer: {
    height: 30,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
  },

  phoneCodeButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRight: "1px solid #E4E4E4",
  },

  phoneCodeAndInput: {
    display: "flex",
    padding: "10px 0",
    backgroundColor: "#F7F7F8",
    height: 50,
    borderRadius: 10,
    width: "100%",
  },

  notchedOutline: {
    borderWidth: 0 + " !important",
  },

  phoneInput: {
    color: "#030229",
    height: 30,
    padding: 10,
    MozAppearance: "textfield",

    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },

    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },

  saveLoginInfoBox: {
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
  },

  saveLoginInfoLabel: {
    color: "#030229",
  },

  continueButton: {
    width: "100%",
    padding: "14px 0",
    marginTop: 15,
  },

  loginLabel: {
    marginTop: 16,
    marginBottom: 25,
    lineHeight: "1.75rem",
  },

  inputLabel: {
    marginBottom: 8,
    width: "100% ",
  },

  unregisteredMessage: {
    marginTop: 12,
    color: theme.palette.secondary.main,
    lineHeight: "1rem",
    textAlign: "center",
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },
}));
