import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { isJSONString } from "utils";
import { ContentCopy, Delete, Edit, Reply, Download, Info, PushPinOutlined } from "@mui/icons-material";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { ActionMenu, ConfirmDialog } from "components";
import ConfirmDeleteMessagePopup from "./ConfirmDeteleMessagePopup";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { saveMessageInQueue, useConversationContext } from "../ConversationContext";
import MessageDetailDrawer from "./MessageDetailDrawer";
import { getInteractor } from "services/local.service";
import { useCleanUpEffect, useDownloadFile } from "hooks";
import { getMessageFileList, replaceId2Name } from "utils/message.utils";
import { UnpinIcon } from "components/icons";
import { useAlertContext } from "components/context/AlertContext";
import { getSavedServer } from "utils/branch.utils";

const ChatActionMenu = ({ message, menuAnchor, isThreadMode, onEditMessage, onClose }) => {
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const isMine = message.senderId === accountId;
  const isCurrentDevice = message.senderDeviceId === deviceId;
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { downloadFile } = useDownloadFile();
  const { isMounted } = useCleanUpEffect();
  const { showAlert } = useAlertContext();
  const { groupDetail } = useConversationContext();

  const serverOptions = getSavedServer().serverOptions;
  const MAX_GROUP_MESSAGE_PIN = serverOptions.maxGroupMessagePin;
  const editMessageTime = serverOptions.editMessageTime;
  const deleteMessageTime = serverOptions.deleteMessageTime;
  const isAllowEdit = isCurrentDevice
    ? Date.now() - message.modified < editMessageTime
    : Date.now() - message.created < editMessageTime;
  const isAllowDelete = isCurrentDevice
    ? Date.now() - message.modified < deleteMessageTime
    : Date.now() - message.created < deleteMessageTime;

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [isDetailMessage, setIsDetailMessage] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [isShowConfirmUnpin, setIsShowConfirmUnpin] = useState(false);

  const handleReplyMessage = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        selectedGroupId: message.groupId,
        threadingId: message.sourceId,
      }),
    );
    onClose();
  };

  const handleDownLoadFile = async () => {
    if (MEDIA_TYPES.includes(message.sendType) && isJSONString(message.content)) {
      const msgFileList = getMessageFileList(message);
      downloadFile(msgFileList, message.groupId);
      onClose();
    } else {
      // Show alert if app can not download file
      alert(getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND));
    }
  };

  const handleCopyContent = async () => {
    const groupDetail = (await getInteractor(prefixKey).LocalGroupService.get(message.groupId)) || {};
    const groupMembers = groupDetail.groupMembers || [];
    navigator?.clipboard.writeText(await replaceId2Name(message.content, groupMembers, false));
    onClose();
  };

  const handleShowConfirmDialog = () => {
    setIsOpenConfirmDialog(true);
    onClose();
  };

  const handleCloseConfirmDialog = () => {
    if (isMounted()) setIsOpenConfirmDialog(false);
    onClose();
  };

  const handleDeleteMessage = async () => {
    if (isMine) {
      await saveMessageInQueue({
        prefixKey,
        groupId: message.groupId,
        threadId: message.threadId,
        sendType: SystemConstant.SEND_TYPE.deleteMessage,
        content: message.id,
        parentId: message.sourceId,
      });
    } else {
      dispatch(ConversationActions.deleteMessageLocal(message, StorageUtil.getCurrentPrefixKey()));
    }
    handleCloseConfirmDialog();
  };

  const handleShowDetail = () => {
    setIsDetailMessage(true);
    onClose();
  };

  const handlePinMessage = async () => {
    const newPinState = !isPin;
    const countPinMessage = await getInteractor(prefixKey).LocalGroupMessageService.count({
      type: SystemConstant.GROUP_MESSAGE_TYPE.pin,
      state: SystemConstant.STATE.active,
      group_id: message.groupId,
    });

    if (newPinState && countPinMessage >= MAX_GROUP_MESSAGE_PIN) {
      showAlert({ content: getCommonLang("TXT_OVER_MAX_PIN"), alertProps: { severity: "error" } });
    } else {
      dispatch(
        ConversationActions.pinMessage({
          sourceId: message.sourceId,
          type: SystemConstant.GROUP_MESSAGE_TYPE.pin,
          state: Boolean(newPinState) ? SystemConstant.PIN_TYPE.pin : SystemConstant.PIN_TYPE.unpin,
          groupId: message.groupId,
        }),
      );
    }

    setIsShowConfirmUnpin(false);
    onClose();
  };

  const handleShowConfirm = () => setIsShowConfirmUnpin(true);

  const isMediaMessage = MEDIA_TYPES.includes(message.sendType);
  const isEditable =
    isAllowEdit && isMine && ![...MEDIA_TYPES, SystemConstant.SEND_TYPE.file].includes(message.sendType);
  const isCopyable = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage].includes(
    message.sendType,
  );
  const isShowPin = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal || groupDetail.isAdmin;
  const isEnableDelete = isMine && isAllowDelete;

  const menuArray = [
    isThreadMode
      ? null
      : {
          onClick: handleReplyMessage,
          icon: <Reply />,
          content: getCommonLang(LangConstant.TXT_REPLY),
        },

    {
      onClick: handleShowDetail,
      icon: <Info />,
      content: getCommonLang("TXT_SEE_INFORMATION"),
    },

    isEditable
      ? {
          onClick: onEditMessage,
          icon: <Edit />,
          content: getCommonLang(LangConstant.TXT_EDIT),
        }
      : null,

    isCopyable
      ? {
          onClick: handleCopyContent,
          icon: <ContentCopy />,
          content: getCommonLang(LangConstant.TXT_COPY),
        }
      : null,

    isMediaMessage
      ? {
          onClick: handleDownLoadFile,
          icon: <Download />,
          content: getCommonLang(LangConstant.TXT_DOWNLOAD),
        }
      : null,

    isEnableDelete
      ? {
          onClick: handleShowConfirmDialog,
          icon: <Delete />,
          content: getCommonLang(LangConstant.TXT_DELETE),
        }
      : null,

    isShowPin
      ? {
          onClick: isPin ? handleShowConfirm : handlePinMessage,
          icon: isPin ? <UnpinIcon /> : <PushPinOutlined />,
          content: getCommonLang(isPin ? "TXT_UNPIN" : "TXT_PIN"),
        }
      : null,
  ].filter(item => Boolean(item && item.content));

  useEffect(() => {
    if (message) setIsPin(message.isPin);
  }, [message]);

  return (
    <>
      <ActionMenu contentArray={menuArray} onClose={onClose} anchorEl={menuAnchor} placement="top" />

      {isOpenConfirmDialog && (
        <ConfirmDeleteMessagePopup
          open={isOpenConfirmDialog}
          onCloseConfirmDialog={handleCloseConfirmDialog}
          onConfirmDelete={handleDeleteMessage}
        />
      )}

      {isDetailMessage && (
        <MessageDetailDrawer message={message} open={isDetailMessage} onClose={() => setIsDetailMessage(false)} />
      )}

      {isShowConfirmUnpin && (
        <ConfirmDialog
          open
          title={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CONFIRM_UNPIN_TITLE")}
          content={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CONFIRM_UNPIN_SUBTITLE")}
          submitProps={{ submitText: getCommonLang("TXT_UNPIN"), onClick: handlePinMessage }}
          cancelProps={{
            onClick: () => {
              setIsShowConfirmUnpin(false);
              onClose();
            },
          }}
        />
      )}
    </>
  );
};

export default memo(ChatActionMenu);

const MEDIA_TYPES = [SystemConstant.SEND_TYPE.audio, SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];
