import React, { memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Fab, Slide } from "@mui/material";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";

const InfiniteScroll = ({
  children,
  className,
  scrollToChildId,
  hasMore,
  isReverse,
  onScrollToTop,
  onScrollToBottom,
  onClickScrollButton,
  ScrollTooltip,
  ...otherProps
}) => {
  const classes = useStyles();
  const scrollerRef = useRef();
  const scrollDataRef = useRef({
    hasMore,
    isReverse,
    onScrollToTop,
    onScrollToBottom,
  });
  scrollDataRef.current = useMemo(
    () => ({ hasMore, isReverse, onScrollToTop, onScrollToBottom }),
    [hasMore, isReverse, onScrollToTop, onScrollToBottom],
  );

  const [isTransitionScrollIcon, setIsTransitionScrollIcon] = useState(false);

  const handleScroll = useCallback(event => {
    let element = event.target;
    if (!element || !scrollDataRef.current) return;

    const { isReverse, hasMore, onScrollToTop, onScrollToBottom } = scrollDataRef.current;
    const absScrollTop = Math.abs(element.scrollTop);

    const scrollToHeigh = absScrollTop + element.offsetHeight;
    let triggerHeight = Math.ceil(element.scrollHeight * 0.2);
    triggerHeight = triggerHeight > 1000 ? 1000 : triggerHeight < 200 ? 200 : triggerHeight; // Max: 1000 - Min: 200 (px)

    if (hasMore && onScrollToBottom && element.scrollHeight - scrollToHeigh <= triggerHeight) {
      onScrollToBottom();
    }

    if (hasMore && onScrollToTop && absScrollTop <= triggerHeight) {
      onScrollToTop();
    }

    setIsTransitionScrollIcon(
      isReverse ? absScrollTop > 20 : absScrollTop + element.clientHeight < element.scrollHeight,
    );
  }, []);

  useLayoutEffect(() => {
    if (scrollerRef.current && (onScrollToTop || onScrollToBottom)) {
      scrollerRef.current.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollerRef.current) scrollerRef.current.removeEventListener("scroll", handleScroll);
      };
    }
  }, [onScrollToTop, onScrollToBottom, isReverse]);

  const isShowIcon = Boolean(onClickScrollButton);
  return (
    <>
      <Box className={clsx(classes.infiniteScrollRoot, className)} ref={scrollerRef} {...otherProps}>
        {children}
      </Box>
      {isShowIcon && (
        <Slide direction="up" in={isTransitionScrollIcon}>
          <Fab variant="extended" size="small" className={classes.arrowDown} onClick={onClickScrollButton}>
            <KeyboardDoubleArrowDown />
            {ScrollTooltip && <ScrollTooltip isShowButton={isTransitionScrollIcon} />}
          </Fab>
        </Slide>
      )}
    </>
  );
};

export default memo(InfiniteScroll);

InfiniteScroll.propTypes = {
  className: PropTypes.string,
  scrollToChildId: PropTypes.string,
  hasMore: PropTypes.bool,
  isReverse: PropTypes.bool,
  ScrollTooltip: PropTypes.object,

  onScrollToTop: PropTypes.func,
  onScrollToBottom: PropTypes.func,
  onClickScrollButton: PropTypes.func,
};

InfiniteScroll.defaultProps = {
  className: "",
  hasMore: true,
  isReverse: false,

  onScrollToTop: () => {},
  onScrollToBottom: () => {},
};

const useStyles = makeStyles(theme => ({
  infiniteScrollRoot: {
    overflowY: "auto",
    position: "relative",
  },

  arrowDown: {
    backgroundColor: theme.palette.white,
    color: theme.palette.grey[300],
    position: "absolute",
    bottom: 32,
    right: 22,
    textTransform: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },

    "&:has(.custom-notice)": {
      color: theme.palette.primary.main,
    },
  },
}));
