import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const SettingLogoIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M2 12C2 10.5333 2.28333 9.17499 2.85 7.92499C3.41667 6.67499 4.19167 5.59166 5.175 4.67499C6.15833 3.75832 7.29167 3.06249 8.575 2.58749C9.85833 2.11249 11.2333 1.92499 12.7 2.02499C12.9833 2.05832 13.2083 2.17915 13.375 2.38749C13.5417 2.59582 13.6 2.84166 13.55 3.12499C13.5 3.40832 13.3625 3.63332 13.1375 3.79999C12.9125 3.96666 12.6583 4.04166 12.375 4.02499C11.225 3.97499 10.1458 4.13749 9.1375 4.51249C8.12917 4.88749 7.24167 5.44165 6.475 6.17499C5.70833 6.90832 5.10417 7.77082 4.6625 8.76249C4.22083 9.75416 4 10.8333 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20C13.15 20 14.2208 19.7792 15.2125 19.3375C16.2042 18.8958 17.0667 18.2917 17.8 17.525C18.5667 16.725 19.1333 15.8167 19.5 14.8C19.8667 13.7833 20.025 12.725 19.975 11.625C19.9583 11.3417 20.0333 11.0875 20.2 10.8625C20.3667 10.6375 20.5917 10.5 20.875 10.45C21.1583 10.4 21.4042 10.4583 21.6125 10.625C21.8208 10.7917 21.9417 11.0167 21.975 11.3C22.075 12.75 21.8875 14.1167 21.4125 15.4C20.9375 16.6833 20.25 17.8167 19.35 18.8C18.4 19.8333 17.2917 20.625 16.025 21.175C14.7583 21.725 13.4167 22 12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12ZM18 8.99999C17.1667 8.99999 16.4583 8.70832 15.875 8.12499C15.2917 7.54166 15 6.83332 15 5.99999C15 5.16665 15.2917 4.45832 15.875 3.87499C16.4583 3.29166 17.1667 2.99999 18 2.99999C18.8333 2.99999 19.5417 3.29166 20.125 3.87499C20.7083 4.45832 21 5.16665 21 5.99999C21 6.83332 20.7083 7.54166 20.125 8.12499C19.5417 8.70832 18.8333 8.99999 18 8.99999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

SettingLogoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
SettingLogoIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(SettingLogoIcon);
