import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ButtonGroupInfo = ({ name, onClick, icon }) => {
  const classes = useStyles();

  return (
    <Button className={classes.btnGroupInfo} fullWidth onClick={onClick}>
      <Typography className="ellipsis" variant="body1">
        {name}
      </Typography>
      {icon && <Box className={classes.iconGroupBtn}>{icon}</Box>}
    </Button>
  );
};

ButtonGroupInfo.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

ButtonGroupInfo.defaultProps = {
  name: "",
  onClick: () => {},
};

export default ButtonGroupInfo;

const useStyles = makeStyles({
  btnGroupInfo: {
    height: 50,
    textTransform: "none",
    display: "flex",
    justifyContent: "space-between",
  },

  iconGroupBtn: {
    width: 36,
    height: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#C4C4C4",
    color: "#010101",
  },
});
