import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { BranchSelectors, SystemSelectors } from "redux-store";
import { createSelector } from "reselect";
import useLazyEffect from "./useLazyEffect";
import useCleanUpEffect from "./useCleanUpEffect";
import { setUnreadInAppLogo } from "pubsub/services/message/getMessage";

const memoizedReduxState = createSelector(
  [
    BranchSelectors.getSelectedBranch,
    state => state.conversationRedux.isUpdateViewMode,
    SystemSelectors.getNewNotification,
  ],
  (selectedBranch, isUpdateViewMode, newNotification) => {
    return {
      selectedBranchId: selectedBranch.id,
      isUpdateViewMode,
      newNotification,
    };
  },
);

const useUnreadMessage = () => {
  const { selectedBranchId, isUpdateViewMode, newNotification } = useSelector(memoizedReduxState);
  const { isMounted } = useCleanUpEffect();

  const [allUnreadMessages, setAllUnreadMessages] = useState(0);
  const [unreadNotification, setUnreadNotification] = useState(0);

  const updateUnreadNotification = useCallback(async () => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const numberUnreadNotice = (
      await getInteractor(prefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(selectedBranchId)
    ).length;
    setUnreadNotification(numberUnreadNotice);
    await setUnreadInAppLogo();
  }, [selectedBranchId]);

  const fetchUnreadMessage = useCallback(async selectedBranchId => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();

    const numberUnreadNotice = (
      await getInteractor(prefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(selectedBranchId)
    ).length;
    if (isMounted()) setUnreadNotification(numberUnreadNotice);

    const totalUnread = await getInteractor(prefixKey).LocalGroupService.getTotalUnread();
    const totalUnreadInThread = await getInteractor(prefixKey).LocalThreadService.countTotalUnreadMessage();

    if (isMounted()) setAllUnreadMessages(totalUnread + totalUnreadInThread);

    await setUnreadInAppLogo();
  }, []);

  useLazyEffect(() => {
    fetchUnreadMessage(selectedBranchId);
  }, [isUpdateViewMode, selectedBranchId]);

  useEffect(() => {
    updateUnreadNotification();
  }, [newNotification]);

  return {
    allUnreadMessages,
    unreadNotification,
    totalUnreadInBranch: allUnreadMessages + unreadNotification,
  };
};

export default useUnreadMessage;
