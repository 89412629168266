import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import clsx from "clsx";
import { OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import { AppConstant, LangConstant } from "const";

const SearchBar = ({ value, onChange, classes, placeholder, disableDebounce, ...otherProps }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  const [searchValue, setSearchValue] = useState(value);

  const onDelaySearch = useCallback(
    disableDebounce ? params => onChange(params) : debounce(params => onChange(params), AppConstant.DEBOUNCE_TIME),
    [disableDebounce, onChange],
  );

  const onSearch = event => {
    onDelaySearch(event.target.value);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (value !== searchValue) {
      setSearchValue(value);
    }
  }, [value]);

  return (
    <OutlinedInput
      classes={{
        root: clsx(defaultClasses.searchBarRoot, classes.root),
        input: clsx(defaultClasses.searchInput, "regular-md-txt", classes.input),
        notchedOutline: defaultClasses.borderlessInput,
      }}
      startAdornment={<Search className={defaultClasses.startIcon} />}
      value={searchValue}
      onChange={onSearch}
      inputProps={{
        placeholder: placeholder ? placeholder : getLabel(LangConstant.TXT_SEARCH),
      }}
      {...otherProps}
    />
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,

  disableDebounce: PropTypes.bool,

  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
  }),
};

SearchBar.defaultProps = {
  value: "",
  placeholder: "",

  onChange: () => {},

  disableDebounce: false,

  classes: {
    root: "",
    input: "",
  },
};

export default SearchBar;

const useStyles = makeStyles(theme => ({
  searchBarRoot: {
    width: "100%",
    background: "#F5F5F5",
    borderRadius: 10,
    height: 40,
  },

  searchInput: {
    padding: 12,
    "&::placeholder": {
      color: "#8C8C8C",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
  },

  borderlessInput: {
    border: "none",
  },

  startIcon: {
    color: "#4D4B53",
  },
}));
