import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { palette } from "theme/material";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { Tab, TabsList, tabClasses } from "@mui/base";

const AddingChatTab = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  return (
    <TabsList className={classes.tabList}>
      <StyledTab className={classes.tab}>{chatObjContent.tabMessage}</StyledTab>
      <StyledTab className={classes.tab}>{chatObjContent.tabChannel}</StyledTab>
    </TabsList>
  );
};

AddingChatTab.propTypes = {};

export default memo(AddingChatTab);

const StyledTab = styled(Tab)`
  width: 100%;
  min-width: 145px;
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  border: none;
  border-radius: 15.5px;
  color: #7f7f7f;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 700;
  background-color: transparent;
  text-transform: uppercase;

  &:hover {
    color: #1e272e;
  }

  &.${tabClasses.selected} {
    background-color: ${palette.grey[100]};
    color: #1e272e;
  }
`;

const useStyles = makeStyles({
  tabList: {
    minWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "white",
    borderRadius: 8,
    marginBottom: 16,
  },
});
