import { useEffect } from "react";
import { Avatar, Box, CardMedia, Skeleton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppDrawer, InfiniteScroll } from "components";
import { AppConstant, ImageConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import MessageThread from "./MessageThread";
import { useThreadInfo, useUnreadThread } from "hooks";
import { MarkReadIcon } from "components/icons";
import { getNSLang } from "utils/lang.utils";
import { getInteractor } from "services/local.service";
import { toCamel } from "utils";

const ThreadListDrawer = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const {
    pagination: { threadList, isEnd },
    viewMode,
    loadMore,
    updateThreadAndMessage,
  } = useThreadInfo();
  const { unreadThreadNum } = useUnreadThread();

  const handleScrollToBottom = () => {
    if (isEnd) return;

    loadMore(threadList);
  };

  const handleReadAll = async () => {
    const allUnreadThread = await getInteractor().LocalThreadService.getUnreadThread();

    updateThreadAndMessage(toCamel(allUnreadThread), true);
  };

  const handleClose = () => {
    updateThreadAndMessage(threadList);
    onClose();
  };

  useEffect(() => {
    const unreadThread = threadList.filter(item => item.totalUnread > 0);
    if (unreadThread.length > 10) updateThreadAndMessage(unreadThread);
  }, [threadList]);

  return (
    <AppDrawer
      open={isOpen}
      onClose={handleClose}
      title={getNSLang(LangConstant.NS_HOME, "TXT_FLOW_MESSAGE")}
      classes={{ title: classes.drawerTitle }}
      secondIcon={<MarkReadIcon />}
      secondIconProps={{
        onClick: handleReadAll,
        title: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_MARK_AS_READ"),
        className: classes.markRead,
        disabled: unreadThreadNum <= 0,
      }}
    >
      <InfiniteScroll className={classes.infiniteScroll} onScrollToBottom={handleScrollToBottom} hasMore={!isEnd}>
        {threadList.map(item => (
          <MessageThread
            key={item.id}
            threadId={item.threadId}
            parentMessage={item.parentMessage}
            replyMessageList={item.replyMessageList}
            totalReply={item.totalReply}
            groupId={item.groupId}
            senderParentMsg={item.senderParentMsg}
          />
        ))}
        {viewMode === AppConstant.VIEW_MODE.fetching && <ThreadListLoading />}
      </InfiniteScroll>

      {viewMode === AppConstant.VIEW_MODE.empty && (
        <Box className={classes.emptyChatList}>
          <CardMedia component="img" src={ImageConstant.WelcomeImage} className={classes.emptyImage} />
          <Typography className={classes.emptyMessage}>{getLabel(LangConstant.TXT_NO_UNSEEN_THREAD)}</Typography>
        </Box>
      )}
    </AppDrawer>
  );
};

export default ThreadListDrawer;

const useStyles = makeStyles(theme => ({
  emptyImage: {
    padding: "10px 14px",
  },

  emptyMessage: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    marginTop: 16,
    lineHeight: "1.25rem",
    textAlign: "center",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 56px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  infiniteScroll: {
    height: "100%",
    paddingTop: 5,
  },

  drawerTitle: {
    textAlign: "left",
  },

  markRead: {
    color: theme.palette.primary.main,
  },
}));

export const ThreadListLoading = () => {
  return (
    <Stack gap={1} p={2}>
      {[...Array(3).keys()].map(key => (
        <Stack
          key={key}
          sx={{
            border: "solid",
            borderColor: "#e7e7e7",
            borderWidth: "thin",
            borderRadius: 1,
            gap: 1,
            p: 2,
          }}
        >
          <Skeleton width="100%" />
          {[...Array(3).keys()].map(chattingKey => (
            <Stack key={chattingKey} flexDirection="row" alignItems="center" gap={1}>
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>

              <Box width="100%">
                <Skeleton width="80%" />
                <Skeleton width="100%" />
              </Box>
            </Stack>
          ))}

          <Stack flexDirection="row" justifyContent="right">
            <Skeleton variant="rounded" height={40} width={117} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
